import { ref } from 'vue';
import { LOCALES, DefaultLang } from '@/constants/constants';

function browserLocale() {
  let lang = '';

  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    [lang] = navigator.languages;
  } else if (navigator.userLanguage) {
    // IE only
    lang = navigator.userLanguage;
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    lang = navigator.language;
  }

  if (lang.indexOf('ja') >= 0) {
    return LOCALES.JA;
  }

  return DefaultLang;
}

export { browserLocale };

export default function useLocale(lang = DefaultLang) {
  const locale = ref(lang); // initial value depends on browser's default locale

  const updateLocale = (newLocale) => {
    locale.value = newLocale;
  };

  return {
    locale,
    updateLocale,
  };
}
