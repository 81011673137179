<template>
  <div class="services-wrap">
    <div class="services-content">
      <div :class="['services-header', locale]">
        <span v-viewportChanged="showView" class="hidden-element title" :style="pr.titleStyle">{{pr.title}}</span>
        <div class="sp">
          <div v-viewportChanged="showView" v-for="item in pr.descriptionsMobile"
          :key="item" class="hidden-element description">
            {{ item }}
          </div>
        </div>
        <div class="pc">
          <div v-viewportChanged="showView" v-for="item in pr.descriptionsPC" :key="item"
               class="hidden-element description">
            <BreakText v-viewportChanged="showView" :content="item" />
          </div>
        </div>
      </div>
      <div class="services-list">
        <div v-for="svc in pr.services" :key="svc.name" :id="svc.id" :class="['service-item', locale]">
          <div class="service-item-content">
            <img :src="getImageUrl(svc.icon)" :alt="svc.name">
            <div class="service-item-header">
              <p v-viewportChanged="showView" class="hidden-element service-name">{{ svc.name }}</p>
              <p v-viewportChanged="showView" class="hidden-element service-subtitle">{{ svc.service }}</p>
            </div>
            <p v-viewportChanged="showView" class="hidden-element service-description">
              {{ svc.description }}
            </p>
          </div>
        </div>
      </div>
      <span v-viewportChanged="showView" class="hidden-element learn-more sp">{{pr.learnMoreMobile}}</span>
      <div v-viewportChanged="showView" :class="['hidden-element learn-more pc', locale]">
        <BreakText v-for="(item, index) in pr.learnMorePC" :key="index" :content="item" />
      </div>
      <contact-button :btnClickEvent="toContactUs"></contact-button>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import BreakText from '@/components/common/BreakText.vue';
import usePageResource from '@/composables/usePageResource';
import ContactButton from '@/components/contact-button/ContactButton.vue';

export default {
  name: 'services',
  setup() {
    const { pr } = usePageResource('services');
    const locale = inject('locale');
    return {
      moveTo: inject('moveTo'),
      pr,
      locale,
    };
  },
  components: {
    ContactButton,
    BreakText,
  },
  data() {
    return {

    };
  },
  methods: {
    getImageUrl(imgName) {
      if (imgName) {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        return require(`./../../assets/${imgName}`);
      }
      return '';
    },
    toContactUs() {
      this.moveTo('contact-us');
    },
  },
};
</script>
<style lang="scss" scoped>
.pc {
  display: block;
}
.sp {
  display: none;
}

.services-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 108px 24px 100px 24px;
  background: #FFFFFF;
  color: #222222;
  border-bottom-width: 1px;
  border-bottom-color: rgba($color: #000000, $alpha: 0.1);
  border-bottom-style: solid;

  .services-content {
    width: 100%;
    max-width: 1260px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .services-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        font-size: 30px;
        margin-bottom: 32px;
        color: #222222;
        text-align: center;
        font-weight: bold;
      }

      .description {
        font-size: 16px;
        line-height: 28px;
      }

      &.ja {
        margin-top: 6px;

        .title {
          margin-bottom: 30px;
        }
      }
    }

    .services-slides {
      width: 100%;
      margin-top: 68px;
      margin-bottom: 72px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .work-card-wrap {
        flex: 50%;
        box-sizing: border-box;
        padding: 30px;
      }
    }

    .services-list {
      margin-top: 16px;
      margin-bottom: 78px;
      width: 100%;
      max-width: 1000px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
    }

    #services_office-automation {
      &.ja {
        .service-item-header {
          width: 314px;
          margin-left: -27px;

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-left: 0;

            .service-name {
              margin-left: -5px;
              margin-right: -5px;
            }
          }
        }
      }
    }

    #services_app {
      &.ja {
        .service-item-header {
          width: 280px;
          margin-left: -10px;

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }

    .service-item {
      flex: 33%;
      display: flex;
      justify-content: center;
      margin-top: 62px;
      img {
        width: 120px;
      }

      .service-item-content {
        max-width: 270px;

        .service-item-header {
          text-align: center;
          color: #222222;
          line-height: 30px;
          font-weight: bold;
        }

        .service-name {
          margin-top: 24px;
          font-size: 18px;
        }

        .service-subtitle {
          line-height: 28px;
          font-size: 20px;
          white-space: pre-wrap;
        }

        .service-description {
          margin-top: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #666666;
          text-align: left;
          word-break: break-all;
          letter-spacing: -0.1px;
        }
      }

      &.ja {
        .service-item-content {
          max-width: 260px;

          .service-item-header {
            font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Yu Gothic Medium", "Yu Gothic", sans-serif;
          }

          .service-subtitle {
            margin-top: 4px;
            line-height: 20px;
          }

          .service-description {
            margin-top: 11px;
          }
        }
      }
    }

    @media only screen and (max-width: 975px) {
      .service-item:nth-child(n) {
        flex: 50%;
        justify-content: flex-end;

        .service-item-content {
          margin-right: 37.5px;
        }
      }
      .service-item:nth-child(2n) {
        justify-content: flex-start;

        .service-item-content {
          margin-left: 37.5px;
          margin-right: 0;
        }
      }
    }

    .learn-more {
      height: 66px;
      margin-bottom: -3px;
      font-size: 16px;
      text-align: center;
      line-height: 28px;
      color: #222222;
      white-space: pre-wrap;

      &.ja {
        margin-bottom: 21px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .pc {
    display: none;
  }
  .sp {
    display: block;
  }
  .services-wrap {
    padding: 66px 10px 70px 10px;
    .services-content {
      .services-header {
        .title {
          margin-bottom: 34px;
          font-size: 22px;
        }

        .description {
          font-size: 15px;
          line-height: 30px;
        }

        &.ja {
          margin-top: 4px;
        }
      }

      .services-list {
        margin-top: 0;
        margin-bottom: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .service-item {
          flex: 100%;
          justify-content: center;
        }
      }

      .service-item {
        margin-top: 40px;

        .service-item-content {
          max-width: 327px;
          margin: 0 !important;

          .service-subtitle {
            font-size: 20px;
          }

          .service-description {
            height: 52px;
          }
        }

        &.ja {
          .service-item-content{
            max-width: 327px;

            .service-description {
              margin-top: 8px;
              height: 75px;
            }
          }
        }
      }

      .learn-more {
        height: auto;
        margin-bottom: 32px;
        white-space: pre-wrap;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
</style>
