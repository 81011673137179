<template>
  <div v-if="!isSP" class="body-second-section-pc">
    <p v-viewportChanged="showView" class="hidden-element text-pc">{{ work.desTitle2 }}</p>
    <p v-viewportChanged="showView" class="hidden-element description-pc">
      {{ work.desContent2[0] }}
    </p>
    <div class="step-pc">
      <span>{{ work.desContent2[1] }}</span>
      <div class="line-pc"><div class="horizontal-line-pc"></div></div>
      <img style="width:100% ;margin-top: 17px;" alt='' :src="getImageUrl(work.screenShot3.pc)" />
    </div>
    <div class="images-pc">
      <img
        v-for="item in work.screenShot4"
        :key="item"
        alt=''
        :src="getImageUrl(item)"
      />
    </div>
  </div>
  <div v-else class="body-second-section-sp">
    <p v-viewportChanged="showView" class="hidden-element text-sp">{{ work.desTitle2 }}</p>
    <p v-viewportChanged="showView" class="hidden-element description-sp">
      {{ work.desContent2[0] }}
    </p>
    <div class="step-sp">
      <img style="max-width: 194px;" alt='' :src="getImageUrl(work.screenShot3.sp)" />
      <div class="line-sp"><div class="horizontal-line-sp"></div></div>
      <span>{{ work.desContent2[1] }}</span>
    </div>
    <div class="images-sp">
      <img
        v-for="item in work.screenShot4"
        :key="item"
        alt=''
        :src="getImageUrl(item)"
      />
    </div>
  </div>
</template>

<script>
import useRenderData from '@/composables/useRenderData';
import useIsSP from '@/composables/useIsSP';

export default {
  props: {
    work: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { getImageUrl } = useRenderData('network-security');
    const { isSP } = useIsSP();
    return {
      getImageUrl,
      isSP,
    };
  },
};
</script>

<style lang="scss" scoped>
  .body-second-section-pc{
    width: 100%;
    padding-bottom: 90px;
    .step-pc{
      display: flex;
      margin-top: 50px;
      flex-direction: column;
      justify-content: center;
      span{
        font-size: 16px;
        color: #222222;
        letter-spacing: 0.43px;
        text-align: center;
        font-weight: 400;
      }
      .line-pc{
        height: 18px;
        width: 100%;
        position: relative;
        border-left: 1px solid #CFD8DC;
        border-right: 1px solid #CFD8DC;
        .horizontal-line-pc{
          width: 100%;
          height: 1px;
          background-color: #CFD8DC;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .images-pc{
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 36px;
      &>:nth-child(1){
        width: 38.5%;
        height: 38.5%;
      }
      &>:nth-child(2){
        width: 18%;
      }
      &>:nth-child(3){
        width: 18%;
      }
      &>:nth-child(4){
        width: 18%;
      }
    }
  }
  .body-second-section-sp{
    width: 100%;
    padding-bottom: 28px;
    .step-sp{
      display: flex;
      margin-top: 41px;
      height: 382px;
      justify-content: center;
      &>:nth-child(3){
        display: flex;
        align-items: center;
      }
      span{
        font-size: 16px;
        color: #222222;
        letter-spacing: 0.43px;
        text-align: center;
        font-weight: 400;
      }
      @media only screen and (max-width: 374px) {
        span {
          font-size: 12px;
        }
      }
      .line-sp{
        width: 18px;
        height: 100%;
        position: relative;
        border-top: 1px solid #CFD8DC;
        border-bottom: 1px solid #CFD8DC;
        margin: 0 15.5px;
        .horizontal-line-sp{
          height: 100%;
          width: 1px;
          background-color: #CFD8DC;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .images-sp{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 40px;
      &>img{
        margin-bottom: 32px;
      }
      &>:nth-child(1){
        width: 84%;
        height: 84%;
      }
      &>:nth-child(2){
        width: 40%;
        height: 40%;
        margin-right: 2%;
      }
      &>:nth-child(3){
        width: 40%;
        height: 40%;
        margin-left: 2%;
      }
      &>:nth-child(4){
        width: 40%;
        height: 40%;
      }
      @media only screen and (max-width: 500px) {
        &>:nth-child(1){
          width: 92%;
          height: 92%;
        }
        &>:nth-child(2){
          width: 44%;
          height: 44%;
          margin-right: 2%;
        }
        &>:nth-child(3){
          width: 44%;
          height: 44%;
          margin-left: 2%;
        }
        &>:nth-child(4){
          width: 44%;
          height: 44%;
        }
      }
    }
  }
  .text-pc{
    font-size: 23px;
    color: #222222;
    letter-spacing: 0.61px;
    font-weight: 600;
  }
  .description-pc{
    font-size: 16px;
    color: #222222;
    letter-spacing: 0.43px;
    line-height: 28px;
    font-weight: 400;
    margin-top: 26px;
  }
  .text-sp{
    font-size: 19px;
    color: #222222;
    letter-spacing: 0.51px;
    line-height: 26px;
    font-weight: 600;
  }
  .description-sp{
    font-size: 14px;
    color: #222222;
    letter-spacing: 0.37px;
    line-height: 28px;
    font-weight: 400;
    margin-top: 24px;
  }
</style>
