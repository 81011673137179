<template>
  <div class="header-pc">
      <div class="animation-text header-pc-text">
        <p class="description-pc">{{ work.company }}</p>
        <p class="title-pc">
          <span v-for="(text, index) in work.name" :key="index">
            {{text}}
          </span>
        </p>
        <p class="description-pc" v-if="Array.isArray(work.descriptionPC)">
          <span v-for="(text, index) in work.descriptionPC" :key="index">
            {{text}}
          </span>
        </p>
      </div>
      <div class="header-pc-image">
        <img  style="width:100% ;" alt='' :src="getImageUrl(work.screenShot1)" />
      </div>
    </div>
</template>

<script>
import useRenderData from '@/composables/useRenderData';

export default {
  setup() {
    const { getImageUrl } = useRenderData('network-security');
    return {
      getImageUrl,
    };
  },
  props: {
    work: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
  .header-pc{
    background: #F1F3F4;
    display: flex;
    justify-content: center;
    padding: 114px 30px;
    .header-pc-text{
      box-sizing: border-box;
      padding:85px 0;
      max-width: 454px;
      margin-right: 7.5px;
    }
    .header-pc-image{
      max-width: 454px;
      margin-left: 7.5px;
    }
  }
  .title-pc {
    margin-bottom: 85px;
    line-height: 42px;
    font-size: 32px;
    font-weight: bold;
    color: #222222;
    letter-spacing: 0.85px;
  }
  .description-pc {
    margin-bottom: 17px;
    flex: 1 1 auto;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-line;
    color: #222222;
    letter-spacing: 0.43px;
    font-weight: 400;
  }
</style>
