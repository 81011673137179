export default {
  navList: [
    {
      id: 'services',
      title: 'サービス',
      link: '/services',
      subNav: [
        { title: 'モバイルコンサルティング', link: '/services_app-advisory' },
        { title: 'UX/UIデザイン', link: '/services_ui' },
        { title: '新技術を活用したの企画・開発・運用支援', link: '/services_web' },
        { title: 'モバイルアプリ/ウェブアプリの開発', link: '/services_app' },
        { title: 'ミニプログラム/ミニアプリの開発・運用支援', link: '/services_mini-program' },
        {
          title: 'オフィスオートメーションツールの導入・個別開発',
          link: '/services_office-automation',
        },
      ],
    },
    {
      id: 'works',
      title: '実績',
      link: '/works',
      subNav: [],
    },
    { title: 'logo', link: '' },
    {
      id: 'home-about',
      title: '会社情報',
      link: '/home-about',
      subNav: [
        { title: '飛狼数碼（上海）有限公司', link: '/about_sh' },
        { title: '成都市飛狼科技有限公司', link: '/about_cd' },
        { title: '大連飛狼科技有限公司', link: '/about_dl' },
      ],
    },
    {
      id: 'recruit',
      title: '採用',
      link: '/recruit',
      subNav: [
        { title: '事業部門', link: '/recruit_pmo' },
        { title: '開発部門', link: '/recruit_dev' },
        { title: '品質管理部門', link: '/recruit_qa' },
      ],
    },
    {
      id: 'news',
      title: 'ニュース',
      link: '/news',
      subNav: [],
    },
  ],
  privacy_link: 'プライバシーポリシー',
  beianIcp: '蜀ICP备19005068号',
  beian: '川公网安备 51019002002138 号',
  langList: [
    { title: '中文', code: 'zh' },
    { title: '日本語', code: 'ja' },
  ],
  contact: {
    title: '連絡方法',
    emailTitle: 'メール',
    url: 'info@fenrir-inc.com.cn',
    telTitle: '電話',
    tels: ['028-85177409（成都)', '0411-83731889（大連)'],
    contactTitle: 'お問い合わせ',
  },
};
