import { ref, watch } from 'vue';
import { DefaultLang } from '@/constants/constants';
import PageResources from '../model/index';

export default function usePageResources(locale, lang = DefaultLang) {
  const pageResources = ref(PageResources[lang]);
  const updatePageResource = () => {
    pageResources.value = PageResources[locale.value || lang];
  };

  watch(locale, updatePageResource);

  return {
    pageResources,
  };
}
