export default {
  sectionList: [
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          value: '上海飞狼于2022年8月1日搬迁至以下地址进行办公。',
        },
        {
          partType: 'string',
          value:
            '为了回应大家的期待，公司全体员工将更加努力。今后也请一如既往地支持和关照我们。',
        },
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
            "<ul style='list-style-position: inside; padding-left: 1em;'><li>公司名称：飞狼数码（上海）有限公司</li><li>新地址：上海市黄浦区河南南路16号中汇大厦2层</li></ul>",
        },
      ],
    },
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          value: '若您途经附近，欢迎移步本公司。',
        },
        {
          partType: 'string',
          // eslint-disable-next-line max-len
          value: "<strong>联系我们：</strong><br>飞狼数码（上海）有限公司　吉泽 克巳<br>邮箱：<a href='mailto:info@fenrir-inc.com.cn'>info@fenrir-inc.com.cn</a>",
        },
      ],
    },
  ],
};
