export default {
  id: 'contact',
  form: {
    id: 'form',
    title: 'お問い合わせ 以下情報をご入力ください',
    formList: [
      {
        id: 'name',
        name: '氏名',
        desc: '例：田中 太郎',
      },
      {
        id: 'email',
        name: 'メールアドレス',
        desc: '例：sample@example.com',
      },
      {
        id: 'tel',
        name: '電話番号',
        desc: '例：028-85177409',
      },
      {
        id: 'company',
        name: '会社名',
        desc: '例：成都市飛狼科技有限公司',
      },
      {
        id: 'department',
        name: '部署',
        desc: '例：営業部',
      },
      {
        id: 'comment',
        name: 'お問い合わせ内容',
        desc: 'できるだけ具体的にご記入ください。',
      },
    ],
    submit: {
      private: 'について同意します。 プライバシーポリシー',
      name: '送信する',
      popupErrorTip: {
        title: '送信が失敗しました。\n再度「送信する」ボタンを押して\nください。',
        confirmBtn: 'OK',
      },
    },
    error: {
      email: '正しい形式をご入力してください',
      tel: '正しい形式をご入力してください',
      normal: 'ご入力ください',
    },
    reCaptcha: 'このサイトはreCaptchaによって保護されており、\nGoogleの'
             + '<a class="link" target="_black" href="https://policies.google.com/privacy?hl=ja">プライバシーポリシー</a>と'
             + '<a class="link" target="_black" href="https://policies.google.com/terms?hl=ja">利用規約</a>が適用されます。',
  },
  popupTip: {
    id: 'popupTip',
    title: '未送信の内容があります。\nこのページを離れてもよろしい\nでしょうか？',
    cancelBtn: 'キャンセル',
    confirmBtn: 'OK',
  },
};
