<template>
  <main class="main">
    <div class="back" v-if="backVisible">
      <div class="back-content">
        <router-link :to="{ name: 'news', params: { lang: $route.params.lang } }" class="back-link">
          <span>{{ pr.back }}</span>
        </router-link>
      </div>
    </div>
    <article :class="['news-detail', { 'no-back': !backVisible }]">
      <div class="header">
        <p class="date-type">
          <span class="date-type-date">{{ detail.date }}</span>
          <Divider :space="22"/>
          <span>{{ detail.type }}</span>
        </p>
        <h1 class="title">
          <span class="epitome">{{ detail.epitome }}</span>
          {{ detail.title }}
        </h1>
        <div class="image" v-if="detail['mainPicture']">
          <img :src="detail['mainPicture']" alt="contentImg"/>
        </div>
      </div>
      <div :class="['main', { 'main-ja': !isZH }]">
        <section-list :section-list="detail"/>
      </div>
      <div class="done">
        <done v-if="detail.done"/>
      </div>
      <div class="footer">
        <ShareBar :detail="detail"/>
      </div>
    </article>
  </main>
</template>

<script>
import { inject } from 'vue';
import Divider from '@/components/news/Divider.vue';
import usePageResource from '@/composables/usePageResource';
import SectionList from '@/components/news/SectionList.vue';
import ShareBar from '@/components/news/ShareBar.vue';
import Done from '@/components/news/Done.vue';
import { LOCALES } from '@/constants/constants';

export default {
  name: 'news-detail',
  setup() {
    const { pr } = usePageResource('news');
    const locale = inject('locale');
    return {
      pr,
      locale,
    };
  },
  data() {
    return {
      detail: {},
    };
  },
  components: {
    Divider,
    SectionList,
    ShareBar,
    Done,
  },
  watch: {
    pr() {
      this.initDetail();
    },
  },
  created() {
    this.initDetail();
  },
  methods: {
    initDetail() {
      const { id } = this.$route.params;
      if (!id) {
        this.$router.replace('/');
      } else {
        const result = this.pr.list.find((item) => id === item.id);
        if (!result || result.to) {
          this.$router.replace('/');
        } else {
          this.detail = result;
        }
      }
    },
  },
  computed: {
    filterList() {
      return this.pr.list.filter((item) => !item.hidden);
    },
    isZH() {
      return this.locale === LOCALES.ZH;
    },
    backVisible() {
      return this.filterList.length > 5;
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$width: 900px;
$text-color: #212121;
$md: 767px;
$height-header: 56px;

@mixin width-margin($max-width: $width) {
  max-width: $max-width;
  margin: 0 auto;
}

main.main {
  padding: $height-header 0 0;
}

.news-detail {
  padding: 0 20px;
  color: $text-color;
  font-size: 16px;

  &.no-back {
    padding-top: 100px;
  }

  @media only screen and (max-width: $md) {
    font-size: 14px;
  }

  ::selection {
    background-color: #cfd8dc;
  }

  ::v-deep(a) {
    color: #1565c0;
    padding: 0 0 2px 0;
    border-bottom: 1px solid #1565c0;
    text-decoration: none;
  }

  .header {
    @include width-margin;

    .date-type {
      margin-bottom: 24px;
      font-size: 18px;

      &-date {
        color: #a7aeb1;
        letter-spacing: 0.1em;
      }

      @media only screen and (max-width: $md) {
        margin-bottom: 30px;
        font-size: 14px;
      }
    }

    .title {
      padding-bottom: 28px;
      font-size: 23px;
      line-height: 36px;

      .epitome {
        display: block;
        font-size: 18px;
        line-height: 1.5;
      }

      @media only screen and (max-width: 576px) {
        padding-bottom: 24px;
        font-size: 21px;
        line-height: 30px;

        .epitome {
          font-size: 16px;
          margin-bottom: 8px;
        }
      }
    }

    .image {
      width: 100%;
      padding: 20px;
      border: 1.5px solid #ccc;
      text-align: center;
      margin-bottom: 70px;

      img {
        max-width: 100%;
      }
    }
  }

  .main {
    @include width-margin;

    &.main-ja {
      letter-spacing: 0.05em;
    }
  }

  .done {
    @include width-margin;
    margin-bottom: 130px;

    @media only screen and (max-width: $md) {
      margin-bottom: 40px;
    }
  }

  .footer {
    @include width-margin;

    @media only screen and (max-width: $md) {
      padding-bottom: 80px;
    }
  }
}

.back {
  width: 100%;
  margin: 50px auto 70px;
  padding: 0 20px;

  &-content {
    @include width-margin;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1em;
  }

  &-link {
    display: flex;
    align-items: center;
    text-decoration: none;

    &::before {
      content: '';
      background-image: url('../assets/icn_left.svg');
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      margin-right: 7.5px;
    }
  }

  @media screen and (max-width: $md) {
    margin: math.div($height-header, 2) auto math.div($height-header, 2);
  }
}
</style>
