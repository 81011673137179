<template>
  <form @submit="submit" :class="['form', { formJA: !isZH }]">
    <div class="title">
      <p :class="['title-top', { topJA: !isZH }]">
        {{ pr.form.title.split(" ")[0] }}
      </p>
      <p :class="['title-bottom', { bottomJA: !isZH }]">
        {{ pr.form.title.split(" ")[1] }}
      </p>
    </div>
    <div
      :class="['form-item', { itemJA: !isZH }]"
      v-for="(formItem, index) in pr.form.formList"
      :key="index"
    >
      <label
        :class="['form-item-title', { fontJA: !isZH }]"
        :for="formItem.id"
        >{{ formItem.name }}</label
      >
      <div class="form-item-content">
        <input
          v-if="formItem.id !== 'comment'"
          :class="['form-item-content-ipt', { error: error[formItem.id] }]"
          :type="getInputTypeById(formItem.id)"
          autocomplete="off"
          :name="formItem.id"
          :id="formItem.id"
          :placeholder="formItem.desc"
          :value="thisDataKeyValue(formItem.id)"
          @input="handleInputChange(formItem.id)"
          @blur="handleInputBlur(formItem.id)"
        />
        <textarea
          v-if="formItem.id === 'comment'"
          :class="['form-item-content-comment', { error: error[formItem.id] }]"
          name="comment"
          id="comment"
          @blur="handleInputBlur(formItem.id)"
          @input="handleInputChange(formItem.id)"
          :placeholder="formItem.desc"
          cols="30"
          rows="10"
          v-model="comment"
        />
        <p class="form-item-content-errorTip">{{ error[formItem.id] }}</p>
      </div>
    </div>
    <div :class="['submit', { submitJA: !isZH }]">
      <div :class="['submit-title', { titleJA: !isZH }]">
        <div
          :class="[
            'submit-title-checkbox',
            { checked: isCheckedPrivacyPolicy, checkboxJA: !isZH },
          ]"
          @click.prevent="handleCheckBoxClick"
        ></div>
        <div :class="['submit-title-private', { privateJA: !isZH }]">
          <p class="pc">{{ pr.form.submit.private.split(" ")[0] }}</p>
          <p class="privatePolicy pc" @click.prevent="toPrivacyPolicy">
            {{ pr.form.submit.private.split(" ")[1] }}
          </p>
          <p v-if="isZH" class="sp">
            <span>{{ pr.form.submit.private.split(" ")[0] }}</span>
            <span class="privatePolicy" @click.prevent="toPrivacyPolicy">{{
              pr.form.submit.private.split(" ")[1]
            }}</span>
          </p>
          <p v-else class="fontJA sp">
            <span class="privatePolicy" @click.prevent="toPrivacyPolicy">{{
              pr.form.submit.private.split(" ")[1]
            }}</span>
            <span>{{ pr.form.submit.private.split(" ")[0] }}</span>
          </p>
        </div>
      </div>
      <button
        :class="['submit-btn', { canSubmit: canSubmit }]"
        :disabled="!canSubmit || loading"
      >
        {{ pr.form.submit.name }}
      </button>
      <div v-html="reCaptchaText" class="reCaptcha"></div>
      <Popup
        :title="popupTitle"
        :confirmText="popupConfirmText"
        ref="popup" />
    </div>
  </form>
</template>

<script>
import { inject } from 'vue';
import { LOCALES, reCAPTCHASiteKey } from '@/constants/constants';
import usePageResource from '@/composables/usePageResource';
import Popup from '@/components/popup/Popup.vue';

export default {
  components: {
    Popup,
  },
  setup() {
    const { pr } = usePageResource('contact-us');
    const locale = inject('locale');

    return {
      moveTo: inject('moveTo'),
      pr,
      locale,
    };
  },
  data() {
    return {
      name: '',
      email: '',
      tel: '',
      company: '',
      department: '',
      comment: '',
      isCheckedPrivacyPolicy: false,
      canSubmit: false,
      loading: false,
      error: {
        name: '',
        email: '',
        tel: '',
        company: '',
        department: '',
        comment: '',
      },
    };
  },
  computed: {
    isZH() {
      return this.locale === LOCALES.ZH;
    },
    reCaptchaText() {
      return this.pr.form.reCaptcha.replace(/\n/gm, '<br/>');
    },
    popupTitle() {
      return this.pr.form.submit.popupErrorTip.title;
    },
    popupConfirmText() {
      return this.pr.form.submit.popupErrorTip.confirmBtn;
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.name = this.getInLocalStorage('name') || '';
      this.email = this.getInLocalStorage('email') || '';
      this.tel = this.getInLocalStorage('tel') || '';
      this.company = this.getInLocalStorage('company') || '';
      this.department = this.getInLocalStorage('department') || '';
      this.comment = this.getInLocalStorage('comment') || '';
    },
    handleInputBlur(key) {
      const value = this[key];
      this.setInLocalStorage(key, value);
    },
    setInLocalStorage(key, value) {
      localStorage.setItem(key, value);
    },
    getInLocalStorage(key) {
      return localStorage.getItem(key);
    },
    toThanksContact() {
      this.moveTo('thanks-contact');
    },
    submit(e) {
      e.preventDefault();

      this.loading = true;
      grecaptcha.ready(async () => {
        try {
          const token = await grecaptcha.execute(reCAPTCHASiteKey, { action: 'submit' });
          const lang = this.isZH ? 'cn' : 'ja';
          const data = {
            name: this.name,
            email: this.email,
            tel: this.tel,
            department: this.department,
            company: this.company,
            comment: this.comment,
            lang,
            'g-recaptcha-response': token,
          };
          await $.ajax({
            url: '/api/mail',
            type: 'POST',
            data,
          });
          this.setInLocalStorage('comment', '');
          this.toThanksContact();
        } catch (err) {
          console.error(err);
          this.$refs.popup.open();
          this.loading = false;
        }
      });
    },
    thisDataKeyValue(key) {
      return this[key];
    },
    handleCheckBoxClick() {
      this.isCheckedPrivacyPolicy = !this.isCheckedPrivacyPolicy;
      this.checkCanSubmit();
    },
    verification(key) {
      switch (key) {
        case 'email': {
          const reg = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          if (this[key].length === 0) {
            this.error[key] = this.pr.form.error.normal;
          } else if (!reg.test(this[key])) {
            this.error[key] = this.pr.form.error.email;
          } else {
            this.error[key] = '';
          }
          break;
        }
        case 'tel': {
          const reg = /[0-9 | -]$/;
          if (this[key].length === 0) {
            this.error[key] = this.pr.form.error.normal;
          } else if (reg.test(this[key])) {
            this.error[key] = '';
          } else {
            this.error[key] = this.pr.form.error.tel;
          }
          break;
        }
        default: {
          if (this[key].length === 0) {
            this.error[key] = this.pr.form.error.normal;
          } else {
            this.error[key] = '';
          }
        }
      }
    },
    getInputTypeById(id) {
      if (id === 'email') {
        return 'email';
      }
      if (id === 'tel') {
        return 'tel';
      }
      return 'text';
    },
    handleInputChange(key) {
      const e = window.event;
      this[key] = e.target.value;

      this.verification(key);
      this.checkCanSubmit();
    },
    checkCanSubmit() {
      const arrInput = [this.name, this.email, this.tel, this.company, this.department, this.comment];
      const arrError = Object.values(this.error);
      const inputFlag = arrInput.every((iptEle) => iptEle.length > 0);
      const errorFlag = arrError.some((errEle) => errEle.length > 0);
      this.canSubmit = inputFlag && !errorFlag && this.isCheckedPrivacyPolicy;
    },
    toPrivacyPolicy() {
      this.moveTo('privacy-policy');
    },
  },
  beforeUnmount() {
    // getInstance().hideBadge();
  },
};
</script>

<style lang="scss" scoped>
$max-width-sp: 768px;

@mixin boxSize($w, $h) {
  width: $w;
  height: $h;
}

.sp {
  @media screen and(min-width: ($max-width-sp + 1)) {
    display: none;
  }
}
.pc {
  @media screen and(max-width: $max-width-sp) {
    display: none;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 604px;

  &.formJA {
  max-width: 678px;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &-top {
      height: 42px;
      line-height: 42px;
      font-size: 30px;
      letter-spacing: 0.8px;
      margin: 90px 0 14px 0;
      margin-left: 35px;
      font-weight: bold;

      @media screen and (max-width: $max-width-sp) {
        height: 30px;
        line-height: 30px;
        margin: 56px 0 10px 0;
        font-size: 22px;
        letter-spacing: 0.59px;
      }

      &.topJA {
        margin: 96px 0 12px 0;
        height: 30px;
        line-height: 30px;
        font-weight: bold;

        @media screen and (max-width: $max-width-sp) {
          height: 30px;
          line-height: 30px;
          margin: 56px 0 10px 0;
          font-size: 22px;
          letter-spacing: 0.59px;
        }
      }
    }

    &-bottom {
      height: 28px;
      line-height: 28px;
      font-size: 18px;
      letter-spacing: 0.48px;
      margin-bottom: 90px;
      margin-left: 34px;

      @media screen and (max-width: $max-width-sp) {
        font-size: 14px;
        letter-spacing: 0.37px;
        margin: 0 0 39px 0;
      }

      &.bottomJA {
        margin: 0 0 98px 0;

        @media screen and (max-width: $max-width-sp) {
          font-size: 14px;
          letter-spacing: 0.37px;
          margin-bottom: 40px;
        }
      }
    }
  }

  &-item {
    width: 604px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
    letter-spacing: 0.48px;

    @media screen and (max-width: $max-width-sp) {
      width: 87.2%;
      flex-direction: column;
      justify-content: start;
      margin-bottom: 16px;
    }

    &:nth-last-child(2) {
      margin: 0px !important;
    }

    &.itemJA {
      width: 678px;
      letter-spacing: 0.37px;

      @media screen and (max-width: $max-width-sp) {
        width: 87.2%;
      }
    }

    &-title {
      margin-top: 11px;
      line-height: 28px;
      font-size: 18px;
      font-weight: bold;

      @media screen and (max-width: $max-width-sp) {
        line-height: 22px;
        font-size: 16px;
        letter-spacing: 0.43px;
        margin: 0 0 12px 0;
      }

      &.fontJA {
        font-weight: bold;
      }
    }

    &-content {
      width: 490px;
      font-size: 16px;
      letter-spacing: 0.43px;

      @media screen and (max-width: $max-width-sp) {
        width: 100%;
      }

      &-ipt {
        @include boxSize(100%, 50px);
        font-family: inherit;
        letter-spacing: inherit;
        padding: 0 16px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #a6aeb1;
        color: #212121;
        outline: none;
        font-size: 16px;

        &::-webkit-input-placeholder {
          color: #a6aeb1;
        }
        &::-moz-placeholder {
          color: #a6aeb1;
        }
      }

      &-comment {
        @include boxSize(490px, 260px);
        letter-spacing: 0.43px;
        font-size: 16px;
        font-family: inherit;
        border: 1px solid #a6aeb1;
        box-sizing: border-box;
        padding: 14px 16px 0 16px;
        border-radius: 4px;
        color: #212121;
        outline: none;
        resize:none;

        &::-webkit-input-placeholder {
          color: #a6aeb1;
        }
        &::-moz-placeholder {
          color: #a6aeb1;
        }

        @media screen and (max-width: $max-width-sp) {
          @include boxSize(100%, 265px);
        }
      }

      .error {
        border: 1px solid #cd4040;
        background-color: #fff3f3;
      }

      &-errorTip {
        margin-top: 4px;
        color: #cd3f3f;
        line-height: 22px;
        height: 22px;

        @media screen and (max-width: $max-width-sp) {
          font-size: 14px;
          letter-spacing: 0.37px;
          margin-top: 6px;
        }
      }
    }
  }

  .submit {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: column;
    flex-wrap: wrap;
    margin: 40px 0 160px 0;

    &.submitJA {
      max-width: 504px;
    }

    @media screen and (max-width: $max-width-sp) {
      padding: 0 22px 0 23px;
      margin: 52px 0 100px 0;
    }

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      margin-bottom: 32px;
      width: 100%;

      @media screen and (max-width: $max-width-sp) {
        justify-content: start;
      }

      &.titleJA {
        margin-bottom: 31px;

        @media screen and (max-width: $max-width-sp) {
          align-items: flex-start;
          margin-bottom: 32px;
          height: 48px;
        }
      }

      &-private {
        display: flex;
        align-items: center;
        letter-spacing: 0;

        &.fontJA {
          width: 100%;
        }

        span {
          line-height: 24px;
        }

        &.privateJA {
          flex-direction: row-reverse;

          p {
            white-space: nowrap;
          }

          @media screen and (max-width: $max-width-sp) {
            flex-direction: row;
            width: 92.04%;

            p {
              white-space: normal;
            }
          }
        }

        .privatePolicy {
          color: #74797C;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &-checkbox {
        @include boxSize(16px, 16.69px);
        display: inline-block;
        margin: 0 10px 0 0;
        border: 1px solid #a6aeb1;
        box-sizing: border-box;
        border-radius: 2px;

        &.checked {
          background: url("~@/assets/icn_check@3x.png");
          background-size: 100%;
          border: none;
        }

        &.checkboxJA {
          @media screen and (max-width: $max-width-sp) {
            margin-top: 4px;
          }
        }
      }
    }

    &-btn {
      @include boxSize(360px, 50px);
      background-color: #a6aeb1;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 3.2px;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      outline: none;
      cursor: pointer;

      @media screen and (max-width: $max-width-sp) {
        @include boxSize(280px, 50px);
      }

      &.canSubmit {
        background: #212121;
      }
    }

    .reCaptcha {
      line-height: 18px;
      width: 100%;
      margin-top: 40px;
      font-size: 12px;
      font-weight: 400;
      color: #75797B;
      text-align: center;
      letter-spacing: -0.3px;

      :global(.link) {
        cursor: pointer;
      }
    }
  }
}
</style>
