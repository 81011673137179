<template>
  <div>
    <section v-for="(item, i) in sectionList['sectionList']" :key='i'>
      <h3 v-if='item.subtitle' :class="[{ 'subtitle-ja': !isZH }]">{{ item.subtitle }}</h3>
      <template v-for='(part, j) in item.content'>
        <div class='part-string' v-if="part.partType === 'string'" :key='j' v-html='part.value' />
        <part-type-table
          v-if="part.partType === 'table'"
          :key='j'
          :data='part.data'
          :columns='part.cols'
          :col-head-bold='part.colHeadBold'
        />
        <part-type-img v-if="part.partType === 'img'" :key='j' :img='part.value' />
      </template>
    </section>
  </div>
</template>

<script>
import { inject } from 'vue';
import PartTypeTable from '@/components/news/part-type/PartTypeTable.vue';
import { LOCALES } from '@/constants/constants';
import PartTypeImg from '@/components/news/part-type/PartTypeImg.vue';

export default {
  name: 'section-list',
  data() {
    return {};
  },
  setup() {
    const locale = inject('locale');
    return {
      locale,
    };
  },
  props: {
    sectionList: {
      type: Object,
      required: true,
    },
  },
  components: {
    PartTypeImg,
    PartTypeTable,
  },
  computed: {
    isZH() {
      return this.locale === LOCALES.ZH;
    },
  },
};
</script>

<style scoped lang='scss'>
section {
  margin-bottom: 70px;
  line-height: 28px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 40px;
  }

  & > div,
  & > table,
  & > p {
    margin-bottom: 30px;
  }

  .subtitle-ja {
    font-weight: bold;
  }

  h3 {
    font-weight: 500;
    margin-bottom: 20px;
  }

  ::v-deep(.part-string) {
    white-space: pre-line;
  }
}
</style>
