<template>
  <footer class="footer">
    <div class="footer-nav">
      <div class="nav-bar">
        <div :class="['nav',!isSP && (locale === 'ja' ? 'flex-wrap-c-pc-ja' : 'flex-wrap-c-pc')]">
          <template v-for="(nav, i) in navList">
            <ul
              v-if="nav?.subNav?.length"
              :key="i"
              :class="['nav-item', { hidden: !(nav.id && openItems.includes(nav.id)) }]"
            >
              <li :class="['nav-item-link title', { titleJA: !isZH }]">
                <span class="title-link" @click.stop="goTo(`#${nav.id}`)">{{ nav.title }}</span>
                <button
                  :class="['toggle-btn', { hidden: nav.subNav.length <= 0 }]"
                  @click.stop="toggleNav(nav)"
                >
                  +
                </button>
              </li>
              <li
                :class="[
                  'nav-item-link item',
                  { itemJA: !isZH, multiline: item.title.length >= 15 },
                ]"
                v-for="(item, j) in nav.subNav"
                :key="j"
              >
                <a
                  class="item-link"
                  :href="item.link"
                  @click.prevent="nav.id === 'works' ? toWorkDetail(item.link) : clickLink($event)"
                >
                  {{ item.title }}
                </a>
              </li>
            </ul>
            <div v-else-if="nav?.subNav?.length === 0 && isSP" class="nav-item" :key="i+'sp'">
              <router-link
                class="only-one"
                :to="nav?.link || { name: 'news', params: { lang: $route.params.lang } }"
              >
                {{ nav.title }}
              </router-link>
            </div>
            <span v-else :key="i+'pc'" class="nav-item-link title">
              <router-link
                :to="nav?.link || { name: 'news', params: { lang: $route.params.lang } }"
              >
                {{ nav.title }}
              </router-link>
            </span>
          </template>
          <div class="nav-item sp">
            <a class="nav-item-link title fenrir" :href="FenrirLink" target="_blank">Fenrir Inc.</a>
          </div>
          <div class="nav-item sp">
            <div class="nav-item-link title privacy-policy" @click.prevent="toPrivacyPolicy">
              {{ pr.privacy_link }}
            </div>
          </div>
        </div>
        <div class="contact">
          <ul class="nav-item">
            <li :class="['nav-item-link title', { titleJA: !isZH }]">{{ pr.contact.title }}</li>
            <li class="nav-item-link item mail-title disabled">{{ pr.contact.emailTitle }}</li>
            <li class="nav-item-link item mail">
              <a :href="`mailto:${pr.contact.url}`">{{ pr.contact.url }}</a>
            </li>
            <li class="nav-item-link item tel-title disabled">{{ pr.contact.telTitle }}</li>
            <li
              v-for="(tel, index) in pr.contact.tels"
              :key="index"
              :class="['nav-item-link', 'item', 'disabled', 'tel']"
            >
              {{ tel }}
            </li>
          </ul>
          <div class="relation disabled">
            <img class="relation-img" src="~@/assets/wechat_code.jpg" alt="wechat_code" />
            <button class="relation-btn" @click.prevent="toContactUs">
              {{ pr.contact.contactTitle }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-about">
      <div class="about">
        <div class="lang">
          <span
            class="lang-btn"
            v-for="(lang, index) in pr.langList"
            :class="{ checked: locale === lang.code }"
            :key="index"
            @click.prevent="changeLocale(lang.code)"
            >{{ lang.title }}
          </span>
        </div>
        <div class="other">
          <a class="other-link pc fenrir" :href="FenrirLink" target="_blank">Fenrir Inc.</a>
          <a class="other-link pc" href="/privacy-policy" @click.prevent="toPrivacyPolicy">{{
            pr.privacy_link
          }}</a>
          <a class="other-link" href="https://beian.miit.gov.cn/" target="_blank">
            {{ pr.beianIcp }}
          </a>
          <a
            class="other-link"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?domainname=fenrir-inc.com.cn"
            target="_blank"
          >
            {{ pr.beian }}
          </a>
          <span class="other-link copyright">© 2024 Fenrir Inc.</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { inject } from 'vue';
import { LOCALES, FenrirLink } from '@/constants/constants';
import usePageResource from '@/composables/usePageResource';
import useIsSP from '@/composables/useIsSP';

export default {
  name: 'the-footer',
  setup() {
    const { pr } = usePageResource('nav');
    const { isSP } = useIsSP(1230);
    return {
      moveTo: inject('moveTo'),
      locale: inject('locale'),
      updateLocale: inject('updateLocale'),
      pr,
      FenrirLink,
      isSP,
    };
  },
  data() {
    return {
      locals: LOCALES,
      openItems: [],
    };
  },
  computed: {
    isZH() {
      return this.locale === LOCALES.ZH;
    },
    navList() {
      return this.pr.navList.filter((nav) => nav.title !== 'logo') || [];
    },
  },
  methods: {
    toHome() {
      this.moveTo('home');
    },
    toPrivacyPolicy() {
      this.moveTo('privacy-policy');
    },
    toContactUs() {
      this.moveTo('contact-us');
    },
    toWorkDetail(id) {
      this.$router.push({ name: 'work-detail', params: { lang: this.$route.params.lang, id } });
    },
    changeLocale(locale) {
      this.updateLocale(locale);
    },
    toggleNav(nav) {
      const index = this.openItems.indexOf(nav.id);
      if (index < 0) {
        this.openItems.push(nav.id);
      } else {
        this.openItems.splice(index, 1);
      }
    },
    clickLink(e) {
      const targetId = $(e.target).attr('href').replace('/', '#');
      this.goTo(targetId);
    },
    goTo(targetId) {
      const { name, parmas } = this.$route;
      if (name === 'home') {
        this.scrollTo(targetId);
      } else {
        this.$router.push({ name: 'home', params: { ...parmas, targetId } });
      }
    },
    scrollTo(targetId, animate = 1000) {
      let $target = null;
      if (targetId.startsWith('#about_')) {
        if ($('#about-info-sp').is(':visible')) {
          $target = $(`#about-info-sp > ${targetId}`);
        } else {
          $target = $('#about-info-pc');
          this.$bus.$emit('show-about-info', targetId.split('_')[1]);
        }
      } else {
        $target = $(targetId);
      }

      const $body = $('body');
      if ($target.length) {
        $body.addClass('disabled');
        $('html, body')
          .delay(this.menuCloseDuration)
          .stop()
          .animate(
            {
              scrollTop: $target.offset().top - 56,
            },
            animate,
            () => $body.removeClass('disabled'),
          );
      }
    },
  },
};
</script>

<style scoped lang="scss">
$max-width-sp: 1230px;

.sp {
  @media screen and(min-width: ($max-width-sp + 1)) {
    display: none;
  }
}

.pc {
  @media screen and(max-width: $max-width-sp) {
    display: none;
  }
}

.footer {
  width: 100%;
  background-color: #222222;
  color: #a6aeb1;
  text-align: center;
  font-size: 11px;

  &-nav {
    max-width: 1200px;
    margin: 0 auto;
  }

  &-about {
    width: 100%;
    background-color: #1a1a1a;
  }

  .nav-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 71px 0 71px 0;

    @media screen and(max-width: $max-width-sp) {
      padding: 32px 0 70px 0;
      flex-direction: column;
      align-items: stretch;
    }

    .nav-item {
      margin-right: 11px;
      width: 185px;
      text-align: left;
      list-style-type: none;
      margin-bottom: 10px;

      &-link {
        display: block;
        font-size: 13px;
        color: #a6aeb1;
        margin-bottom: 10px;
        letter-spacing: 0.8px;
        text-decoration: none;
        line-height: 18px;
        text-size-adjust: none;

        @media screen and(min-width: ($max-width-sp + 1)) {
          margin-bottom: 12px;

          &.itemJA {
            line-height: 13px;
            margin-bottom: 14px;

            &.multiline {
              line-height: 19px;
            }
          }
        }

        a {
          text-decoration: none;
        }

        &.title {
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          line-height: 21px;
          font-size: 15px;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 24px;
          letter-spacing: 1.5px;
          cursor: pointer;

          &.titleJA {
            line-height: 15px;
            margin-bottom: 31px;

            @media screen and(max-width: $max-width-sp) {
              margin-bottom: 9px;
            }
          }

          @media screen and(min-width: ($max-width-sp + 1)) {
            font-size: 15px;
          }

          .title-link {
            flex-grow: 1;

            &:hover {
              opacity: 0.4;
            }
          }

          .toggle-btn {
            width: 0;
            margin: 0;
            padding: 0;
            color: transparent;
            background: center right / 11px no-repeat url('~@/assets/icon_x.png');
            border: none;
            outline: 0;
            transition: background-image 0.3s;
            cursor: pointer;

            &.hidden {
              display: none;
            }
          }

          @media screen and(max-width: $max-width-sp) {
            margin-bottom: 7px;
            height: 43px;
            font-weight: 400;

            .title-link {
              line-height: 43px;
            }

            .toggle-btn {
              width: 40%;
            }

            &.fenrir,
            &.privacy-policy {
              margin-bottom: 0;
              color: #a6aeb1;
              background-image: none;
              line-height: 44px;

              &:hover {
                opacity: 0.4;
              }
            }
          }
        }

        &.item {
          letter-spacing: 0.8px;

          &.disabled {
            cursor: auto;

            &:hover {
              opacity: 1;
            }
          }

          .item-link {
            cursor: pointer;

            &:hover {
              opacity: 0.4;
            }
          }
        }
      }

      &-link.item:last-child {
        margin-bottom: 18px;
      }

      &.hidden {
        .nav-item-link.title {
          @media screen and(max-width: $max-width-sp) {
            .toggle-btn {
              background-image: url('~@/assets/icon_+.png');
            }
          }
        }
      }
    }

    .flex-wrap-c-pc{
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 250px;
      justify-content: flex-start;

      & > :nth-child(n){
        margin-right: 75px;
      }
    }
    .flex-wrap-c-pc-ja{
      @extend .flex-wrap-c-pc;
      max-height: 300px;
    }
    .nav {
      display: flex;
      @media screen and(max-width: $max-width-sp) {
        flex-direction: column;
        padding: 0 32px;
        flex-wrap: nowrap;

        .nav-item {
          width: 100%;
          max-height: 999px;
          overflow: hidden;
          flex: none;
          border-bottom: 1px solid #74787a;
          transition: max-height 400ms ease-in-out;

          .item {
            opacity: 1;
            transition: opacity 600ms ease-out;

            @for $i from 1 through 10 {
              &:nth-of-type(#{$i}) {
                transition-delay: (#{($i - 2) * 50ms+10ms});
              }
            }
          }

          &.hidden {
            max-height: 44px;
            transition: max-height 400ms cubic-bezier(0.165, 0.84, 0.44, 1);

            .item {
              opacity: 0;
            }
          }

          &-link {
            width: 100%;
          }
        }
      }
    }

    .contact {
      display: flex;
      justify-content: space-between;
      min-width: 184px;

      .nav-item {
        margin-right: 0;
      }

      .nav-item:nth-child(1) {
        width: 184px;
      }

      .nav-item:nth-last-child(1) {
        @media screen and(min-width: ($max-width-sp + 1)) {
          margin-left: 62px;
        }
      }

      @media screen and(max-width: $max-width-sp) {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        padding-left: 0px;

        .nav-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          white-space: nowrap;

          &:first-of-type {
            margin-bottom: 24px;
          }
        }
      }
      .nav-item-link {
        &.title {
          cursor: auto;
          margin-bottom: 27px;

          &:hover {
            opacity: 1;
          }
        }

        &.item {
          height: 20px;
          font-size: 14px;
          letter-spacing: 0.37px;
          line-height: 20px;
          width: 100%;

          &.mail {
            margin-bottom: 20px;
            padding: 0;
            cursor: pointer;

            &-title {
              padding-left: 26px;
              margin-bottom: 6px;
              background-size: 16px 12px;
              background-repeat: no-repeat;
              background-position: 0 4px;
              background-image: url('~@/assets/icon_mail.png');

              @media screen and (max-width: $max-width-sp) {
                display: none;
              }
            }

            @media screen and (max-width: $max-width-sp) {
              padding-left: 26px;
              margin-bottom: 16px;
              background-size: 16px 12px;
              background-repeat: no-repeat;
              background-position: 0 4px;
              background-image: url('~@/assets/icon_mail.png');
            }
          }

          &.tel {
            margin-bottom: 4px;
            padding-left: 2px;

            &-title {
              padding-left: 26px;
              margin-bottom: 6px;
              background-position: 2.11px 1.505px;
              background-size: 13.77px 16.99px;
              background-repeat: no-repeat;
              background-image: url('~@/assets/icon_tel.png');

              @media screen and (max-width: $max-width-sp) {
                display: none;

                & + .tel {
                  background-position: 2.11px 1.505px;
                  background-size: 13.77px 16.99px;
                  background-repeat: no-repeat;
                  background-image: url('~@/assets/icon_tel.png');
                }
              }
            }

            @media screen and (max-width: $max-width-sp) {
              padding-left: 26px;
              margin-bottom: 6px;
            }
          }
        }
        @media screen and(max-width: $max-width-sp) {
          &.title {
            padding: 0;
            margin-top: 40px;
            margin-bottom: 24px;
            height: 20px;
            background-image: none;
          }
        }
      }

      .nav-item-link:nth-last-child(1) {
        margin: 0;
      }

      .relation {
        margin-left: 82px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: $max-width-sp) {
          margin-left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .relation-img {
          width: 150px;
          height: 150px;
          margin-bottom: 20px;

          @media screen and (max-width: $max-width-sp) {
            width: 120px;
            height: 120px;
            margin-bottom: 40px;
          }
        }
        .relation-btn {
          padding: 0;
          width: 150px;
          height: 42px;
          color: #ffffff;
          letter-spacing: 2.8px;
          background-color: #222222;
          border: 1px solid #a6aeb1;
          border-radius: 4px;
          outline: none;
          cursor: pointer;

          &:hover {
            color: #222222;
            background-color: #a7aeb1;
          }

          @media screen and (max-width: $max-width-sp) {
            width: 176px;
            height: 40px;
            letter-spacing: 1.6px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .about {
    height: 105px;
    width: 100%;
    padding: 30px 0;
    max-width: 1200px;
    margin: 0 auto;

    @media screen and(max-width: $max-width-sp) {
      padding: 20px 30px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .lang {
      text-align: left;
      &-btn {
        display: inline-block;
        height: 18px;
        line-height: 18px;
        padding-left: 5px;
        padding-right: 5px;
        border-right: 1px solid #a6aeb1;
        font-size: 13px;
        color: #a6aeb1;
        cursor: pointer;

        &:hover {
          opacity: 0.4;
        }
        &.checked {
          font-weight: bold;
          color: #ffffff;
          cursor: auto;

          &:hover {
            opacity: 1;
          }
        }
        &:nth-child(1) {
          padding-left: 0;
        }
        &:nth-last-child(1) {
          border: none;
        }

        @media screen and(max-width: $max-width-sp) {
          font-size: 14px;
        }
      }
    }

    .other {
      margin-top: 10px;
      position: relative;
      text-align: left;
      &-link {
        text-decoration: none;
        font-size: 12px;
        color: #a6aeb1;
        margin-right: 30px;
        line-height: 17px;
        cursor: pointer;

        &:hover {
          opacity: 0.4;
        }

        &.copyright {
          float: right;
          cursor: auto;
          letter-spacing: 0.05em;
          font-weight: 300;
          font-family: -apple-system,
            BlinkMacSystemFont,
            "PingFang SC",
            "Hiragino Sans GB",
            "Microsoft YaHei",
            sans-serif !important;

          &:hover {
            opacity: 1;
          }
        }
      }

      @media screen and(max-width: $max-width-sp) {
        margin-top: 18px;
        margin-left: -6px;
        margin-right: -6px;
        max-width: 335px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &-link {
          margin: 4px 6px;
        }
      }

      @media screen and(max-width: 365px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.nav-item .only-one {
  height: 43px;
  line-height: 43px;
  font-size: 15px;
  color: #fff;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
}
</style>
