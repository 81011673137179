export default {
  title: '採用',
  subtitle: 'スピード感をもって成長し続けたい\nプロフェッショナルが居るべき場所',
  description: [
    ['当社では、個人成長とワークライフバランスを重視し、魅力的な業務環境、充分な昇進トレーニング、', '多種多様な福利厚生を用意しています。'],
    ['志向が高く、チャレンジ精神とチーム意識を持つ人物を求めています。'],
  ],
  descriptionMobile:
    '当社では、個人成長とワークライフバランスを重視し、魅力的な業務環境、充分な昇進トレーニング、多種多様な福利厚生を用意しています。\n志向が高く、チャレンジ精神とチーム意識を持つ人物を求めています。',
  list: [
    {
      id: 'pmo',
      name: '事業部門',
      jobs: [
        {
          name: 'ITコンサルティング営業',
        },
        {
          name: 'ITプロジェクトマネージャー',
        },
      ],
    },
    {
      id: 'dev',
      name: '開発部門',
      jobs: [
        {
          name: 'プロジェクトマネージャー',
        },
        {
          name: 'iOS エンジニア',
        },
        {
          name: 'Android エンジニア',
        },
        {
          name: 'Web エンジニア',
        },
        {
          name: 'バックエンドエンジニア',
        },
      ],
    },
    {
      id: 'qa',
      name: '品質管理部門',
      jobs: [
        {
          name: 'テストエンジニア',
        },
      ],
    },
  ],
};
