<template>
  <div :class="{ 'box': box }">
    <div
      :class="['title', titleType, { 'dot': titleDot }]"
      v-html="title">
    </div>
    <div :class="['desc', descType]" v-if="desc" v-html="getText(desc)"></div>
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: String,
    titleType: { type: String, default: '' },
    desc: String,
    descType: String,
    titleSmall: Boolean,
    titleDot: Boolean,
    deseClass: Boolean,
    box: { type: Boolean, default: true },
  },
  setup() {
    const getText = (value) => value.replace(/\n/gm, '<br/>');
    return {
      getText,
    };
  },
});
</script>

<style lang="scss" scoped>
$fontColor: #303233;

.box {
  margin-bottom: 60px;
  color: $fontColor;

  @media screen and (max-width: 768px) {
    margin-bottom: 32px;
  }
}

.police-content {
  padding-left: 16px;
}

.title {
  position: relative;
  line-height: 1.5;

  &.title-first {
    margin-bottom: 28px;
    font-size: 18px;
    font-weight: bold;
    color: #303233;
  }

  &.title-secend {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.05em;
  }

  &.dot {
    line-height: 2;
    padding-left: 16px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;

    &::before {
      content: '・';
      position: absolute;
      left: 0;
      transform: translateX(-50%);
      width: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    &.title-first {
      margin-bottom: 16px;
      font-size: 15px;
      letter-spacing: 0.1em;
    }

    &.title-secend {
      font-size: 14px;
    }
  }
}

.desc {
  line-height: 1.8;
  font-size: 16px;
  letter-spacing: 0.1em;
  margin-bottom: 20px;

  &.desc-large {
    margin-bottom: 36px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    letter-spacing: 0;
  }
}
</style>
