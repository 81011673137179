export default {
  id: 'contact',
  form: {
    id: 'form',
    title: '联系我们 请填写表单信息，我们将尽快与您联系',
    formList: [
      {
        id: 'name',
        name: '姓名',
        desc: '请输入您的姓名',
      },
      {
        id: 'email',
        name: '邮箱',
        desc: '例：sample@example.com',
      },
      {
        id: 'tel',
        name: '电话',
        desc: '例：028-85177409',
      },
      {
        id: 'company',
        name: '公司名称',
        desc: '例：成都市飞狼科技有限公司',
      },
      {
        id: 'department',
        name: '所属部门',
        desc: '例：营业部',
      },
      {
        id: 'comment',
        name: '留言',
        desc: '请尽可能具体地描述您希望了解的信息。',
      },
    ],
    submit: {
      private: '已阅读并同意飞狼的 隐私政策',
      name: '提交',
      popupErrorTip: {
        title: '提交失败，请重试。',
        confirmBtn: '确认',
      },
    },
    error: {
      email: '请输入正确的格式',
      tel: '请输入正确的格式',
      normal: '请输入内容',
    },
    reCaptcha: '本网站受reCaptcha保护，\n适用于Google的'
             + '<a class="link" target="_black" href="https://policies.google.com/privacy?hl=zh">隐私政策</a>和'
             + '<a class="link" target="_black" href="https://policies.google.com/terms?hl=zh">使用条款</a>。',
  },
  popupTip: {
    id: 'popupTip',
    title: '有未提交的内容。\n确定要离开这个页面吗？',
    cancelBtn: '取消',
    confirmBtn: '确认',
  },
};
