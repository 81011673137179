<template>
  <section class="recruit-content">
    <Department v-for="(obj, index) in list" :key="index" :dept="obj"/>
  </section>
</template>

<script>
import usePageResource from '@/composables/usePageResource';
import Department from './Department.vue';

export default {
  name: 'recruit-content',
  components: { Department },
  setup() {
    const { pr } = usePageResource('recruit');
    return {
      pr,
    };
  },
  computed: {
    list() {
      return this.pr.list;
    },
  },
};
</script>

<style scoped lang="scss">
.recruit-content {
  margin: 0 auto 100px;
  padding-bottom: 52px;
  max-width: 1230px;
  background-color: #fff;

  @media only screen and (max-width: 1230px) {
    margin-bottom: 0;
    padding-bottom: 70px;
  }
}
</style>
