<template>
  <div :class="['splash-wrapper', { ja: !isZH }]">
    <div>
      <img id="home-logo" class="logo" :src="require('../assets/fenrir_logo.svg')" />
      <p class="title pc" v-html="titlePC"></p>
      <p class="title sp" v-html="titleSP"></p>
      <p class="description pc">{{ description1PC }}</p>
      <p class="description sp">{{ description1SP }}</p>
      <p v-if="description2" class="description description2">{{ description2 }}</p>
    </div>
    <div class="scroll-arrow">
      <img  alt='' :src="require('../assets/first_view_arrow.svg')" />
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { LOCALES } from '@/constants/constants';
import usePageResource from '@/composables/usePageResource';

export default {
  name: 'Splash',
  computed: {
    isZH() {
      return this.locale === LOCALES.ZH;
    },
    titlePC() {
      return this.pr.title_pc.replace(/\n/gm, '<br/>');
    },
    titleSP() {
      return this.pr.title_sp.replace(/\n/gm, '<br/>');
    },
    description1PC() {
      return this.pr.description1_pc;
    },
    description1SP() {
      return this.pr.description1_sp;
    },
    description2() {
      return this.pr.description2;
    },
  },
  setup() {
    const { pr } = usePageResource('splash');
    const locale = inject('locale');
    return {
      pr,
      locale,
    };
  },
  // mounted() {
  //   if (/mobi|tablet|ipad|iphone|android/.test(navigator.userAgent.toLowerCase())) {
  //     const splash = $('.splash-wrapper');
  //     const viewportHeight = window.innerHeight;
  //     splash.css({ height: viewportHeight });
  //   }
  // },
};
</script>

<style scoped lang="scss">
  .splash-wrapper {
    height: 830px;
    box-sizing: border-box;
    padding: 56px 64px 9px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    color: #464849;
  }

  .logo {
    margin-right: 4px;
    width: 137.7px;
    opacity: 0;
    animation: 1s cubic-bezier(0.165, 0.84, 0.44, 1) 200ms 1 forwards fade-in;
  }

  .title {
    margin-top: 80px;
    margin-bottom: 34px;
    opacity: 0;
    line-height: 42px;
    font-size: 30px;
    font-weight: bold;
    animation: 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 500ms 1 forwards slide-up-fading;
    color: #222222;
  }

  .description {
    flex: 1 1 auto;
    opacity: 0;
    font-size: 18px;
    line-height: 35px;
    white-space: pre-line;
    animation: 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 800ms 1 forwards slide-up-fading;
    color: #222;
  }

  .ja {
    .logo {
      margin-right: 8px;
    }

    .title {
      margin-top: 86px;
      margin-bottom: 32px;
      line-height: 30px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .title {
      font-size: 3.5vw;
    }

    .description {
      font-size: 1.8vw;
    }
  }

  .description2 {
    margin-top: 16px;
  }

  .scroll-arrow {
    display: flex;
    justify-content: center;
    height: 21.06px;
    padding-top: 108px;
    box-sizing: content-box;
    opacity: 0;
    animation: 600ms cubic-bezier(0.165, 0.84, 0.44, 1) 2.7s 1 forwards slide-down-fading;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (min-width: 769px) {
    .sp {
      &.title, &.description {
        margin: 0;
        height: 0;
        // visibility: hidden;
        display: none;
      }
    }
  }

  @media only screen and (max-width: 768px) {
      .splash-wrapper {
        padding: 56px 0 9px;
        height: 630px;
      }

      .logo {
        margin-right: 0;
        width: 68.85px;
        height: 96px;
      }

      .title {
        margin-top: 46px;
        margin-bottom: 26px;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.5px;
      }

      .description {
        font-size: 14px;
        line-height: 30px;
      }

      .description2 {
        margin-top: 12px;
      }

      .scroll-arrow {
        padding-top: 66px;
        width: 10.69vw;
        height: 2.86vh;
      }

      .pc {
        &.title, &.description {
          margin: 0;
          height: 0;
          // visibility: hidden;
          display: none;
        }
      }

      .ja {
        .logo {
          margin-right: 0;
        }

        .title {
            margin-top: 39px;
            margin-bottom: 26px;
        }
      }
    }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-up-fading {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slide-down-fading {
    0% {
      opacity: 0;
      transform: translateY(-8px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
</style>
