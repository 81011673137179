<template>
  <main class="news">
    <h1 class="news-title">{{ pr.title }}</h1>
    <NewsContent
      :list="filterList"
      :animation="false"
      :description="true"
      :image="true"
      :max-length="pageSize"
      :page-num="pageNum"
    />
    <Pagination :total="filterList.length" v-model:pageNum="pageNum" />
  </main>
</template>

<script>
import { inject } from 'vue';
import usePageResource from '@/composables/usePageResource';
import NewsContent from '@/components/news/NewsContent.vue';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'news-list',
  components: {
    Pagination,
    NewsContent,
  },
  data() {
    return {
      pageSize: 10,
      pageNum: 1,
    };
  },
  setup() {
    const { pr } = usePageResource('news');
    const locale = inject('locale');
    return {
      pr,
      locale,
    };
  },
  computed: {
    filterList() {
      return this.pr.list.filter((item) => !item.hidden);
    },
  },
  beforeRouteEnter(to, from, next) {
    const { name } = from;
    if (name !== 'news-detail') {
      next();
      return;
    }
    next((vm) => {
      const storageNum = window.sessionStorage.getItem('pageNum');
      // eslint-disable-next-line no-param-reassign
      if (storageNum) vm.pageNum = Number(storageNum);
    });
  },
  beforeRouteLeave() {
    window.sessionStorage.setItem('pageNum', this.pageNum);
  },
};
</script>

<style lang="scss" scoped>
$height-header: 56px;
$breakpoints-md: 768px;
$height-top: 100px;
.news {
  padding: ($height-header + $height-top) 6.4vw 0;

  &-title {
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin-bottom: 1.66em;
  }

  @media screen and (max-width: $breakpoints-md) {
    padding: ($height-header * 2) 6.4vw 0;

    .news-title {
      font-size: 22px;
    }
  }
}
</style>
