<template>
  <div class="pagination" :style="styleCss">
    <LinkBtn
      data-type="first"
      :data-page="-pageCount"
      tag="button"
      type="button"
      :class="['pagination-item', { disabled: isFirst }]"
      @click="commonClick"
    >
      <img :src="require('@/assets/icn_first.svg')" alt="icon_first" />
    </LinkBtn>
    <LinkBtn
      data-type="prevMany"
      data-page="-5"
      tag="button"
      type="button"
      :class="['pagination-item', 'pagination-many', { disabled: isFirst }]"
      @click="commonClick"
    >
      <img :src="require('@/assets/icn_left.svg')" alt="icn_left" />
      <span>5</span>
    </LinkBtn>
    <LinkBtn
      data-type="prev"
      data-page="-1"
      tag="button"
      type="button"
      :class="['pagination-item', { disabled: isFirst }]"
      @click="commonClick"
    >
      <img :src="require('@/assets/icn_left.svg')" alt="icn_left" />
    </LinkBtn>

    <div class="pagination-item page">
      <span class="page-num">{{ pageNum }}</span>
      <span>/</span>
      <span class="page-count">{{ pageCount }}</span>
    </div>

    <LinkBtn
      data-type="next"
      data-page="1"
      tag="button"
      type="button"
      :class="['pagination-item', { disabled: isLast }]"
      @click="commonClick"
    >
      <img :src="require('@/assets/icn_right.svg')" alt="icn_right" />
    </LinkBtn>
    <LinkBtn
      data-type="nextMany"
      data-page="5"
      tag="button"
      type="button"
      :class="['pagination-item', 'pagination-many', { disabled: isLast }]"
      @click="commonClick"
    >
      <span>5</span>
      <img :src="require('@/assets/icn_right.svg')" alt="icn_right" />
    </LinkBtn>
    <LinkBtn
      data-type="last"
      :data-page="pageCount"
      tag="button"
      type="button"
      :class="['pagination-item', { disabled: isLast }]"
      @click="commonClick"
    >
      <img :src="require('@/assets/icn_last.svg')" alt="icn_last" />
    </LinkBtn>
  </div>
</template>

<script>
import LinkBtn from '@/components/common/LinkBtn.vue';

export default {
  name: 'Pagination',
  components: { LinkBtn },
  data() {
    return {};
  },
  emits: [
    'onFirstClick',
    'onPrevManyClick',
    'onPrevClick',
    'onNextClick',
    'onNextManyClick',
    'onLastClick',
    'update:pageNum',
  ],
  methods: {
    correctNum(val) {
      if (val >= 1 && val <= this.pageCount) {
        return val;
      }
      if (val < 1) return 1;
      return this.pageCount;
    },
    up() {
      document.documentElement.scrollTop = 0;
    },
    commonClick(e) {
      const {
        dataset: { type, page },
      } = e.currentTarget;
      const num = this.correctNum(Number(page) + this.pageNum);
      const eventName = this.emitMap.get(type);
      if (eventName) this.$emit(eventName, num);
      this.$emit('update:pageNum', num);
      if (this.isClickedTop) this.up();
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.pageSize);
    },
    isFirst() {
      return this.pageNum === 1;
    },
    isLast() {
      return this.pageNum === this.pageCount;
    },
    emitMap() {
      return new Map([
        ['first', 'onFirstClick'],
        ['prevMany', 'onPrevManyClick'],
        ['prev', 'onPrevClick'],
        ['next', 'onNextClick'],
        ['nextMany', 'onNextManyClick'],
        ['last', 'onLastClick'],
      ]);
    },
  },
  props: {
    pageSize: {
      type: Number,
      default: 10,
      validator(value) {
        return value > 0;
      },
    },
    pageNum: {
      type: Number,
      default: 1,
      validator(value) {
        return value > 0;
      },
    },
    total: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0;
      },
    },
    isClickedTop: {
      type: Boolean,
      default: true,
    },
    styleCss: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
$breakpoints-md: 768px;

.pagination {
  max-width: 600px;
  margin: 70px auto 160px;
  display: flex;
  font-size: 16px;

  @media screen and (max-width: $breakpoints-md) {
    margin: 40px auto 100px;
    font-size: 14px;

    .pagination-many {
      display: none;
    }

    .pagination-item {
      font-size: 14px;
      margin-right: 0.75em;
    }
  }

  &-item {
    margin-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    font-size: 16px;
    user-select: none;

    &:last-child {
      margin-right: 0;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

.page {
  $fill: 28px;
  flex: 1;
  font-weight: bold;

  &-num {
    margin-right: $fill;
  }

  &-count {
    margin-left: $fill;
  }

  @media screen and (max-width: $breakpoints-md) {
    $fill: 12px;
    &-num {
      margin-right: $fill;
    }

    &-count {
      margin-left: $fill;
    }
  }
}
</style>
