<template>
  <div class="works-swipe-wrapper">
    <swiper class="works-swiper"
            :mousewheel="true"
            @slideChange="onSlideChange"
    >
      <template v-for="(work, index) in works">
        <swiper-slide v-if="work?.visible?.sp ?? true" :virtualIndex="index" :key="work.id" class="swiper-item">
          <work-card :work="work" :detailLabel="detailLabel"></work-card>
        </swiper-slide>
      </template>

    </swiper>
    <div class="dots-wrapper">
      <div class="dots">
        <template v-for="(works, index) in works">
          <div v-if="works?.visible?.sp ?? true" :key="works.id"
               class="dot" :class="{active: index === currentIndex}">
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import 'swiper/swiper.scss';
import { Swiper, SwiperSlide } from 'swiper/vue';
import WorkCard from '@/components/works/WorkCard.vue';

export default {
  name: 'WorksSwipe',
  components: {
    WorkCard,
    Swiper,
    SwiperSlide,
  },
  props: {
    works: {
      type: Array,
      default: () => [],
    },
    detailLabel: {
      type: String,
      default: '查看详情',
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    onSlideChange(swiper) {
      this.currentIndex = swiper.realIndex;
    },
  },
};
</script>

<style scoped lang="scss">
.works-swipe-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #464849;
  overflow-x: hidden;

  .works-swiper {
    width: 100%;
    flex: 1 1 auto;
  }

  .dots-wrapper {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 42px;
    background: transparent;

    .dots {
      display: flex;
      justify-content: center;
      align-items: center;

      .dot {
        width: 10px;
        height: 10px;
        margin: 0 12px;
        border-radius: 5px;
        background: #CCCCCC;

        &.active {
          background: #212121;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .works-swipe-wrapper {
    .works-swiper {
      $space: 24px;
      margin: 0 (-$space);
      width: calc(100% + #{$space * 2});
    }
  }
}
</style>
