<template>
  <header class="header" ref="header" @click.stop="close">
    <nav :class="['header-nav', { active: showNavList, 'is-iOS': isiOS }]" @click.stop>
      <ul class="header-sp">
        <template v-for="(item, index) in pr.navList">
          <li class="header-link" :key="index" v-if="item.title !== 'logo' && item.id !== 'news'">
            <a
              class="header-link-a"
              :href="item.id === 'works' ? '/work-cards' : item.link"
              @click.prevent="goTo"
            >
              {{ item.title }}
            </a>
          </li>
          <li
            v-if="item.title === 'logo'"
            :key="index"
            :class="['header-logo', { hidden: !shouldShowLogo }]"
          >
            <router-link
              :to="{ name: 'home', params: { lang: $route.params.lang } }"
              class="header-logo-link"
            >
              <img src="@/assets/fenrir_logo.svg" class="header-logo-img" />
            </router-link>
          </li>
        </template>
        <li class="header-menu">
          <div class="menu-btn-before"></div>
          <a class="header-menu-btn" href="#" @click.prevent="toggle"></a>
          <div class="menu-btn-after"></div>
        </li>
      </ul>
      <ul :class="['arrow-list header-list', { active: showNavList }]">
        <template v-for="(item, index) in pr.navList">
          <li :key="index" class="arrow-list-item" v-if="item.title !== 'logo'">
            <a :href="item.id === 'works' ? '/work-cards' : item.link" @click.prevent="goTo">{{ item.title }}</a>
          </li>
        </template>
      </ul>
    </nav>
  </header>
</template>

<script>
import { inject } from 'vue';
import usePageResource from '@/composables/usePageResource';

export default {
  name: 'navigation',
  emits: ['toggle'],
  setup() {
    const { pr } = usePageResource('nav');
    const isiOS = inject('isiOS');
    return {
      pr,
      isiOS,
    };
  },
  data() {
    return {
      showNavList: false,
      userInteractionEnabled: true,
      shouldShowLogo: false,
      menuCloseDuration: 400,
    };
  },
  watch: {
    showNavList(val) {
      const vm = this;
      const active = 'active';
      const { classList } = vm.$refs.header;
      if (val && !classList.contains(active)) {
        classList.add(active);
        vm.$emit('toggle', true);
      } else {
        vm.userInteractionEnabled = false;
        vm.$emit('toggle', false);
        setTimeout(() => {
          classList.remove(active);
          vm.userInteractionEnabled = true;
        }, vm.menuCloseDuration);
      }
    },
    $route(to) {
      const { targetId } = to.params;
      this.updateLogoWithPath(to.path);

      setTimeout(() => {
        this.updateLogoWithOffsetY();
        if (targetId) {
          this.scrollTo(targetId, 0);
        }
      }, 0);
    },
  },
  created() {
    this.updateLogoWithPath(this.$route.name);
    $(window).scroll(() => {
      this.updateLogoWithOffsetY();
    });
  },
  mounted() {
    this.updateLogoWithOffsetY();
  },
  methods: {
    toggle() {
      if (!this.userInteractionEnabled) return;
      this.showNavList = !this.showNavList;
    },
    close() {
      if (!this.userInteractionEnabled) return;
      this.showNavList = false;
    },
    updateLogoWithPath(name) {
      if (name !== 'home') {
        this.shouldShowLogo = true;
        return;
      }
      this.shouldShowLogo = false;
    },
    updateLogoWithOffsetY() {
      const $homeLogo = $('#home-logo');
      if ($homeLogo.length) {
        const hT = $homeLogo.offset().top;
        const hH = $homeLogo.outerHeight();
        const sT = window.pageYOffset;
        this.shouldShowLogo = sT > hT + hH;
      }
    },
    goTo(e) {
      const targetId = $(e.target).attr('href').replace('/', '#');
      const { name, params } = this.$route;
      if (name === 'home') {
        this.scrollTo(targetId);
      } else {
        this.$router.push({ name: 'home', params: { ...params, targetId } });
      }
    },
    scrollTo(targetId, animate = 1000) {
      this.close();

      let $target = null;
      if (targetId.startsWith('#about_')) {
        if ($('#about-info-sp').is(':visible')) {
          $target = $(`#about-info-sp > ${targetId}`);
        } else {
          $target = $('#about-info-pc');
          this.$bus.$emit('show-about-info', targetId.split('_')[1]);
        }
      } else {
        $target = $(targetId);
      }

      const $body = $('body');
      if ($target.length) {
        $body.addClass('disabled');
        $('html, body')
          .delay(this.menuCloseDuration)
          .stop()
          .animate(
            {
              scrollTop: $target.offset().top - 56,
            },
            animate,
            () => $body.removeClass('disabled'),
          );
      }
    },
  },
};
</script>

<style scoped lang="scss">
$navHeight: 56px;
$itemsCount: 5;

.header {
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: $navHeight;
  -webkit-tap-highlight-color: transparent;

  &.active {
    height: 100%;
    touch-action: none;
  }

  &-nav {
    width: 100%;
    height: $navHeight;
    background: rgba(255, 255, 255, 0.95);
    border-bottom: 0.5px solid #cfd8dc;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);

    &.active {
      height: $navHeight * $itemsCount + $navHeight;
      border-radius: 0 0 24px 24px;

      .header {
        &-menu {
          .menu-btn {
            &-before,
            &-after {
              transition: transform 200ms cubic-bezier(0.02, 0.01, 0.47, 1);
            }

            &-before {
              transform: translate3d(0, 6px, 0) rotate(-225deg) scale(1, 0.5);
            }

            &-after {
              transform: translate3d(0, -4px, 0) rotate(225deg) scale(1, 0.5);
            }
          }
        }
      }
    }
  }

  &-sp {
    position: relative;
    display: flex;
    height: $navHeight;
    justify-content: center;
    align-items: center;
    list-style: none;
  }

  &-link {
    text-align: center;
    font-size: 14px;
    height: 56px;
    min-width: 151px;

    &-a {
      position: relative;
      display: block;
      height: 56px;
      line-height: 56px;
      text-decoration: none;
      color: #222;
      cursor: pointer;

      &:hover {
        opacity: 1;

        &::after {
          opacity: 1;
        }
      }

      &::after {
        width: 100%;
        margin: auto 0;
        height: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        opacity: 0;
        z-index: 1;
        content: '';
        background: #222;
        transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &-logo {
    $width: 80px;
    width: $width;
    overflow: hidden;
    transition: width 400ms cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 250ms cubic-bezier(0.165, 0.84, 0.44, 1);

    &-link {
      display: block;
      width: $width;
      transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 500ms;

      &:hover {
        opacity: 1;
      }
    }

    &-img {
      padding: 12px 24px;
      width: 80px;
      height: 56px;
    }

    &.hidden {
      width: 0;
      opacity: 0;

      .header-logo-link {
        opacity: 0;
        transform: translateX(-50%);
      }
    }
  }

  &-menu {
    @media screen and (min-width: 769px) {
      display: none;
    }
    width: 60px;
    height: $navHeight;
    display: block;
    position: absolute;
    right: calc(6.4vw - 25px);
    top: 0;

    .menu-btn {
      &-before {
        top: 22px;
      }

      &-after {
        bottom: 22px;
      }

      &-before,
      &-after {
        left: 50%;
        margin-left: -12px;
        width: 24px;
        height: 2px;
        border-radius: 1px;
        position: absolute;
        background: #222;
        transform: translate3d(0, 0, 0) rotate(0deg) scale(1, 0.5);
        transition: transform 400ms cubic-bezier(0.02, 0.01, 0.47, 1);
        pointer-events: none;
      }
    }

    &-btn {
      width: 60px;
      height: 56px;
      display: block;
      cursor: pointer;
    }
  }

  &-list {
    height: 0;
    transition: height 400ms cubic-bezier(0.165, 0.84, 0.44, 1);

    &.active {
      height: $navHeight * $itemsCount;

      .arrow-list-item > a {
        opacity: 1;
      }
    }

    .arrow-list-item {
      & > a {
        line-height: $navHeight;
        height: $navHeight;
        opacity: 0;
        transition: opacity 600ms ease-out;
      }

      $baseDelay: 100ms;
      @for $i from 1 through $itemsCount {
        &:nth-child(#{$i}) a {
          transition-delay: ($baseDelay + 40 * ($i - 1));
        }
      }
    }
  }
}

.is-iOS {
  .header-link-a {
    &:hover {
      &::after {
        opacity: 0;
      }
    }
  }
}
</style>
