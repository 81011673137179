<template>
  <div class="ordered-list">
    <span class="ordered-list-title" v-if="title">{{ title }}</span>
    <ol class="ordered-list-content">
      <li class="ordered-list-item" v-for="(item, index) in list || []" :key="index">
        <template v-if="typeof item === 'string'"> {{ index + 1 }}. {{ item }} </template>
        <template v-else>
          <p class='ordered-list-subtitle'>{{ item.listStyle || `${index + 1}.` }} {{ item.subtitle }}</p>
          <OrderedList :list="item.list" v-if='item.list' />
        </template>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'OrderedList',
  props: {
    title: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.ordered-list {
  &-title {
    display: inline-block;
    margin: 20px 0 24px;
    font-size: 14px;
    color: #a7aeb1;
    font-weight: bold;
  }

  &-subtitle {
    margin-bottom: 10px;
  }

  &-content {
    margin-bottom: 28px;
    color: #222;
    font-size: 14px;
    line-height: 24px;
    list-style-type: none;
  }

  &-item {
    margin-bottom: 10px;
    padding: 0 0 0 18px;
    text-indent: -18px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
