/* eslint-disable max-len */
export default {
  title: 'プライバシーポリシー',
  read: '本ポリシーは、成都市飛狼科技有限公司（以下、「当社」といいます。）が、個人情報をどのように収集し、使用するかが記載されています。本ポリシーは当社コーポレートサイト、当社が運営する関連ホームページ、サービス、あるいは直接または間接的にご連絡をいただく全ての利用者様に適用されます。但し、関連ホームページやサービスにおいてプライバシーポリシーが別途定められている場合は、この限りではありません。\n'
      + '他社サービス経由で当社にご連絡をいただいた場合は、受領した情報につきましては本ポリシーにしたがって管理させていただきますが、他社サービスのご利用に関しては別途利用者様と他社との間の取り決めが適用されます。',
  list: [
    {
      id: 'list_1',
      title: '個人情報等の収集・利用目的・利用根拠',
      desc: '当社は、利用者様より以下の情報（以下、「個人情報等」といいます。）をご提供いただきます。ご提供いただいた利用者様の個人情報等は、ご提供いただく際に利用者様にお知らせした利用目的、または以下に示す利用目的の範囲内でのみ利用し、事前の同意なくこれ以外の目的には利用いたしません。なお、個人情報等は、利用する必要がなくなったときに、速やかに消去されます。',
    },
    {
      id: 'list_2',
      title: '利用者様の権利',
      desc: '利用者様には、以下の権利があります。これらの権利行使及びご意見、ご質問、苦情のお申出その他利用者様情報の取扱いに関するお問い合わせは、以下の<a href="/ja/contact-us"  style="color: #303233">お問い合わせフォーム</a>からお願いいたします。',
    },
    {
      id: 'list_3',
      title: '個人情報等の適切な管理・保護',
      desc: '当社は、利用者様の個人情報等を法律に基づき適切に管理・保護し、不適切な消失・盗難・不正使用・漏えい・毀損・改ざんを防止する対策、情報セキュリティ管理体制を導入しております。',
    },
    {
      id: 'list_4',
      title: '第三者への提供・開示',
      desc: '当社は、次の場合を除き、利用者様の個人情報を第三者に提供または開示しません。',
    },
    {
      id: 'list_5',
      title: '第三者に関する免責事項',
      desc: '当社は、以下の場合において第三者による個人情報の取得に関しての責任を負いません。',
    },
    {
      title: '委託先の管理',
      desc: '当社は、利用目的の実施に必要な範囲で、個人情報等の取扱いを外部に委託することがあります。その場合は、個人情報等を適切に取り扱うと認める委託先を選定します。また、委託先に対し、利用目的の実施に必要な範囲に限定して利用者様の個人情報等を提供または開示するとともに、委託先と機密保持契約を結んだ上で個人情報等の適切な取扱いを求め、その状況について定期的に確認・見直しを行います。',
    },
    {
      id: 'list_7',
      title: '当社の連絡先',
      desc: '個人情報の使用方法についてご質問やご不明な点がございましたら、お気軽にお問い合わせください。連絡先は次のとおりです 。',
    },
    {
      title: '準拠法',
      desc: '本ポリシーは、中華人民共和国の法律に従って解釈・適用されるものとします。',
    },
    {
      title: '個人情報等の取り扱いの見直しおよび改善',
      desc: '当社は、個人情報等の取扱いに関して適宜取扱いの評価を行い、継続的改善に取り組んでいます。その為、本ポリシーを変更する場合があります。大幅な変更がある場合は、利用者様に分かりやすい方法で当該内容を告知いたします。',
    },
    {
      title: '情報保留の期限',
      desc: '当社は、関係のない個人情報等が収集されないように、合理的かつ実行可能なあらゆる措置を講じます。当社は、本ポリシーに記載された目的を達成するために必要な期間のみ、利用者様の個人情報等を保持します（商品またはサービスの提供のために保持期間を延長する必要がある場合、または法律で許可されている場合を除く）。利用者様の個人情報等が法定または合意された保存期間を超えた場合、当社は法律に従ってそれを削除または匿名化します。',
    },
    {
      title: '個人情報等の国境を越えた転送',
      desc: '当社が利用者様から提供された個人情報等を日本のフェンリル株式会社およびその他の海外の当社グループ企業に提供する場合、当社は、利用者様に対する当社のサービス品質を向上させるために、当該個人情報等の国境を越えた送信の過程で、関連する法規制の要件を遵守し、国境を越えた送信の前に、法律に従ってデータのアウトバウンドセキュリティ評価を実行し、可能な限り個人情報等のセキュリティを保護するための匿名化を行います。',
    },
  ],
  subList: {
    list_1: [
      {
        title: '収集する情報',
        desc: '以下のような情報を収集いたします。収集する情報の詳細は、その一例を示します。',
        content: [
          {
            title: 'アクセス情報',
            content: [
              { title: 'IPアドレス' },
              { title: 'デバイス情報（機器及びアプリケーションの識別番号、インターネットサービスプロバイダー、モバイルキャリア）' },
              { title: 'ブラウザ情報' },
              { title: 'WEB閲覧状況（アクセス先のURL、検索情報）、メールのクリック状況' },
              { title: 'オペレーティングシステム、システム設定情報' },
              { title: 'タイムスタンプ情報' },
              { title: 'Cookie情報' },
            ],
          },
          {
            title: '案件のご依頼・各種お問い合わせ',
            content: [
              { title: 'お問い合わせ内容' },
              { title: '会社名・部署名' },
              { title: '住所' },
              { title: '氏名' },
              { title: '連絡先（メールアドレス・電話番号）' },
            ],
          },
          {
            title: '採用関連情報',
            content: [
              { title: '個人の属性情報（住所・氏名・生年月日等）' },
              { title: '学歴・職務経歴等' },
            ],
          },
        ],
      },
      {
        title: '収集した情報の利用目的',
        content: [
          {
            title: 'プロモーション・営業活動',
            content: [
              { title: '当社提携サービスのご利用手続き' },
              { title: '当社製品、サービス、セミナーのご案内' },
              { title: '商品、景品の発送・提供' },
              { title: 'アンケート等への調査協力依頼' },
              { title: 'ご提供いただいたアンケート等の内容の調査・分析' },
              { title: 'サービスのご利用状況の調査・分析' },
            ],
          },
          {
            title: 'お問い合わせ対応',
            content: [{ title: '各種お問い合わせ対応' }],
          },
          {
            title: '採用活動',
            content: [
              { title: '採用応募者への採用情報等の提供・連絡' },
              { title: '当社における採用業務管理' },
            ],
          },
          {
            title: '法令上の手続',
            content: [
              { title: '当社又は利用者による当社のサービス利用に関する請求、紛争についての調査・解決。' },
              { title: '法令上認められる場合及び国の機関又は地方公共団体への提供。' },
            ],
          },
        ],
      },
      {
        title: '収集した情報の利用根拠',
        desc: '当社は、以下のうち、１つ以上の根拠に基づいて個人情報等を利用します。',
        content: [
          { title: '同意', content: [{ title: '当社は、利用者様の同意に基づき、利用者様の個人情報等を収集します。利用者様は、いつでも上記同意を撤回する権利を有します。' }] },
          { title: '利用者様の要求への対応', content: [{ title: '当社は、契約の履行のために取扱が必要となる場合、契約締結前の利用者様の要求に対応して手続を履践するために取扱が必要となる場合、利用者様の個人情報等を収集します。利用者様の要求への対応には、利用者様からのお問い合わせへの対応や採用活動を含みます。' }] },
          { title: '法令上の義務の遵守', content: [{ title: '当社は、当社が従うべき法的義務を遵守するために、利用者様の個人情報等を収集します。' }] },
          { title: '利用者様又は第三者の重要な利益の保護', content: [{ title: '当社は、利用者様又は第三者の重要な利益を保護するために、利用者様の個人情報等を収集します。利用者様の重要な利益には、利用者様の再入力の手間の省略、利用者様にとっての最適なコンテンツの表示、利用者様の関心に応じた広告の表示を含みます。' }] },
          { title: '公共の利益の保護', content: [{ title: '当社は、公共の利益を保護するために必要な範囲において、利用者様の個人情報等を収集します。' }] },
          { title: '当社又は第三者の正当な利益の追求', content: [{ title: '当社は、当社又は第三者の正当な利益の追求のため、利用者様の個人情報等を収集します。当社の正当な利益には、当社のサービスに関する不正行為を防止及び検出しこれに対応する場合、カスタマーサポートを提供する場合、当社のサービス向上又はセキュリティ向上のために調査・分析を行う場合、ダイレクトマーケティングを行う場合及び採用活動を行う場合を含みます。' }] },
        ],
      },
      {
        title: 'Cookie等の利用について',
        desc: '当社は、当社が運営するホームページ及び関連ホームページにおいて、Cookieを利用することがあります。Cookieはインターネットブラウザを介してコンピュータシステムに保存されるテキストファイルです。Cookieを利用すると、ウェブページを利用したときにブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、利用者様のコンピューターに保存しておくことができます。Cookieを使用することにより、当社のウェブサイト上の情報及び広告を最適化することができます。\n'
            + '利用者様は、使用するインターネットブラウザにおける設定によって、当社のウェブサイトを通じてCookieの設定を無効にすることが可能です。',
      },
      {
        title: '解析ツールの利用について',
        desc: '当社のウェブサイトでは、マーケティング活動のため、第三者企業のサービスを導入しております。詳細については、以下の第三者企業のプライバシーポリシーをご確認ください。',
        content:
          [{ content: [{ title: '百度統計\n<a href="https://tongji.baidu.com/" target="_black" style="color: #161616">https://tongji.baidu.com/</a>' }], padding: true, dot: true }],
      },
      {
        title: 'reCAPTCHAの利用について',
        desc: '当社のウェブサイトでは、セキュリティ目的のため「reCAPTCHA」を使用しています。\n'
            + 'このreCAPTCHAには、Googleのプライバシーポリシーと利用規約が適用されます。\n'
            + 'reCAPTCHA APIは、デバイスやアプリケーションのデータなどハードウェアおよびソフトウェアの情報（デバイスやアプリのデータなど）を収集し、それらのデータをGoogleに送信して分析することによって、ロボットによる悪質な行為かどうかを判断します。本サービスの利用に関連して収集された情報は、reCAPTCHAの向上および一般的なセキュリティ目的のために使用され、Googleによる広告には使用されません。',
        content:
          [
            { content: [{ title: 'Google プライバシーポリシー\n<div><a href="https://policies.google.com/privacy?hl=ja" target="_black" style="color: #161616">https://policies.google.com/privacy?hl=ja</a></div>\n' }], padding: true, dot: true },
            { content: [{ title: 'Google 利用規約\n<a href="https://policies.google.com/terms?hl=ja" target="_black" style="color: #161616">https://policies.google.com/terms?hl=ja</a>' }], padding: true, dot: true },
          ],
      },
    ],
    list_2: [
      { title: '情報権・アクセス権', desc: '利用者様は、当社が保有する個人情報等に関する説明及び当該情報の使用方法に関する説明を求める権利を有します。また、当社は、利用者様から処理が行われている個人情報等へのアクセスの請求があればその写しを提供いたします。' },
      { title: '訂正権', desc: '利用者様は、利用者様の不正確な個人情報等を訂正し、当該情報等の処理目的を考慮した上で当該情報等を完全なものとさせる権利を有します。' },
      { title: '削除権', desc: '利用者様は、一定の場合、利用者様に関する個人情報等の削除を求める権利を有します。' },
      { title: '処理制限権', desc: '利用者様は、一定の場合、利用者様に関する個人情報等の処理を制限させる権利を有します。' },
      { title: 'ポータビリティ権', desc: '利用者様は、同意又は利用者様の要求に対応するために取得された個人情報等につき、構造化され、一般的に使用され、機械によって読み取り可能な形式で受け取る権利、又は利用者様の個人情報等の提供を受けた管理者から妨げられることなく、当該個人情報等を当社から他の管理者に移行する権利を有します。具体的には、利用者様が当社が提供するサービスとは異なる他社提供サービスに移行される場合、利用者様が当社に提供した個人データ群に関して、ＣＳＶなどのテキストファイルデータや、ＪＳＯＮなどのデータ記述言語のレベルで、再利用可能な個人データの提供を受ける権利を有します。' },
      { title: '異議権', desc: '利用者様は、公共の利益の保護、又は当社若しくは第三者の正当な利益の追求のために取得された個人情報等の処理に対して（プロファイリングを含む）、異議を唱える権利を有します。' },
      { title: 'プロファイリングを含む自動化された意思決定を受けない権利', desc: '利用者様は、①利用者様に関する法的効果を発生させる、または、②利用者様に対して同様の重大な影響を及ぼす、プロファイリングを含む完全に自動化された決定の対象とされない権利を有します。なお、現時点で、当社は、広告の内容・配信方法、利用者様に与える影響等に鑑みて、侵襲度が高いターゲティング広告等は一切行っておらず、利用者様の個人情報等に関して、①法的効果を発生させる、または、②同様の重大な影響を及ぼす、プロファイリングを含む完全に自動化された決定を行っておりません。' },
      { title: '同意の撤回権', desc: '利用者様は、同意を根拠として取得された個人情報等につき、自己の同意をいつでも撤回する権利を有します。但し、当該同意の撤回前に当該同意に基づいて行われた処理の適法性には影響はありません。' },
    ],
    list_4: [
      { title: '利用者様ご本人から個人情報等の開示について同意をいただいている場合' },
      { title: '利用目的の達成に必要な範囲で業務委託先等に提供または開示する場合' },
      {
        title: '利用目的の達成に必要な範囲で当社グループ企業間において共同利用する場合\n当社は、グループ企業間の円滑な業務遂行を図るため、当社の定める利用目的の範囲において、利用者様の個人情報を、当社グループ企業間で、共同利用する場合があります。その場合、個人情報の安全管理については、当社が責任を負います。',
        content: [
          {
            title: '(a)共同利用する可能性がある個人情報の項目',
            content: [
              { title: '①利用者様の住所、氏名、生年月日等\n②電話番号、メールアドレス\n③IPアドレス、デバイス情報、ブラウザ情報、WEB閲覧情報、Cookie情報' },
            ],
          },
          {
            title: '(b)共同利用者の範囲',
            content: [{ title: '日本のフェンリル株式会社及びその国内外の当社グループ企業（飛狼数碼（上海）有限公司、大連飛狼科技有限公司、その他のフェンリル株式会社が直接的または間接的に株式の50％以上を保有している会社が含まれます。）' }],
          },
          {
            title: '(c)共同利用者の利用目的',
            content: [{ title: '第１条に規定する個人情報の利用目的と同一する。' }],
          },
          {
            title: '(d)当該個人データの管理について責任を有する者の名称',
            content: [{ title: '成都市飛狼科技有限公司' }],
          },
        ],
      },
      { title: '法令により、開示または提供が許容されている場合' },
      { title: '買収、合併、会社の清算、会社分割または営業譲渡等の事業承継に伴い個人情報を開示または提供する場合' },
    ],
    list_5: [
      { title: '利用者様が自らの判断で第三者に個人情報を明らかにされた場合' },
      { title: '利用者様からの問い合わせに際し、お伝えいただいた内容から、期せずして本人が特定できてしまった場合' },
      { title: '当社サービスからリンクされる外部サイトにおいて、利用者様より個人情報が提供され、またそれが利用された場合' },
      { title: '利用者様が利用されたネットワークの脆弱性により、利用者様の責で個人を識別できる情報が漏洩してしまった場合' },
    ],
    list_7: [
      { title: '住　　所：中国四川省成都市高新区天府軟件園D6棟15階' },
      { title: '電話番号：028-85177409' },
      { title: 'お問い合わせフォーム：<a href="/ja/contact-us" style="color: #303233">https://fenrir-inc.com.cn/ja/contact-us</a>' },
    ],
  },
  date: '2024年8月20日改訂',
};
