<template>
  <div class="div">
    <img :src="img.src" :alt="img.alt" class="image" />
  </div>
</template>

<script>
export default {
  name: 'part-type-img',
  data() {
    return {};
  },
  props: ['img'],
};
</script>

<style lang="scss" scoped>
.div {
  width: 100%;
  text-align: center;
}
.image {
  width: 100%;
}
</style>
