<template>
  <div class="contain">
    <div class="header-section">
      <HeaderPC v-if="!isSP" :work="work"/>
      <HeaderSP v-else :work="work"/>
    </div>
    <NetworkSecurityBodyPC :work="work"/>
  </div>
</template>

<script>
import useIsSP from '@/composables/useIsSP';
import HeaderPC from '@/components/NetworkSecurity/HeaderPC.vue';
import HeaderSP from '@/components/NetworkSecurity/HeaderSP.vue';
import NetworkSecurityBodyPC from '@/components/NetworkSecurity/network-security-body/index.vue';

export default {
  components: {
    HeaderPC,
    HeaderSP,
    NetworkSecurityBodyPC,
  },
  props: {
    work: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { isSP } = useIsSP();
    return {
      isSP,
    };
  },
};
</script>

<style lang="scss" scoped>
$height-header: 56px;
  .contain{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header-section{
    width: 100%;
    padding: $height-header 0 0;
  }
</style>
