<template>
  <section class="news">
    <h2 v-viewportChanged="showView" class="news-title hidden-element">
      {{ pr.title }}
    </h2>
    <NewsContent :list="filterList" @onLength="handleLength" />
    <div class="more">
      <router-link
        :to="{ name: 'news', params: { lang: $route.params.lang } }"
        v-slot="{ href, navigate }"
        custom
      >
        <LinkBtn v-if="length > 5" :href="href" @click="navigate" class="more-link">
          {{ pr.more }}
        </LinkBtn>
      </router-link>
    </div>
  </section>
</template>

<script>
import usePageResource from '@/composables/usePageResource';
import NewsContent from '@/components/news/NewsContent.vue';
import LinkBtn from '@/components/common/LinkBtn.vue';

export default {
  name: 'news',
  data() {
    return {
      length: 0,
    };
  },
  setup() {
    const { pr } = usePageResource('news');
    return {
      pr,
    };
  },
  computed: {
    filterList() {
      return this.pr.list.filter((item) => !item.hidden);
    },
  },
  methods: {
    handleLength(length) {
      this.length = length;
    },
  },
  components: {
    LinkBtn,
    NewsContent,
  },
};
</script>

<style scoped lang="scss">
$breakpoints-md: 768px;
.news {
  background-color: #f7f9fa;
  padding: 80px 6.4vw 100px;
  @media only screen and (max-width: 768px) {
    padding: 40px 6.4vw 70px;
  }

  &-title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 50px;
    @media only screen and (max-width: 768px) {
      font-size: 22px;
      margin-bottom: 28px;
    }
  }
}

.more {
  width: 100%;
  text-align: center;
  margin-top: 40px;

  &-link {
    padding: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    max-width: 360px;
    font-weight: bold;
  }

  @media only screen and (max-width: $breakpoints-md) {
    margin-top: 30px;
    .more-link {
      max-width: 250px;
    }
  }
}
</style>
