<template>
  <li v-viewportChanged="showView" class="hidden-element recruit-job">
    <div :class="['recruit-job-header', { disabled: !isZH }]" @click="toggle">
      <span class="recruit-job-name">{{ job.name || '' }}</span>
      <img v-if="isZH"
        :class="['recruit-job-header-arrow', { open: isOpen }]"
        src="@/assets/Arrow_Open.svg"
      >
    </div>
    <div :class="['recruit-job-content', { active: isOpen }]" ref="content">
      <OrderedList v-if="job.responsibilities"
        :title="pr.responsibilitiesTitle"
        :list="job.responsibilities"
      />
      <OrderedList v-if="job.requirements" :title="pr.requirementsTitle" :list="job.requirements"/>
      <div v-if="job.benefits" class="benefits-list">
        <span class="benefits-list-title">{{ pr.benefitsTitle }}</span>
        <ul class="benefits-list-content">
          <li class="benefits-list-item" v-for="(item, index) in (job.benefits || [])" :key="index">
            【{{ item.title || '' }}】{{ item.description || '' }}
          </li>
        </ul>
      </div>
      <div :class="['recruit-job-links', { center: !chengduJobLink || !dalianJobLink }]">
        <a v-if="chengduJobLink && chengduJobLink !== ''" :href="chengduJobLink" target="_blank"
        class="recruit-job-links-item with-hover">{{ pr.recruitJobCD }}</a>
        <a v-if="dalianJobLink && dalianJobLink !== ''" :href="dalianJobLink" target="_blank"
        class="recruit-job-links-item with-hover">{{ pr.recruitJobDL }}</a>
        <a v-if="shanghaiJobLink && shanghaiJobLink !== ''" :href="shanghaiJobLink" target="_blank"
        class="recruit-job-links-item with-hover">{{ pr.recruitJobSH }}</a>
      </div>
    </div>
  </li>
</template>

<script>
import { inject } from 'vue';
import usePageResource from '@/composables/usePageResource';
import OrderedList from '@/components/common/OrderedList.vue';
import { LOCALES } from '@/constants/constants';

export default {
  name: 'recruit-job',
  components: { OrderedList },
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { pr } = usePageResource('recruit');
    const locale = inject('locale');
    return {
      pr,
      locale,
    };
  },
  data() {
    return {
      isOpen: false,
      isMobile: false,
    };
  },
  computed: {
    chengduJobLink() {
      return this.isMobile ? this.job.cdLinkM : this.job.cdLink;
    },
    dalianJobLink() {
      return this.isMobile ? this.job.dlLinkM : this.job.dlLink;
    },
    shanghaiJobLink() {
      return this.isMobile ? this.job.shLinkM : this.job.shLink;
    },
    isZH() {
      return this.locale === LOCALES.ZH;
    },
  },
  watch: {
    isOpen() {
      $(this.$refs.content)
        .stop()
        .animate({ height: 'toggle', opacity: 'toggle' });
    },
    locale() {
      this.isOpen = false;
    },
  },
  created() {
    if (/mobi|iphone|android/.test(navigator.userAgent.toLowerCase())) {
      this.isMobile = true;
    }
  },
  methods: {
    toggle() {
      if (!this.isZH) { return; }
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.recruit-job {
  border-bottom: 1px solid #cfd8dc;

  &-header {
    display: flex;
    height: 77px;
    align-items: center;
    justify-content: space-between;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    &.disabled {
      cursor: auto;
    }

    &-arrow {
      transition: transform 0.2s linear;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  &-content {
    display: none;
  }

  &-name {
    flex-grow: 1;
    font-size: 16px;
    color: #222;
    font-weight: bold;
  }

  .benefits-list {
    &-title {
      display: inline-block;
      margin: 20px 0 24px;
      font-size: 14px;
      color: #a7aeb1;
      font-weight: bold;
    }

    &-content {
      margin-bottom: 48px;
      color: #222;
      font-size: 14px;
      line-height: 24px;
      list-style-type: none;
    }

    &-item {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px auto 54px;
    max-width: 311px;

    &.center {
      justify-content: center;
    }

    &-item {
      $fontSize: 14px;
      $padding: 12px;

      box-sizing: border-box;
      padding-left: $padding;
      max-width: 146px;
      width: 49%;
      height: 42px;
      line-height: 42px;
      border: 1px solid #A6AEB1;
      border-radius: 4px;
      color: #222;
      font-size: $fontSize;
      text-decoration: none;
      background: url("~@/assets/Button_Link.png") no-repeat right $padding
        center / $fontSize;
      font-weight: 400;

      &:hover {
        opacity: 1;
        background: url("~@/assets/Button_Link_hover.png") no-repeat right $padding
        center / $fontSize;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .recruit-job {
    &-header {
      height: 60px;
    }
  }
}
</style>
