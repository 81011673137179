export default {
  title: 'サービス',
  titleStyle: 'line-height: 22px;',
  descriptionsPC: [
    ['「最新技術を活用し顧客体験を向上したい」「開発実績が豊富な会社に依頼したい」', 'という課題などでお困りではないですか？'],
    ['最新技術をいち早く取り入れた開発と、豊富な開発実績で培った専門知識やノウハウから、', '最適な方法で課題を解決します。'],
  ],
  descriptionsMobile: [
    '「最新技術を活用し顧客体験を向上したい」',
    '「開発実績が豊富な会社に依頼したい」',
    'という課題などでお困りではないですか？',
    '最新技術をいち早く取り入れた開発と、',
    '豊富な開発実績で培った',
    '専門知識やノウハウから、',
    '最適な方法で課題を解決します。',
  ],
  learnMorePC: [
    ['ここに掲載しているサービスは一例です。', 'ITビジネスに関するお困りごとやその他サービスについて、'],
    ['お気軽にご相談・お問い合わせください。'],
  ],
  learnMoreMobile: 'ここに掲載しているサービスは一例です。\nITビジネスに関するお困りごとや\nその他サービスについて、\nお気軽にご相談・お問い合わせください。',
  services: [
    {
      id: 'services_app-advisory',
      icon: 'icn_consulting.png',
      name: 'モバイル',
      service: 'コンサルティング',
      description: 'モバイルデバイスを活用した業務改革の推進や、サイバーセキュリティ法等中国法令順守対応を支援します。',
    },
    {
      id: 'services_ui',
      icon: 'icn_design.png',
      name: 'UX/UI',
      service: 'デザイン',
      description: 'ユーザーに合わせて情報を適切に整理し、ユーザーの文化や価値感に合った優れたデザインを提供します。',
    },
    {
      id: 'services_web',
      icon: 'icn_VR.png',
      name: '新技術を活用した',
      service: '企画・開発・運用支援',
      description: '生成AI、IoT、NFTなどの新技術を活用し、新たなユーザー体験を一緒に創りましょう。',
    },
    {
      id: 'services_app',
      icon: 'icn_App.png',
      name: 'モバイルアプリ/ウェブアプリの',
      service: '開発',
      description: '最先端のモバイルプラットフォームを熟知した豊富な開発経験を基に、品質の高い開発サービスを提供します。',
    },
    {
      id: 'services_mini-program',
      icon: 'icn_miniprogram.png',
      name: 'ミニプログラム/ミニアプリの\n',
      service: '開発・運用支援',
      description: 'WeChat（微信）やAlipay（支付宝）などのミニプログラム開発を通じて、ニーズにあったサービスを提供します。',
    },
    {
      id: 'services_office-automation',
      icon: 'icn_tool.png',
      name: 'オフィスオートメーションツールの',
      service: '導入・個別開発',
      description: 'WeCom（企業微信）や DingTalk（釘釘）の導入で社内業務効率を向上、社内システム連携や機器連携も可能です。',
    },
  ],
};
