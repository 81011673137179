<template>
  <div class="recruit">
    <div v-viewportChanged="showView" class="hidden-element recruit-bg">
      <div class="recruit-bg-color"></div>
    </div>
    <TopView/>
    <Content/>
  </div>
</template>

<script>
import TopView from './TopView.vue';
import Content from './Content.vue';

export default {
  name: 'recruit',
  components: {
    TopView,
    Content,
  },
};
</script>

<style scoped lang="scss">
.recruit {
  position: relative;
  overflow: hidden;

  &-bg {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #58575C url("~@/assets/Background_Recruit.png") no-repeat;
    background-size: 100% auto;
    background-position: center 0;

    &-color {
      width: 100%;
      height: 100%;
      background-color: rgba($color: #212121, $alpha: 0.6);
    }

    @media only screen and (max-width: 768px) {
      background-size: auto 720px;
      background-position: -450px -60px;
    }
  }
}
</style>
