import usePageResource from '@/composables/usePageResource';

const useRenderData = (key) => {
  const { pr } = usePageResource(key);
  const getImageUrl = (imgName) => {
    if (imgName) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/${imgName}`);
    }
    return '';
  };
  return {
    pr,
    getImageUrl,
  };
};

export default useRenderData;
