<template>
  <div class="mask" @click="handleMaskClick">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    maskClickEvent: Function,
  },
  methods: {
    handleMaskClick() {
      if (this.maskClickEvent) {
        this.maskClickEvent();
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    z-index: 9999;
  }
</style>
