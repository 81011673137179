<template>
  <table :class="{ horizontal: isCol, vertical: !isCol, 'col-head-bold': colHeadBold }">
    <thead v-if="isCol">
      <tr>
        <th v-for="(item, i) in columns" :key="i" v-html="item.title" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="(tr, i) in data" :key="i" :class="{ trc: i === data.length - 1 }">
        <template v-if="isCol">
          <td v-for="(col, j) in columns" :key="j" v-html="tr[col.prop]" />
        </template>
        <template v-else>
          <td v-for="(value, name) in tr" :key="name" v-html="value" />
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'part-type-table',
  data() {
    return {};
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    colHeadBold: Boolean,
  },
  computed: {
    isCol() {
      return !!this.columns.length;
    },
  },
};
</script>

<style scoped lang="scss">
$border-default-style: 1px solid #aaa;
$md: 767px;

table {
  width: 100%;
  word-break: break-word;

  th,
  td {
    padding: 20px;
    text-align: left;
    white-space: pre-line;
    vertical-align: middle;
  }

  tr td:first-child {
    white-space: nowrap;
  }

  ::v-deep(ul),
  ::v-deep(ol) {
    margin-left: 20px;
    white-space: pre-line;
  }

  &.horizontal {
    border: {
      top: $border-default-style;
      left: $border-default-style;
    }

    thead {
      background-color: #eee;
    }

    td,
    th {
      border: {
        right: $border-default-style;
        bottom: $border-default-style;
      }
    }
  }

  &.vertical {
    tr {
      &:not(.trc) {
        border: {
          bottom: $border-default-style;
          color: #fff;
        }
      }

      & td:last-child {
        border-right: none;
      }
    }

    td {
      background-color: #eceff1;
      border: {
        right: $border-default-style;
        color: #fff;
      }
    }

    @media only screen and (max-width: $md) {
      tr td:first-child {
        border-right: none;
        padding-bottom: 0;
      }

      td {
        display: block;
        width: 100%;
      }
    }
  }

  &.col-head-bold {
    & tr td:first-child {
      font-weight: bold;
    }
  }
}
</style>
