<template>
  <div class="contact">
    <ContactForm />
    <Popup
      :title="popupTitle"
      :cancelText="popupCancelText"
      :confirmText="popupConfirmText"
      ref="popup" />
  </div>
</template>

<script>
import usePageResource from '@/composables/usePageResource';
import ContactForm from '@/components/contact-form/ContactForm.vue';
import Popup from '@/components/popup/Popup.vue';

export default {
  components: {
    ContactForm,
    Popup,
  },
  setup() {
    const { pr } = usePageResource('contact-us');
    return {
      pr,
    };
  },
  mounted() {
    this.setBodyStyle('contain');
  },
  computed: {
    popupTitle() {
      return this.pr.popupTip.title;
    },
    popupCancelText() {
      return this.pr.popupTip.cancelBtn;
    },
    popupConfirmText() {
      return this.pr.popupTip.confirmBtn;
    },
  },
  methods: {
    setBodyStyle(style) {
      $(document.body).css({ 'overscroll-behavior-y': style });
    },
    getInLocalStorage(key) {
      return localStorage.getItem(key);
    },
    isFormInput() {
      const name = this.getInLocalStorage(this.pr.form.formList[0].id);
      const email = this.getInLocalStorage(this.pr.form.formList[1].id);
      const tel = this.getInLocalStorage(this.pr.form.formList[2].id);
      const company = this.getInLocalStorage(this.pr.form.formList[3].id);
      const department = this.getInLocalStorage(this.pr.form.formList[4].id);
      const comment = this.getInLocalStorage(this.pr.form.formList[5].id);
      const flag = [name, email, tel, company, department, comment].every((item) => (
        item === null || item === ''
      ));

      return flag;
    },
    hideKeyboard() {
      document.activeElement.blur();
      $('input').blur();
      $('textarea').blur();
    },
  },
  unmounted() {
    this.setBodyStyle('auto');
  },
  beforeRouteLeave(to, from, next) {
    this.hideKeyboard();
    if (to.name === 'thanks-contact' || this.isFormInput()) {
      next();
    }
    this.$refs.popup.open(next);
  },
};
</script>

<style scoped lang="scss">
.contact {
  min-width: 300px;
  width: 100%;
  padding-top: 56px;
  display: flex;
  justify-content: center;
  font-weight: 400;
  color: #222222;
}
</style>
