<template>
  <div class="works-wrap">
    <div class="works-content">
      <div :class="['works-header', { ja: !isZH}]">
        <span v-viewportChanged="showView" class="hidden-element title">{{ pr.title }}</span>
        <break-text
          v-viewportChanged="showView"
          class="hidden-element description"
          :content="pr.description1"
        />
        <break-text
          v-viewportChanged="showView"
          class="hidden-element description"
          :content="pr.description2"
        />
      </div>
      <works-swipe class="sp" :works="showWorksPage" :detailLabel="pr.detailLabel"></works-swipe>
      <div class="works-slides pc">
        <template v-for="work in showWorksPage" :key="work.id">
          <div class="work-card-wrap">
            <work-card :work="work" :detailLabel="pr.detailLabel"></work-card>
          </div>
        </template>
      </div>
      <works-button
        containerClass="works-button"
      />
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import usePageResource from '@/composables/usePageResource';
import WorkCard from '@/components/works/WorkCard.vue';
import WorksSwipe from '@/components/works/WorksSwipe.vue';
import WorksButton from '@/components/works-button/WorksButton.vue';
import BreakText from '@/components/common/BreakText.vue';
import { LOCALES } from '@/constants/constants';
import useIsSP from '@/composables/useIsSP';

export default {
  components: {
    WorkCard,
    WorksSwipe,
    WorksButton,
    BreakText,
  },
  data() {
    return {};
  },
  setup() {
    // const isMobile = /mobi|tablet|ipad|iphone|android/.test(navigator.userAgent.toLowerCase());
    const { pr } = usePageResource('works');
    const locale = inject('locale');
    const { isSP } = useIsSP();
    return {
      // isMobile,
      pr,
      locale,
      isSP,
    };
  },
  computed: {
    isZH() {
      return this.locale === LOCALES.ZH;
    },
    showWorksPage() {
      let arr = this.pr.works;
      if (this.isSP) arr = [...arr.filter((work) => work?.isShowInHome?.sp ?? true)];
      if (!this.isSP) arr = [...arr.filter((work) => work?.isShowInHome?.pc ?? true)];
      return [...arr];
    },
  },
};
</script>

<style lang="scss" scoped>
.works-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px 24px 100px 24px;
  background: #FFFFFF;
  color: #222222;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

  .works-content {
    width: 100%;
    max-width: 1260px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .works-header {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        margin-bottom: 32px;
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
      }

      .description {
        padding: 0 15px;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.43px;

        @media only screen and (max-width: 374px) {
          padding: 0;
          margin: 0 -10px;
        }
      }
    }

    .works-slides {
      width: 100%;
      margin-bottom: 42px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .work-card-wrap {
        flex: 50%;
        box-sizing: border-box;
        padding: 22px 30px;
      }
    }

    .learn-more {
      margin-bottom: 42px;
      font-size: 16px;
      text-align: center;
      line-height: 22px;
    }
  }
}

.works-button {
  margin-top: 35px;
}

@media only screen and (max-width: 1138px) {
  .work-card-wrap {
    flex: 100% !important;
  }
}

.sp {
  display: none;
}

@media only screen and (max-width: 768px) {
  .sp {
    display: block;
  }

  .works-button {
    margin-top: 20px;
  }

  .works-wrap {
    padding: 56px 24px 70px;

    .works-content {
      .works-header {
        margin-bottom: 32px;

        .title {
          margin-bottom: 34px;
          font-size: 22px;
        }

        .description {
          margin: 0 -10px;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: -0.32px;
        }

        &.ja {
          margin-bottom: 40px;

          .title {
            line-height: 22px;
            margin-bottom: 30px;
          }
        }
      }

      .works-slides {
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;

        &.pc {
          display: none;
        }

        .work-card-wrap {
          flex: 100%;
          padding: 0;

          &:not(last-child) {
            margin-bottom: 24px;
          }
        }
      }

      .learn-more {
        margin-bottom: 32px;
        max-width: 236px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.text-line {
  display: inline-block;
  white-space: nowrap;
}
</style>
