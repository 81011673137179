<template>
  <div class="header-sp">
      <div class="header-sp-text">
        <p class="animation-text description-sp noWrap">{{ work.company }}</p>
        <break-text
          class="title-sp animation-text"
          :content="work.name"
        />
        <break-text
          class="description-sp animation-text"
          :content="work.description"
        />
      </div>
      <div class="header-sp-image">
        <img  style="width:100% ;" alt='' :src="getImageUrl(work.screenShot1)" />
      </div>
    </div>
</template>

<script>
import BreakText from '@/components/common/BreakText.vue';

export default {
  components: { BreakText },
  props: {
    work: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getImageUrl(imgName) {
      if (imgName) {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        return require(`@/assets/${imgName}`);
      }
      return '';
    },
  },
};
</script >

<style lang="scss" scoped>
  .header-sp{
    box-sizing: border-box;
    background: #F1F3F4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 24px 72px;
    .header-sp-text{
      box-sizing: border-box;
      padding-bottom: 33px;
      max-width: 454px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .header-sp-image{
      max-width: 454px;
      margin-left: 7.5px;
    }
  }
  .title-sp {
    text-align: center;
    margin-bottom: 17px;
    font-size: 26px;
    line-height: 30px;
    font-weight: bold;
    color: #222222;
    letter-spacing: 0.85px;
  }
  .description-sp {
    text-align: center;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 22px;
    white-space: pre-line;
    color: #222222;
    letter-spacing: 0.43px;
    font-weight: 400;
  }
  .noWrap {
    white-space: nowrap;
  }
</style>
