<template>
  <div :class="isSP ? 'body-section-sp' : 'body-section-pc'">
    <SafetyLawBackground :work="work"/>
    <SecurityRisk :work="work"/>
    <SecurityService :work="work"/>
    <works-button
      containerClass="works-button"
      btnText="worksBtnTextInDetail"
    />
  </div>
</template>

<script>
import useIsSP from '@/composables/useIsSP';
import SafetyLawBackground from '@/components/NetworkSecurity/network-security-body/SafetyLawBackground.vue';
import SecurityService from '@/components/NetworkSecurity/network-security-body/SecurityService.vue';
import SecurityRisk from '@/components/NetworkSecurity/network-security-body/SecurityRisk.vue';
import WorksButton from '@/components/works-button/WorksButton.vue';

export default {
  components: {
    SafetyLawBackground,
    SecurityRisk,
    SecurityService,
    WorksButton,
  },
  props: {
    work: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { isSP } = useIsSP();
    return { isSP };
  },
};
</script>

<style lang="scss" scoped>
  .body-section-pc{
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 30px 160px;
  }
  .body-section-sp{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 100px;
  }
  .works-button {
    margin-top: 90px;
    @media only screen and(max-width: 768px) {
      margin-top: 60px;
    }
  }
</style>
