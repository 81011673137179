export default {
  title: '产品与服务',
  titleStyle: 'font-weight: bold;line-height: 30px;',
  descriptionsPC: [
    '“如何使用最新技术来提升用户体验？”“如何找一家开发经验丰富的公司？”',
    '您是否常常因此而烦恼？',
    '我们将会以最前沿的技术，最丰富的开发经验，最专业的设计知识， 为您提供最佳的解决方案。',
  ],
  descriptionsMobile: [
    '“如何使用最新技术来提升用户体验？”',
    '“如何找一家开发经验丰富的公司？”',
    '您是否常常因此而烦恼？',
    '我们将会以最前沿的技术，最丰富的开发经验，',
    '最专业的设计知识， 为您提供最佳的解决方案。',
  ],
  learnMorePC: ['此处记载的只是我们的部分服务。如果您有IT业务相关的困扰或者其他需求，请随时联系我们。'],
  learnMoreMobile: '此处记载的只是我们的部分服务。\n如果您有IT业务相关的困扰或者其他需求，\n请随时联系我们。',
  services: [{
    id: 'services_app-advisory',
    icon: 'icn_consulting.png',
    name: '移动端',
    service: '咨询服务',
    description: '助力各项移动端业务改革与项目推进，提供网络安全法等中国法规的对应服务。',
  },
  {
    id: 'services_ui',
    icon: 'icn_design.png',
    name: 'UX/UI',
    service: '设计',
    description: '整合用户的不同使用场景，提供契合用户的文化与价值观的卓越设计。',
  },
  {
    id: 'services_web',
    icon: 'icn_VR.png',
    name: '活用新技术',
    service: '企划开发与运维支持',
    description: '利用生成式AI、物联网、NFT等新技术，一起创造新的用户体验。',
  },
  {
    id: 'services_app',
    icon: 'icn_App.png',
    name: '移动端App/网页端App',
    service: '开发',
    description: '利用丰富的移动端开发经验，提供更高品质的开发服务。',
  },
  {
    id: 'services_mini-program',
    icon: 'icn_miniprogram.png',
    name: '小程序',
    service: '开发与运维支持',
    description: '通过微信和支付宝等小程序开发，最大程度满足您的需求。',
  },
  {
    id: 'services_office-automation',
    icon: 'icn_tool.png',
    name: '办公自动化工具',
    service: '导入与开发',
    description: '通过导入企业微信和钉钉，提高工作效率，打通公司内部系统协作，建立设备关联。',
  },
  ],
};
