<template>
  <div :id="`recruit_${dept.id}`" class="recruit-department">
    <span v-viewportChanged="showView" class="hidden-element recruit-department-name">
      {{ dept.name || '' }}
    </span>
    <ul class="recruit-department-jobs">
      <Job v-for="(obj, index) in (dept.jobs || [])" :key="index" :job="obj"/>
    </ul>
  </div>
</template>

<script>
import Job from './Job.vue';

export default {
  name: 'recruit-department',
  components: { Job },
  props: {
    dept: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.recruit-department {
  padding: 52px 40px 0;

  &-name {
    display: inline-block;
    margin-bottom: 4px;
    padding: 5px 10px;
    color: #fff;
    background-color: #333;
    font-size: 14px;
    border-radius: 2px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 1;
  }

  &-jobs {
    list-style: none;
  }

  @media only screen and (max-width: 768px) {
    padding: 40px 32px 0;

    &-name {
      margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: 359px) {
    padding: 40px 20px 0;
  }

}
</style>
