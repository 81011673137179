<template>
  <div class="part-type-done">
    <h2 v-if="doneContent.title">
      {{ doneContent.title }}
    </h2>
    <h2 v-else>{{ doneContent.beforeWrap }}<br />{{ doneContent.afterWrap }}</h2>
    <p class="content">
      {{ doneContent.beforeLink }}
      <a :href="doneContent.href" @click.prevent="handleClick">
        {{ doneContent.link }}
      </a>
      {{ doneContent.afterLink }}
    </p>
  </div>
</template>

<script>
import usePageResource from '@/composables/usePageResource';

export default {
  name: 'done',
  data() {
    return {};
  },
  setup() {
    const { pr } = usePageResource('news');
    return {
      pr,
    };
  },
  inject: ['moveTo'],
  computed: {
    doneContent() {
      return this.pr.doneContent;
    },
  },
  methods: {
    handleClick() {
      this.moveTo(this.doneContent.href);
    },
  },
};
</script>

<style scoped lang="scss">
$md: 767px;

.part-type-done {
  width: 100%;
  text-align: center;
  border: 1px solid #333;
  padding: 37px 20px;
  font-size: 16px;
  line-height: 155%;

  h2 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  br {
    display: none;
  }

  @media only screen and (max-width: $md) {
    font-size: 14px;
    padding: 30px 5%;

    h2 {
      font-size: 18px;
    }

    br {
      display: block;
    }

    p {
      text-align: left;
      margin: 0 5%;
    }
  }
}
</style>
