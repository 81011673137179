export default {
  title: '招贤纳才',
  subtitle: '飞狼敞开怀抱欢迎\n有激情、学习能力强、想获得更多成长的各位有志之士',
  description: [
    ['飞狼科技为员工提供宽松的工作环境，完善的培训制度，优厚的福利待遇，重视员工的个人成长和发展。'],
    ['飞狼科技热忱欢迎有抱负、勇于挑战、团队意识强的有志青年加入。'],
  ],
  descriptionMobile: '飞狼科技为员工提供宽松的工作环境，完善的培训制度，优厚的福利待遇，重视员工的个人成长和发展。\n飞狼科技热忱欢迎有抱负、勇于挑战、团队意识强的有志青年加入。',
  responsibilitiesTitle: '岗位职责',
  requirementsTitle: '岗位要求',
  benefitsTitle: '福利待遇',
  recruitJobCD: '成都 立刻申请',
  recruitJobDL: '大连 立刻申请',
  recruitJobSH: '上海 立刻申请',
  list: [
    {
      id: 'pmo',
      name: '事业部门',
      jobs: [
        {
          name: 'IT咨询销售经理',
          responsibilities: [
            {
              subtitle: '开拓客户，制定销售计划，完成销售目标',
              listStyle: '·',
              list: [
                '开拓新客户，包括开拓渠道、制定营业方式等，开拓新客户并获得新案件',
                '对现有客户的深耕营业（咨询营业）理解顾客的业务，及时发现课题,获得案件',
              ],
            },
            {
              subtitle: '提出解决方案',
              listStyle: '·',
              list: [
                '听取分析客户需求，结合客户公司目标和行业实践，负责为客户公司提供IT战略规划、数字化转型等方面的IT咨询服务',
                '分析行业趋势，通过多种方法了解客户现状和痛点，提出有针对性的解决方案和建议书',
                '能通过咨询方法对客户公司IT系统、数据、技术进行长远规划，并制定速赢方案和实施路径图',
                '了解移动应用、云、互联网等IT信息技术在行业的应用，并能通过科技结合业务应用进行规划和推动落地，实现科技驱动业务发展',
                '负责咨询项目的交付，进行项目管理、统筹、规划和跟踪，能够与客户高层领导沟通・完成领导交付的其他任务',
              ],
            },
            {
              subtitle: '完成领导交付的其他任务',
              listStyle: '·',
            },
          ],
          requirements: [
            '专科及以上学历，应用软件，数字营销，或其他IT相关领域有3年以上销售或客户服务经验',
            '具有丰富的客户资源和客户关系，业绩优秀',
            '具备较强的市场分析、营销、推广能力和优秀的客户沟通能力和商务处理能力、协调能力，分析和解决问题的能力',
            '有网络应用软件产品或网络设计销售经验者优先',
            '有较强的事业心，责任心，具备快速学习能力',
            '符合公司文化，具备创业精神，同理心，正能量，以及客户最重要的工作态度和职业精神',
            '简单的日语日常会话能力，较少商务交流经验可',
          ],
          benefits: [
            {
              title: '平台广阔',
              description: '外资企业背景，拥有海外出差机会',
            },
            {
              title: '快速能力提升',
              description: '本岗位与广泛行业都有连接，此项必将成为今后个人发展的强大武器',
            },
            {
              title: '职位魅力',
              description: '体验共同创业乐趣，积累作为创业者的成长经验',
            },
            {
              title: '保险福利',
              description: '入职即购买五险一金，定期体检',
            },
            {
              title: '工作与生活平衡',
              description: '弹性工作，带薪年假，法定节假日，周末双休',
            },
            {
              title: '初创公司的魅力',
              description: '在一家创业型公司，你必须也有机会来拓展自己的视野。并且，正因为多样化的工作让你切身感受到，整个企业的愿景与你是紧密联系的，你将会发现，自己的所作所为不简简单单是挣钱，而是更有意义',
            },
          ],
          shLink: 'https://www.liepin.com/job/1945822785.shtml',
          shLinkM: 'https://m.liepin.com/job/1945822785.shtml',
        },
        {
          name: 'IT项目管理',
          responsibilities: [
            '负责指导实施需求调研，制定报价书，实施方案设计，制定实施计划，能够向客户及公司内部人员进行解决方案说明',
            '实施项目管理，负责项目实施团队人员的合理安排及工作管理，解决实施过程中遇到的技术与业务上的问题，控制项目实施进度和质量，确保项目顺利完成，负责最终验收与项目归档',
            '善于和客户沟通，了解客户对业务的需求及改进意见，及时反馈，跟踪和解决问题，提高客户满意度，取得客户的信任',
            '有能力对客户的业务流程做出建设性建议',
          ],
          requirements: [
            '大专或以上学历，计算机或软件开发类专业优先',
            '三年以上企业应用级软件项目实施管理经验，能够独立带领10人以内的团队',
            '熟悉项目管理流程与方法',
            '工作热情、主动、有耐心，有较强的统筹与沟通协调能力',
            '有较强的事业心，责任心，具备快速学习能力',
            '简单的日语日常会话，较少商务交流经验可',
          ],
          benefits: [
            {
              title: '平台广阔',
              description: '外资企业背景，拥有海外出差机会',
            },
            {
              title: '快速能力提升',
              description: '工作内容具有多样性，挑战性，迅速提升个人能力',
            },
            {
              title: '保险福利',
              description: '入职即购买五险一金，定期体检',
            },
            {
              title: '工作与生活平衡',
              description: '弹性工作，带薪年假，法定节假日，周末双休',
            },
            {
              title: '初创公司的魅力',
              description: '在一家创业型公司，你必须也有机会来拓展自己的视野。并且，正因为多样化的工作让你切身感受到，整个企业的愿景与你是紧密联系的，你将会发现，自己的所作所为不简简单单是挣钱，而是更有意义',
            },
          ],
          shLink: 'https://www.liepin.com/job/1945874867.shtml',
          shLinkM: 'https://m.liepin.com/job/1945874867.shtml',
        },
      ],
    },
    {
      id: 'dev',
      name: '开发部门',
      jobs: [
        {
          name: '软件项目经理',
          responsibilities: [
            '把握客户的业务需求',
            '对所承担的项目进行需求分析，提出项目实施的可行性报告，制定项目开发计划',
            '负责项目组开发进度，内部人员的协调',
            '负责与客户沟通交流，推进和解决项目中的业务和技术难题',
          ],
          requirements: [
            '计算机及其相关专业，本科以上学历',
            '日语一级相当水平，能和日方人员进行无障碍的业务层面沟通；',
            '3年以上对日软件项目管理经验',
            '熟悉对日软件开发的作业流程和品质基准',
            '有团队建设及管理经验',
            '具有手机应用开发、Web 系统开发方面实际经验者优先',
          ],
          benefits: [
            {
              title: '平台广阔',
              description: '外资企业背景，专注海外市场应用，拥有海外出差机会',
            },
            {
              title: '快速能力提升',
              description: '为新员工配备导师，培训多样性；海外项目，挑战性高，提升快',
            },
            {
              title: '完善的薪酬制度',
              description: '每年两次加薪与晋升机会',
            },
            {
              title: '保险福利',
              description: '入职即购买五险一金',
            },
            {
              title: '文化福利',
              description: '年度春游+秋游，年度体检，团建福利多多',
            },
            {
              title: '工作与生活平衡',
              description: '带薪年假，法定节假日，周末双休',
            },
          ],
          cdLink: 'https://jobs.51job.com/chengdu-gxq/114412329.html',
          cdLinkM: 'https://msearch.51job.com/jobs/chengdu-gxq/114412329.html',
        },
        {
          name: 'iOS 开发工程师',
          responsibilities: [
            '面向海外市场开发高品质的 iOS 客户端（应用程序方向）',
          ],
          requirements: [
            '大学本科或以上学历，计算机相关专业',
            '一年以上 iOS 开发经验，掌握App开发主流框架，熟练掌握 Swift 者优先',
            '熟悉各种常用算法和数据结构；熟悉多线程、网络通讯编程',
            '具有良好的逻辑梳理能力，能够快速掌握业务功能逻辑体系，具备良好的编码习惯',
            '学习能力强，思维敏捷、喜欢钻研，责任心强，能够独立开展工作任务',
            '具有优秀的团队合作和沟通协作能力，善于学习，乐于分享',
            '有上线的实际作品，自己的技术博客，或在 GitHub 上分享代码者优先',
            '加分项：有一定日语能力或对日软件开发经验',
          ],
          benefits: [
            {
              title: '平台广阔',
              description: '外资企业背景，专注海外市场应用，拥有海外出差机会',
            },
            {
              title: '快速能力提升',
              description: '为新员工配备导师，培训多样性；海外项目，挑战性高，提升快',
            },
            {
              title: '完善的薪酬制度',
              description: '每年两次加薪与晋升机会',
            },
            {
              title: '保险福利',
              description: '入职即购买五险一金',
            },
            {
              title: '文化福利',
              description: '年度春游+秋游，年度体检，团建福利多多',
            },
            {
              title: '工作与生活平衡',
              description: '带薪年假，法定节假日，周末双休',
            },
          ],
          cdLink: 'https://jobs.51job.com/chengdu-gxq/66100235.html',
          cdLinkM: 'https://msearch.51job.com/jobs/chengdu-gxq/66100235.html',
          dlLink: 'http://jobs.zhaopin.com/CC263045631J00293152204.htm',
          dlLinkM: 'https://m.zhaopin.com/jobs/CC263045631J00293152204.htm',
        },
        {
          name: 'Android 开发工程师',
          responsibilities: [
            '面向海外市场开发高品质的 Android 客户端（应用程序方向）',
          ],
          requirements: [
            '大学本科或以上学历，计算机相关专业',
            '一年以上 Android 项目开发经验，具备扎实的 Java 编程基础，熟练掌握 Kotlin 者优先',
            '熟练掌握 Android UI 框架和底层架构，熟悉基于 Android SDK 的应用程序开发',
            '熟悉各种常用算法和数据结构；熟悉多线程、网络通讯编程',
            '具有良好的逻辑梳理能力，能够快速掌握业务功能逻辑体系，具备良好的编码习惯',
            '学习能力强，思维敏捷、喜欢钻研，责任心强，能够独立开展工作任务',
            '具有优秀的团队合作和沟通协作能力，善于学习，乐于分享',
            '有上线的实际作品，自己的技术博客，或在 GitHub 上分享代码者优先',
            '加分项：有一定日语能力或对日软件开发经验',
          ],
          benefits: [
            {
              title: '平台广阔',
              description: '外资企业背景，专注海外市场应用，拥有海外出差机会',
            },
            {
              title: '快速能力提升',
              description: '为新员工配备导师，培训多样性；海外项目，挑战性高，提升快',
            },
            {
              title: '完善的薪酬制度',
              description: '每年两次加薪与晋升机会',
            },
            {
              title: '保险福利',
              description: '入职即购买五险一金',
            },
            {
              title: '文化福利',
              description: '年度春游+秋游，年度体检，团建福利多多',
            },
            {
              title: '工作与生活平衡',
              description: '带薪年假，法定节假日，周末双休',
            },
          ],
          cdLink: 'https://jobs.51job.com/chengdu-gxq/66100254.html',
          cdLinkM: 'https://msearch.51job.com/jobs/chengdu-gxq/66100254.html',
          dlLink: 'http://jobs.zhaopin.com/CC263045631J00293153604.htm',
          dlLinkM: 'https://m.zhaopin.com/jobs/CC263045631J00293153604.htm',
        },
        {
          name: 'Web 前端开发工程师',
          responsibilities: [
            '负责协同移动端、PC 端前端、HTML5 前端开发工作',
            '利用 HTML5 相关技术开发网站、手机、平板电脑等多平台的前端应用，实现各种交互效果',
            '持续的优化前端体验和页面响应速度，并保证兼容性和执行效率',
            '负责前端界面和前端组件/类库的开发工作，研究和探索创新的开发思路和前沿的前端技术，应用到团队与产品中',
          ],
          requirements: [
            '大学本科或以上学历，计算机相关专业',
            '精通 HTML5、CSS3、JavaScript等新功能，熟悉页面架构和布局、模块分离设计经验，掌握 PC、Android、iOS 不同平台的 HTML5 页面的适配',
            '熟练使用 jQuery，熟悉至少一种主流前端框架（如 React / Angular / Vue.js），能独立实现基本的前端交互',
            '会使用前端调试工具发现并解决代码问题',
            '具有良好的逻辑梳理能力，能够快速掌握业务功能逻辑体系，具备良好的编码习惯',
            '学习能力强，思维敏捷、喜欢钻研，责任心强，能够独立开展工作任务',
            '具有优秀的团队合作和沟通协作能力，善于学习，乐于分享',
            '有自己的技术博客或在 GitHub 上分享代码者优先',
            '加分项：有一定日语能力或对日软件开发经验',
          ],
          benefits: [
            {
              title: '平台广阔',
              description: '外资企业背景，专注海外市场应用，拥有海外出差机会',
            },
            {
              title: '快速能力提升',
              description: '为新员工配备导师，培训多样性；海外项目，挑战性高，提升快',
            },
            {
              title: '完善的薪酬制度',
              description: '每年两次加薪与晋升机会',
            },
            {
              title: '保险福利',
              description: '入职即购买五险一金',
            },
            {
              title: '文化福利',
              description: '年度春游+秋游，年度体检，团建福利多多',
            },
            {
              title: '工作与生活平衡',
              description: '带薪年假，法定节假日，周末双休',
            },
          ],
          cdLink: 'https://jobs.51job.com/chengdu-gxq/93029203.html',
          cdLinkM: 'https://msearch.51job.com/jobs/chengdu-gxq/93029203.html',
          dlLink: 'http://jobs.zhaopin.com/CC263045631J00293155304.htm',
          dlLinkM: 'https://m.zhaopin.com/jobs/CC263045631J00293155304.htm',
        },
        {
          name: '后端开发工程师',
          responsibilities: [
            '参与产品的需求调研和需求分析',
            '搭建系统开发环境，完成系统框架和核心代码的实现',
            '不断优化用户体验以及服务端的性能',
            '系统开发测试、部署和集成',
            '维护相关系统、保证系统运行的稳定性',
          ],
          requirements: [
            '大学本科或以上学历（能力突出者可以酌情降低要求）',
            'PHP, Ruby, Java, Go,Python等任意开发语言3年以上开发经验',
            'MySQL/MariaDB/PostgreSQL等任意RDBMS3年以上使用经验',
            '能按项目需求做成数据库设计',
            '具有良好的逻辑梳理能力，能够快速掌握业务功能逻辑体系，具备良好的编码习惯',
            '学习能力强，思维敏捷、喜欢钻研，责任心强，能够独立开展工作任务',
            '具有优秀的团队合作和沟通协作能力，善于学习，乐于分享',
            '有自己的技术博客或在 GitHub 上分享代码者优先',
            '加分项：有一定日语能力或对日软件开发经验',
          ],
          benefits: [
            {
              title: '平台广阔',
              description: '外资企业背景，专注海外市场应用，拥有海外出差机会',
            },
            {
              title: '快速能力提升',
              description: '为新员工配备导师，培训多样性；海外项目，挑战性高，提升快',
            },
            {
              title: '完善的薪酬制度',
              description: '每年两次加薪与晋升机会',
            },
            {
              title: '保险福利',
              description: '入职即购买五险一金',
            },
            {
              title: '文化福利',
              description: '年度春游+秋游，年度体检，团建福利多多',
            },
            {
              title: '工作与生活平衡',
              description: '带薪年假，法定节假日，周末双休',
            },
          ],
          cdLink: 'https://jobs.51job.com/chengdu-gxq/105226608.html',
          cdLinkM: 'https://msearch.51job.com/jobs/chengdu-gxq/105226608.html',
          dlLink: 'http://jobs.zhaopin.com/CC263045631J00293156604.htm',
          dlLinkM: 'https://m.zhaopin.com/jobs/CC263045631J00293156604.htm',
        },
      ],
    },
    {
      id: 'qa',
      name: '品质管理部门',
      jobs: [
        {
          name: '软件测试工程师',
          responsibilities: [
            '根据测试用例，完成手机 App 和 Web App 软件产品的测试',
            '理解产品需求，作成测试用例和相关测试文档',
            '根据 Bug 产生过程和 Bug 现象作成记录票，并跟踪关闭 Bug',
            '收集测试品质数据，参与项目品质分析，为开发团队品质改善提供参考数据',
          ],
          requirements: [
            '日语能力 N2 以上',
            '熟悉软件的测试流程，具有1个以上完整项目的测试经验',
            '熟练使用 JIRA，Backlog，Redmine，GitLab 等常用 Bug 管理工具',
            '熟悉敏捷开发模式和作业流程',
            '学习能力强，思维敏捷、喜欢钻研，责任心强，能够独立开展工作任务',
            '具有优秀的团队合作和沟通协作能力，善于学习，乐于分享',
            '掌握 Oracle / SQL Server / MySQL 等常用数据库系统的增删改查操作优先',
            '有编写过自动化测试脚本的经验优先',
            '有对日项目测试经验者优先',
          ],
          benefits: [
            {
              title: '平台广阔',
              description: '外资企业背景，专注海外市场应用，拥有海外出差机会',
            },
            {
              title: '快速能力提升',
              description: '为新员工配备导师，培训多样性；海外项目，挑战性高，提升快',
            },
            {
              title: '完善的薪酬制度',
              description: '每年两次加薪与晋升机会',
            },
            {
              title: '保险福利',
              description: '入职即购买五险一金',
            },
            {
              title: '文化福利',
              description: '年度春游+秋游，年度体检，团建福利多多',
            },
            {
              title: '工作与生活平衡',
              description: '带薪年假，法定节假日，周末双休',
            },
          ],
          cdLink: 'https://jobs.51job.com/chengdu-gxq/89032550.html',
          cdLinkM: 'https://msearch.51job.com/jobs/chengdu-gxq/89032550.html',
          dlLink: 'https://jobs.zhaopin.com/CC263045631J00293157304.htm'
            + '?srccode=401901&preactionid=3e825d17-5cbe-4206-a80f-f39e22bd9d0a',
          dlLinkM: 'https://m.zhaopin.com/jobs/CC263045631J00293157304.htm',
        },
      ],
    },
  ],
};
