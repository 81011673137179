<template>
  <button class="button with-hover" :class="containerClass" @click="handleBtnClick">
    <span>{{pr.contact}}</span>
  </button>
</template>

<script>
import usePageResource from '@/composables/usePageResource';

export default {
  setup() {
    const { pr } = usePageResource('works');
    return {
      pr,
    };
  },
  name: 'ContactButton',
  props: {
    containerClass: {
      type: String,
      default: '',
    },
    btnClickEvent: Function,
  },
  data() {
    return {};
  },
  methods: {
    handleBtnClick() {
      if (typeof this.btnClickEvent === 'function') {
        this.btnClickEvent();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .button {
    width: 360px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #A6AEB1;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: inherit;
    cursor: pointer;
    outline: none;
    text-decoration: none;

    &:hover {
      opacity: 1;
    }

    > span {
      font-size: 16px;
      color: #222222;
      letter-spacing: 2.8px;
      font-weight: bold;
    }

    @media only screen and (max-width: 768px) {
      width: 250px;
    }
  }
</style>
