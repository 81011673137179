import aiImages1 from '@/assets/new-pages/new_20231205_01.png';
import aiImages2 from '@/assets/new-pages/new_20231205_02.png';

export default {
  sectionList: [
    {
      subtitle: '',
      content: [
        {
          partType: 'img',
          value: {
            src: aiImages1,
            alt: 'aiImages1',
          },
        },
        {
          partType: 'string',
          value:
            // eslint-disable-next-line max-len
            '私たち飛狼の親会社であるFenrir Inc. は、オープンで安全な責任あるAI を推進するための国際的なコミュニティであるAIアライアンス（The AI Alliance、以下 AIアライアンス）に創立メンバーとして参画します。',
        },
      ],
    },
    {
      subtitle: 'AIアライアンスについて',
      content: [
        {
          partType: 'string',
          value:
            // eslint-disable-next-line max-len
            'AIアライアンスとは、2023年12月5日に設立されたオープンで安全な責任あるAIの進化を加速させるための取り組みです。IBM社やMeta社をはじめ、各界をリードする世界50を超える組織が結集し、Fenrir Inc. も設立メンバーとして参画します。Fenrir Inc. は、2005年の創業以来、デザインと技術の融合により、より良いユーザー体験を提供してきました。実用性のあるAIの実現に向け、デザインと技術でAIのオープン・イノベーションとオープン・サイエンスに貢献します。',
        },
        {
          partType: 'string',
          value:
            // eslint-disable-next-line max-len
            "AIアライアンスについての詳細は、以下をご覧ください。\n<a target='_blank' href='https://thealliance.ai/'>https://thealliance.ai/</a>",
        },
      ],
    },
    {
      subtitle: 'Aitomatic社について',
      content: [
        {
          partType: 'string',
          value:
            // eslint-disable-next-line max-len
            'シリコンバレーに拠点を置くAitomatic社は、生成AIの分野で産業界を牽引する存在であり、AIの活用をリードして います。GoogleやPanasonicで豊富な経験を持つクリストファー・グエン（Christopher Nguyen）博士が、 Aitomatic社を設立。同社が推進するOpenSSAを基盤にaiVA (バーチャルアドバイザー）などのAIプロダクトを展開しています。Aitomatic 社は世界中の企業にとって貴重なパートナーとなっており、IBM社やMeta社とともに今回のAI アライアンスの設立に寄与しています。'
            + '\nFenrir Inc. ではAitomatic社が進めるOpenSSAと連携し、私たちが強みとするUX/UI領域でのソフトウェア開発に おけるビジネス効率の向上に取り組んでいます。',
        },
      ],
    },
    {
      subtitle: 'Fenrir Inc.  最高経営責任者 牧野兼史のコメント',
      content: [
        {
          partType: 'string',
          value:
            // eslint-disable-next-line max-len
            'このたび、世界的なアライアンスに名を連ねることができ光栄に思います。急速に発展するAIの分野で世界中のプロフェッショナルと協業できる機会を得たことで、Fenrir Inc. の技術力がより向上すると期待しています。このアライアンスにおいて、Fenrir Inc. が培ってきたデザインや技術力と、オープンで透明性の高いAIのイノベーションを連携させることで社会へ貢献できると考えています。',
        },
      ],
    },
    {
      subtitle: 'Fenrir Inc.  開発センター長 高木健次のコメント',
      content: [
        {
          partType: 'string',
          value:
            // eslint-disable-next-line max-len
            'Aitomatic社のOpenSSA技術を活用した彼らとの協業は、ユーザー中心の産業用AIソリューションを構築する上で極めて重要です。このパートナーシップは、お客さまのニーズに合わせてカスタマイズされたソリューションを提供し、業界の効率化とイノベーションを推進する上で重要な鍵になります。',
        },
      ],
    },
    {
      subtitle: 'Aitomatic社 CEO クリストファー・グエン博士のコメント',
      content: [
        {
          partType: 'string',
          // eslint-disable-next-line max-len
          value: 'AIの独立性は、企業にとって、特定の分野での専門知識を集中させ、競争力を保つためにとても大切です。オープンソースの基盤は、最新技術を使ったモデルをスムーズに展開する上で必要不可欠です。AitomaticとFenrir Inc. は、世界的なAIの連携を通じて、産業向けの革新的で責任あるAIの進化に貢献し、その恩恵をお客様にも受けていただきたいと考えています。',
        },
      ],
    },
    {
      subtitle: '',
      content: [
        {
          partType: 'img',
          value: {
            src: aiImages2,
            alt: 'aiImages2',
          },
        },
      ],
    },
    {
      subtitle: 'アライアンスパートナー（2023年12月5日現在／順不同）',
      content: [
        {
          partType: 'string',
          // eslint-disable-next-line no-useless-concat
          value:
            'シンガポール科学技術研究庁（A*STAR）'
            + '\nAitomatic, Inc.'
            + '\nAdvanced Micro Devices, Inc.（AMD）'
            + '\nAnyscale, Inc'
            + '\nCerebras'
            + '\n欧州合同原子核研究機構（CERN）'
            + '\nCleveland Clinic'
            + '\nコーネル大学'
            + '\nダートマス大学'
            + '\nDell Technologies, Inc.'
            + '\nスイス連邦工科大学ローザンヌ校'
            + '\nスイス連邦工科大学チューリッヒ校（ETH）'
            + '\nFast.ai'
            + '\nFenrir Inc.'
            + '\nFPT Software'
            + '\nヘブライ大学'
            + '\nHugging Face, Inc.'
            + '\nIBM（International Business Machines Corporation）'
            + '\nインペリアル・カレッジ・ロンドン（ICL）'
            + '\nインド工科大学ボンベイ校（IIT Bombay）'
            + '\n国際理論物理学センター（ICTP）'
            + '\nINSAIT（Institute for Computer Science, Artificial Intelligence and Technology）'
            + '\nIntel Corporation'
            + '\n慶應義塾大学'
            + '\nLangChain'
            + '\nLlamaIndex'
            + '\nThe Linux Foundation'
            + '\nMOC Alliance'
            + '\nMeta Platforms, Inc.（Meta）'
            + '\nモハメド・ビン・ザイード人工知能大学（MBZUAI）'
            + '\nMLCommons'
            + '\nアメリカ航空宇宙局（NASA）'
            + '\nアメリカ国立科学財団（NSF）'
            + '\nニューヨーク大学（NYU）'
            + '\nNumFOCUS'
            + '\nOpenTeams'
            + '\nOracle Corporation'
            + '\nPartnership on AI（PAI）'
            + '\nQuansight, Inc.'
            + '\nRed Hat, Inc.'
            + '\nレンセラー工科大学（RPI）'
            + '\nRoadzen, Inc.'
            + '\nSakana AI株式会社'
            + '\nSB Intuitions株式会社'
            + '\nServiceNow, Inc'
            + '\nSilo AI'
            + '\nサイモンズ財団'
            + '\nソニーグループ株式会社'
            + '\nStability AI LTD,'
            + '\nTogether AI'
            + '\nミュンヘン工科大学（TUM）'
            + '\nカリフォルニア大学バークレー校（UC Berkeley）'
            + '\nイリノイ大学アーバナ・シャンペーン校'
            + '\nノートルダム大学'
            + '\nテキサス大学オースティン校'
            + '\n東京大学'
            + '\nイェール大学'
          ,
        },
        {
          partType: 'string',
          // eslint-disable-next-line max-len
          value: "<a target='_blank' href='https://www.fenrir-inc.com/jp/news/2023/12/05/ai-alliance/'>Fenrir Inc. プレスリリース</a>",
        },
      ],
    },
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          // eslint-disable-next-line max-len
          value: "生成AIの活用に関する不明点や、活用したいニーズがございましたら、弊社でご支援することが可能です。下記までお問い合わせください。\n\n<strong>お問い合わせ：</strong>\n飛狼数碼（上海）有限公司 総経理 吉澤\nメール：<a href='mailto:info@fenrir-inc.com.cn'>info@fenrir-inc.com.cn</a>",
        },
      ],
    },
  ],
};
