<template>
  <div v-if="!isSP" class="box-pc">
    <div class="title">
      <div class="card">
        <span>{{ main?.tag }}</span>
      </div>
      <p v-viewportChanged="showView" v-show="main?.title" class="hidden-element des-title">
        {{ main?.title }}
      </p>
      <break-text
        v-viewportChanged="showView"
        class="hidden-element des-content"
        :content="main?.content"
        :isSpanBlock="true"
      />
    </div>
    <div class="image">
      <img :src="getImageUrl(main?.image?.src1)" class="img-person" :alt="main?.image?.src1" />
      <p class="img-title">{{ main.image.title }}</p>
      <p class="img-content">{{ main.image.content }}</p>
      <div class="person-img-box">
        <div class="img-out">
          <img class="screen-shot" :src="getImageUrl(main?.image?.src2)" :alt="main?.image?.src" />
        </div>
        <div class="img-name">
          <span>{{ main?.image?.name }}</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="box-sp">
    <div class="image">
      <div class="card">
        <span>{{ main?.tag }}</span>
      </div>
      <img :src="getImageUrl(main?.image?.src1)" class="img-person" :alt="main?.image?.src1" />
      <p class="img-title-sp">{{ main.image.title }}</p>
      <p class="img-content-sp">{{ main.image.content }}</p>
      <div class="person-img-box">
        <div class="img-out">
          <img class="screen-shot" :src="getImageUrl(main?.image?.src2)" :alt="main?.image?.src" />
        </div>
        <div class="img-name">
          <span>{{ main?.image?.name }}</span>
        </div>
      </div>
    </div>
    <div class="title">
      <p v-viewportChanged="showView" v-show="main?.title" class="hidden-element des-title-sp">
        {{ main?.title }}
      </p>
      <break-text
        v-viewportChanged="showView"
        class="hidden-element des-content-sp"
        :content="main?.content"
        :isSpanBlock="true"
      />
    </div>
  </div>
</template>

<script>
import useRenderData from '@/composables/useRenderData';
import BreakText from '@/components/common/BreakText.vue';
import useIsSP from '@/composables/useIsSP';

export default {
  components: {
    BreakText,
  },
  props: {
    main: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { getImageUrl } = useRenderData();
    const { isSP } = useIsSP(768);
    return {
      getImageUrl,
      isSP,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-pc {
  border-top: 1px solid #a6a6a64b;
  display: flex;
  justify-content: space-between;
  .title {
    box-sizing: border-box;
    padding: 48px 47px 0 0;
    display: flex;
    flex-direction: column;
    .card {
      width: 100%;
      margin-bottom: 14px;
      > span {
        display: inline-block;
        background-color: #000;
        color: #fff;
        padding: 0 8px;
        height: 24px;
        font-size: 14px;
        letter-spacing: 0.37px;
        line-height: 24px;
      }
    }
  }
  .image {
    min-width: 250px;
    padding-top: 94px;
    .img-person {
      width: 160px;
    }
    .person-img-box {
      display: flex;
      justify-content: space-between;
      align-self: center;
      .img-out {
        width: 160px;
        height: 160px;
        border: 2px solid #e6e9e9;
        overflow: hidden;
        border-radius: 50%;
        .screen-shot {
          width: 160px;
        }
      }
      .img-name {
        display: flex;
        align-items: center;
      }
    }
  }
}
.box-sp {
  border-top: 1px solid #a6a6a64b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .image {
    min-width: 250px;
    padding-top: 40px;
    margin-bottom: 40px;
    .card {
      width: 100%;
      margin-bottom: 24px;
      > span {
        display: inline-block;
        background-color: #000;
        color: #fff;
        padding: 0 8px;
        height: 24px;
        font-size: 14px;
        letter-spacing: 0.37px;
        line-height: 24px;
      }
    }
    .img-person {
      width: 160px;
    }
    .person-img-box {
      display: flex;
      justify-content: flex-start;
      align-self: center;
      .img-out {
        width: 160px;
        height: 160px;
        margin-right: 12px;
        border: 2px solid #e6e9e9;
        overflow: hidden;
        border-radius: 50%;
        .screen-shot {
          width: 160px;
        }
      }
      .img-name {
        display: flex;
        margin-left: 12px;
        align-items: center;
      }
    }
  }
  .title {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
}
.des-title {
  width: 100%;
  margin-bottom: 27px;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0.53px;
}
.des-content {
  width: 100%;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.6px;
}
.img-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}
.img-content {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
}

.img-title-sp {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}
.img-content-sp {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
}
.des-title-sp {
  width: 100%;
  margin-bottom: 27px;
  font-size: 19px;
  line-height: 26px;
  font-weight: bold;
  letter-spacing: 0.53px;
}
.des-content-sp {
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.6px;
}
</style>
