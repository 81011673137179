import new20210628 from './news-list/new_20210628_ja.json';
import new20210527 from './news-list/new_20210527_ja.json';
import new20210826 from './news-list/new_20210826_ja.json';
import new20210824 from './news-list/new_20210824_ja.json';
import new20210827 from './news-list/new_20210827_ja.json';
import new20210924 from './news-list/new_20210924_ja';
import new20211029 from './news-list/new_20211029_ja.json';
import new20211129 from './news-list/new_20211129_ja.json';
import new20211129Hidden from './news-list/new_20211129_ja_hidden.json';
import new20220324 from './news-list/new_20220324_ja.json';
import new20220414 from './news-list/new_20220414_ja.json';
import new20220414Hidden from './news-list/new_20220414_ja_hidden.json';
import new20220623 from './news-list/new_20220623_ja.json';
import new20220623Hidden from './news-list/new_20220623_ja_hidden.json';
import news20220823 from './news-list/news_20220823_ja';
import news20220921 from './news-list/news_20220921_ja.json';
import news20220921Hidden from './news-list/news_20220921_ja_hidden.json';
import news20221031 from './news-list/news_20221031_ja.json';
import news20221125 from './news-list/news_20221125_ja.json';
import news20230130 from './news-list/news_20230130_ja.json';
import news20230307 from './news-list/news_20230307_ja.json';
import news20230418 from './news-list/news_20230418_ja.json';
import news20230522 from './news-list/news_20230522_ja.json';
import news20230629 from './news-list/news_20230629_ja.json';
import news20230701 from './news-list/news_20230701_ja';
import news20231108 from './news-list/news_20231108_ja.json';
import news20231205 from './news-list/new_20231205_ja';
import news20231225 from './news-list/news_20231225_ja.json';
import news20240115 from './news-list/news_20240115_ja.json';
import news20241015 from './news-list/news_20241015_ja';
import news20241021 from './news-list/news_20241021_ja';

const newsZH = {
  title: 'ニュース',
  list: [
    {
      id: '20241021',
      date: '2024.10.21',
      type: 'その他',
      epitome: '',
      title: '飛狼成都、オフィス移転のお知らせ',
      mainPicture: '',
      sectionList: news20241021.sectionList,
      done: false,
    },
    {
      id: '20241015',
      date: '2024.10.15',
      type: 'その他',
      epitome: '',
      title: '飛狼上海、オフィス移転のお知らせ',
      mainPicture: '',
      sectionList: news20241015.sectionList,
      done: false,
    },
    {
      id: '20240115',
      date: '2024.01.15',
      type: 'イベント',
      epitome: '2/22、3/7：上海ハンド情報技術株式会社 、飛狼数碼（上海）有限公司、2社合同主催オンラインセミナー',
      title: '【中国最新情報アップデート】中国における生成AIの動向 及び 中国企業での活用事例を徹底解説',
      mainPicture: '',
      sectionList: news20240115.sectionList,
      done: false,
    },
    {
      id: '20231225',
      date: '2023.12.25',
      type: 'イベント',
      epitome: '1/30 Pactera Technology International、飛狼数碼（上海）有限公司、２社合同主催オンラインセミナー',
      title: '【中国最新情報アップデート】中国カーボンニュートラルの動向と対策事例、及び、 中国生成AIの動向と活用事例',
      mainPicture: '',
      sectionList: news20231225.sectionList,
      done: false,
    },
    {
      id: '20231205',
      date: '2023.12.05',
      type: 'プレスリリース',
      epitome: '',
      title: 'Fenrir Inc. 、AIアライアンスに創立メンバーとして参加',
      mainPicture: '',
      sectionList: news20231205.sectionList,
      done: false,
    },
    {
      id: '20231108',
      date: '2023.11.08',
      type: 'イベント',
      epitome: '11月：飛狼数碼（上海）有限公司 主催オンラインセミナー',
      title: '【中国進出企業必見】2023年 中国最新情報アップデート 中国でのビジネス ( 進出・拡大 ) に役立つ：生成AIとUXデザイン',
      mainPicture: '',
      sectionList: news20231108.sectionList,
      done: true,
    },
    {
      id: '20230701',
      date: '2023.07.01',
      type: 'その他',
      epitome: '',
      title: '飛狼成都、オフィス移転のお知らせ',
      mainPicture: '',
      sectionList: news20230701.sectionList,
      done: false,
    },
    {
      id: '20230629',
      date: '2023.06.29',
      type: 'イベント',
      epitome: '7/19 上海文思海輝信息技術有限公司、飛狼数碼（上海）有限公司、２社合同主催オンラインセミナー',
      title: '【中国最新情報アップデート】中国におけるカーボンニュートラル関連法規の動向と、データ越境移転規制・サイバーセキュリティ法の対策',
      mainPicture: '',
      sectionList: news20230629.sectionList,
      done: true,
    },
    {
      id: '20230522',
      date: '2023.05.22',
      type: 'イベント',
      epitome: '6月：3週連続開催 飛狼数碼（上海）有限公司 主催オンラインセミナー',
      title: '【中国進出企業必見】2023年 中国最新情報アップデート 中国でのビジネス ( 進出・拡大 ) に役立つ 押さえておくべき３つのこと',
      mainPicture: '',
      sectionList: news20230522.sectionList,
      done: true,
    },
    {
      id: '20230418',
      date: '2023.04.18',
      type: 'イベント',
      epitome: '4/25 北京市環球法律事務所・飛狼数碼（上海）有限公司　共同主催セミナー・交流会',
      title: '[中国進出企業必見] 中国サイバーセキュリティとデータ越境移転に関わる法制動向と対策',
      mainPicture: '',
      sectionList: news20230418.sectionList,
      done: true,
    },
    {
      id: '20230307',
      date: '2023.03.07',
      type: 'イベント',
      epitome: '3/23 飛狼数碼（上海）有限公司 主催オンラインセミナー',
      title: '“企業微信”徹底活用講座〜基本機能と顧客接点強化 編〜',
      mainPicture: '',
      sectionList: news20230307.sectionList,
      done: true,
    },
    {
      id: '20230130',
      date: '2023.01.30',
      type: 'イベント',
      epitome: '2/9 飛狼数碼（上海）有限公司 主催オンラインセミナー',
      title: '“企業微信”徹底活用講座〜内部管理と営業支援 編〜',
      mainPicture: '',
      sectionList: news20230130.sectionList,
      done: true,
    },
    {
      id: '20221125',
      date: '2022.11.25',
      type: 'イベント',
      epitome: '12/22 飛狼数碼（上海）有限公司 主催オンラインセミナー',
      title: '“企業微信”徹底活用講座〜営業支援と顧客管理 編〜',
      mainPicture: '',
      sectionList: news20221125.sectionList,
      done: true,
    },
    {
      id: '20221031',
      date: '2022.10.31',
      type: 'イベント',
      epitome: '11/24 飛狼数碼（上海）有限公司 主催オンラインセミナー',
      title: '“企業微信”徹底活用講座〜コンプライアンス対応とセキュリティ対策 編〜',
      mainPicture: '',
      sectionList: news20221031.sectionList,
      done: true,
    },
    {
      id: '20221012',
      date: '2022.09.21',
      to: null,
      type: 'イベント',
      epitome: '10/12 飛狼数碼（上海）有限公司 主催オンラインセミナー',
      title: '[中国進出企業必見] データ越境移転に関わる中国最新法令・政策動向と実務対応',
      mainPicture: '',
      sectionList: news20220921Hidden.sectionList,
      hidden: true,
      done: true,
    },
    {
      id: '20220921',
      date: '2022.09.21',
      type: 'イベント',
      epitome: '10/12 飛狼数碼（上海）有限公司 主催オンラインセミナー',
      title: '[中国進出企業必見] データ越境移転に関わる中国最新法令・政策動向と実務対応',
      mainPicture: '',
      sectionList: news20220921.sectionList,
      done: true,
    },
    {
      id: '20220823',
      date: '2022.08.23',
      type: 'その他',
      epitome: '',
      title: '飛狼上海、オフィス移転のお知らせ',
      mainPicture: '',
      sectionList: news20220823.sectionList,
      done: false,
    },
    {
      id: '20220623',
      date: '2022.06.23',
      type: 'イベント',
      epitome: '7/14 株式会社TOKAIコミュニケーションズ、飛狼数碼（上海）有限公司、株式会社縁通　３社合同主催オンラインセミナー',
      title: '中国現地法人におけるAWS活用の勘所 〜中国リージョン、日本リージョンどちらを選ぶべき？実現案、課題（サイバーセキュリティ法対応他）、解決策までご紹介！〜',
      mainPicture: '',
      sectionList: new20220623.sectionList,
      hidden: false,
      done: true,
    },
    {
      id: '20220714',
      date: '2022.06.23',
      type: 'イベント',
      epitome: '7/14 株式会社TOKAIコミュニケーションズ、飛狼数碼（上海）有限公司、株式会社縁通　３社合同主催オンラインセミナー',
      title: '中国現地法人におけるAWS活用の勘所 〜中国リージョン、日本リージョンどちらを選ぶべき？実現案、課題（サイバーセキュリティ法対応他）、解決策までご紹介！〜',
      mainPicture: '',
      sectionList: new20220623Hidden.sectionList,
      hidden: true,
      done: true,
    },
    {
      id: '20220518',
      date: '2022.04.14',
      type: 'イベント',
      epitome: '5/18 飛狼数碼（上海）有限公司、株式会社オクムラ、株式会社縁通　３社合同主催オンラインセミナー',
      title: '中国コロナ禍におけるテレワーク、及びサイバーセキュリティ関連法はこれで対応',
      mainPicture: '',
      sectionList: new20220414Hidden.sectionList,
      hidden: true,
      done: true,
    },
    {
      id: '20220414',
      date: '2022.04.14',
      type: 'イベント',
      epitome: '5/18 飛狼数碼（上海）有限公司、株式会社オクムラ、株式会社縁通　３社合同主催オンラインセミナー',
      title: '中国コロナ禍におけるテレワーク、及びサイバーセキュリティ関連法はこれで対応',
      mainPicture: '',
      sectionList: new20220414.sectionList,
      done: true,
    },
    {
      id: '20220324',
      date: '2022.03.11',
      type: 'イベント',
      epitome: '3/24 飛狼数碼（上海）有限公司・日鉄軟件（上海）有限公司 共同主催オンラインセミナー',
      title: '中国におけるBtoBマーケティングとセキュアな電子契約ソリューション',
      mainPicture: '',
      sectionList: new20220324.sectionList,
      done: true,
    },
    {
      id: '20211216',
      date: '2021.11.29',
      type: 'イベント',
      epitome: '12/16 アリババクラウド・飛狼数碼（上海）有限公司 共同主催オンラインセミナー',
      title: '中国での個人情報保護とデータ越境移転、Salesforce 活用の対策',
      mainPicture: '',
      sectionList: new20211129Hidden.sectionList,
      hidden: true,
      done: true,
    },
    {
      id: '20211129',
      date: '2021.11.29',
      type: 'イベント',
      epitome: '12/16 アリババクラウド・飛狼数碼（上海）有限公司 共同主催オンラインセミナー',
      title: '中国での個人情報保護とデータ越境移転、Salesforce 活用の対策',
      mainPicture: '',
      sectionList: new20211129.sectionList,
      done: true,
    },
    {
      id: '20211029',
      date: '2021.10.29',
      type: 'イベント',
      epitome: '11/4 飛狼数碼（上海）有限公司 主催オンラインセミナー',
      title: '中国の最先端デジタルソリューションにおけるリアルなユーザー体験とは？',
      mainPicture: '',
      sectionList: new20211029.sectionList,
      done: true,
    },
    {
      id: '20210924',
      date: '2021.09.24',
      type: 'その他',
      epitome: '',
      title: '飛狼成都、オフィス移転のお知らせ',
      mainPicture: '',
      sectionList: new20210924.sectionList,
      done: false,
    },
    {
      id: '20210827',
      date: '2021.08.27',
      type: 'イベント',
      epitome: '9/22 中国進出企業様向け オンラインセミナー',
      title: '中国データセキュリティ法等とコンプライアンス対応（2021年 最新情報アップデート）',
      mainPicture: '',
      sectionList: new20210827.sectionList,
      hidden: true,
      done: true,
    },
    {
      id: '20210825',
      date: '2021.08.25',
      type: 'イベント',
      epitome: '9/22 中国進出企業様向け オンラインセミナー',
      title: '中国データセキュリティ法等とコンプライアンス対応（2021年 最新情報アップデート）',
      mainPicture: '',
      sectionList: new20210824.sectionList,
      done: true,
    },
    {
      id: '20210806',
      date: '2021.08.06',
      type: 'イベント',
      epitome: '8/26 飛狼数碼（上海）有限公司 主催オンラインセミナー',
      title: '企業微信と電子発票を用いた経費精算の業務効率化',
      mainPicture: '',
      sectionList: new20210826.sectionList,
      done: true,
    },
    {
      id: '20210628',
      date: '2021.06.28',
      type: 'イベント',
      epitome: '7/15 飛狼数碼（上海）有限公司 主催オンラインセミナー',
      title: '中国サイバーセキュリティ法と中国個人情報保護法の動向と対策',
      mainPicture: '',
      sectionList: new20210628.sectionList,
      done: true,
    },
    {
      id: '20210527',
      date: '2021.05.27',
      type: 'イベント',
      epitome: '5/27 飛狼数碼（上海）有限公司 主催オンラインミニウェビナー',
      title: '「企業微信」を活用した業務改革',
      mainPicture: '',
      sectionList: new20210527.sectionList,
      done: true,
    },
  ],
  share: 'シェア',
  doneContent: {
    beforeWrap: 'このセミナーの受付は',
    afterWrap: '終了いたしました。',
    beforeLink: '今後開催されるセミナー情報などをご希望の方は、',
    link: 'こちらのフォーム',
    afterLink: 'からお申し込みください。',
    href: 'contact-us',
  },
  wxScan: 'WeChatでスキャンしてください',
  more: 'ニュース一覧',
  back: 'ニュース一覧',
};

export default newsZH;
