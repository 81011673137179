<template>
  <div class="thanks">
    <div class="content">
      <div class="content-title">
        <p :class="['content-title-top', { titleTopJA: !isZH }]">{{pr.title_top}}</p>
        <p :class="['content-title-bottom pc', { titleBottomJA: !isZH }]">{{pr.title_bottom}}</p>
        <div :class="['content-title-bottom sp', { titleBottomJA: !isZH }]">
          <p>{{pr.title_bottom.split('、')[0]}}</p>
          <p>{{pr.title_bottom.split('、')[1]}}</p>
        </div>
      </div>
      <div class="content-desc">
        <p class="pc" v-html="pcDesc"></p>
        <p class="sp" v-html="spDesc"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { LOCALES } from '@/constants/constants';
import usePageResource from '@/composables/usePageResource';

export default {
  setup() {
    const { pr } = usePageResource('thanks-contact');
    const locale = inject('locale');
    return {
      pr,
      locale,
    };
  },
  computed: {
    isZH() {
      return this.locale === LOCALES.ZH;
    },
    pcDesc() {
      return this.pr.desc.replace(/\n/gm, '<br/>').replace(/\t/gm, '');
    },
    spDesc() {
      return this.pr.desc.replace(/\n/gm, '<br/>').replace(/\t/gm, '<br/>');
    },
  },
};
</script>

<style lang="scss" scoped>
$max-width-sp: 768px;

.sp {
  @media screen and(min-width: $max-width-sp + 1) {
    display: none;
  }
}

.pc {
  @media screen and(max-width: $max-width-sp) {
    display: none;
  }
}

.thanks {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 56px;
  box-sizing: content-box;
  font-weight: 400;
  color: #222222;
  letter-spacing: 0.48px;

  .content {
    max-width: 988px;
    padding: 0 24px;

    @media screen and (max-width: $max-width-sp) {
      width: 100%;
    }

    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-top {
        height: 42px;
        line-height: 42px;
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0.8px;
        margin: 90px 0 14px 0;

        @media screen and (max-width: $max-width-sp) {
          height: 30px;
          line-height: 30px;
          font-size: 22px;
          letter-spacing: 0.59px;
          margin: 56px 0 10px 0;
        }

        &.titleTopJA {
          height: 30px;
          line-height: 30px;
          margin: 96px 0 12px 2px;

          @media screen and (max-width: $max-width-sp) {
            margin: 56px 0 7px 0;
          }
        }
      }

      &-bottom {
        height: 28px;
        line-height: 28px;
        text-align: center;
        font-style: 18px;
        margin: 0 0 90px 0;

        @media screen and (max-width: $max-width-sp) {
          height: 28px;
          line-height: 28px;
          font-size: 14px;
          letter-spacing: 0.37px;
          margin: 0 0 39px 0;
        }

        &.titleBottomJA {
          margin: 0 0 94px 0;

          @media screen and (max-width: $max-width-sp) {
            height: 56px;
            line-height: 22px;
            margin: 0 0 40px 0;
            padding: 6px 0;
          }
        }
      }
    }

    &-desc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      margin-bottom: 160px;

      @media screen and (max-width: $max-width-sp) {
        text-align: center;
        width: 100%;
        margin-bottom: 100px;
        font-size: 16px;
      }

      p {
        line-height: 28px;

        @media screen and (max-width: $max-width-sp) {
          line-height: 30px;
          letter-spacing: 0.43px;
        }
      }
    }
  }
}
</style>
