<template>
  <div
    :id="`about_${model.id}`"
    :class="['about-info', model.id, language, showView ? 'fade-in-element' : 'hidden-element']"
  >
    <div class="about-info-top">
      <div class="about-info-content">
        <h5 class="about-info-title">
          {{ model.name || '' }}
        </h5>
        <p class="about-info-subtitle">
          {{ model.nameEN || '' }}
        </p>
        <div class="item about-info-manager">
          <h6 class="item-label">
            {{ manager.title || '' }}
          </h6>
          <p class="item-title">
            {{ manager.name || '' }}
          </p>
          <p
            v-for="(desc, i) in description" :key="i"
            v-html="desc" class="item-content about-info-description"
          ></p>
        </div>
      </div>
    </div>
    <div class="about-info-bottom">
      <div class="item address">
        <h6 class="item-label">{{info.addressTitle}}</h6>
        <div class="item-content">
          {{locale === 'ja' && address.postcode ? `〒${address.postcode} ` : ''}}{{ addressText}}
        </div>
        <div class="address-btn">
          <link-btn :style="{ width: 176 + 'px' }" target="_blank" :href="addressLink">
            {{ info.addressBtnText }}
          </link-btn>
        </div>
      </div>
      <div v-if="locale !== 'ja' && address.postcode" class="item postcode">
        <h6 class="item-label">{{ info.postcodeTitle }}</h6>
        <p class="item-content">{{ address.postcode }}</p>
      </div>
      <div v-if="model.phoneNumber" class="item phone">
        <h6 class="item-label">{{ info.phoneNumberTitle }}</h6>
        <p class="item-content">{{ model.phoneNumber }}</p>
      </div>
      <div class="item date">
        <h6 class="item-label">{{ info.creationDateTitle }}</h6>
        <p class="item-content">{{ model.creationDate || '' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import usePageResource from '@/composables/usePageResource';
import LinkBtn from '@/components/common/LinkBtn.vue';

export default {
  name: 'about-info',
  components: {
    LinkBtn,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    language: String,
  },
  data() {
    return {
      showView: false,
    };
  },
  computed: {
    info() {
      return this.pr.info;
    },
    manager() {
      return this.model.manager || {};
    },
    description() {
      const { description = [] } = this.model;
      return description.map((str) => this.toHtml(str));
    },
    address() {
      return this.model.address || {};
    },
    addressText() {
      const { text } = this.address;
      return this.toHtml(text);
    },
    addressLink() {
      return `https://uri.amap.com/marker?poiid=${this.address.poiid}`;
    },
  },
  setup() {
    const { pr } = usePageResource('about');
    const locale = inject('locale');
    return {
      pr,
      locale,
    };
  },
  mounted() {
    this.showView = true;
  },
  methods: {
    toHtml(string) {
      return (string || '').replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
  },
};
</script>

<style scoped lang="scss">
$border: 1px solid rgba($color: #000000, $alpha: 0.1);

.sp {
  @media screen and(min-width: 769px) {
    display: none;
  }
}

.pc {
  @media screen and(max-width: 768px) {
    display: none;
  }
}

.about-info {
  display: flex;
  margin: 80px auto 0;

  &-img {
    height: auto;
  }

  &-top, &-bottom {
    display: flex;
    padding: 0;
  }

  &-top {
    padding-right: 64px;
    align-items: flex-start;
    flex-grow: 1;
    border-right: $border;
  }

  $descLineHeight: 24px;

  &.zh {
    .about-info-top {
      @media screen and (min-width: 769px) {
        min-height: 400px;
      }
    }
  }

  &.ja {
    .about-info-top {
      $minHeight: 303px;
      $breakWidth: 769px, 779px, 823px, 836px, 920px, 1001px, 1114px;
      $length: length($breakWidth);
      @for $i from 0 to $length {
        $current: nth($breakWidth, $i + 1);
        @if $i == $length - 1 {
          @media screen and (min-width: $current) {
            min-height: $minHeight;
          }
        } @else {
          $next: nth($breakWidth, $i + 2);
          @media screen and (min-width: $current) and (max-width: ($next - 1)) {
            min-height: $descLineHeight * ($length - $i - 1) + $minHeight;
          }
        }
      }
    }
  }

  &-bottom {
    box-sizing: border-box;
    padding-left: 64px;
    flex-shrink: 0;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    color: #212121;

    .item {
      margin-top: 32px;

      &:nth-of-type(1) {
        margin-top: 0;
      }
    }

    @media screen and (min-width: 769px) {
      width: 368px;
    }
  }

  &-title {
    line-height: 33px;
    font-size: 24px;
  }

  &-subtitle {
    margin-top: 2px;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0;
    color: #A6AEB1;
    line-height: 22px;
  }

  &-manager {
    margin-top: 30px;
  }

  .item {
    &.address {
      .address {
        &-text {
          display: inline-block;
        }

        &-btn {
          margin-top: 24px;
        }
      }

      .item-content {
        letter-spacing: 0.6px;

        @media screen and (min-width: 769px) {
          min-height: 40px;
        }
      }
    }

    &-label {
      font-size: 16px;
      line-height: 22px;
    }

    &-content {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
    }

    .about-info-description {
      margin-top: 12px;
      font-size: 14px;
      line-height: $descLineHeight;
    }

    &-title {
      margin: 5px 0 36px;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
    }
  }
}

@media screen and (max-width: 768px) {
  .about-info {
    margin-top: 0;
    margin-bottom: 60px;
    flex-direction: column;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-top {
      padding: 40px 0;
      flex-direction: column;
      border-right: none;
      border-top: $border;

      .about-info-content {
        .about-info-name {
          margin-top: 37px;
          line-height: 30px;
        }

        .item-title {
          margin-top: 4px;
          margin-bottom: 24px;
          line-height: 30px;
        }
      }
    }

    &-title {
      font-size: 22px;
      line-height: 30px;
    }

    &-manager {
      margin-top: 24px;
    }

    &-bottom {
      padding: 24px 24px 40px;
      height: auto;
      background-color: #F1F3F4;

      .item {
        margin-top: 24px;
      }
    }

    &-img {
      $width: calc(100% + 44px);
      width: $width;
      height: auto;
    }

    &-name {
      margin-bottom: 25px;
      font-size: 22px;
    }

    .item {
      width: 100%;

      &.address {
        .item-content {
          margin: 10px 0 14px;
          line-height: 22px;
        }

        .address-btn {
          margin-top: 16px;

          @media screen and (max-width: 440px) {
            text-align: center;
          }
        }
      }

      &-label {
        font-size: 16px;
      }

      &-title {
        margin-top: 5px;
        font-size: 22px;
      }

      &-content {
        margin-top: 10px;
        font-size: 14px;
        color: #212121;
      }

      .about-info-description {
        font-size: 14px;
        line-height: 24px;
      }

      &.address-map {
        width: 100%;
        height: 111px;
      }
    }
  }
}
</style>
