<template>
  <div class="body-third-section">
    <p :class="[isSP ? 'text-sp' : 'text-pc']">{{ work.desTitle3 }}</p>
    <div
      v-for="(item, index) in work.desContent3"
      :key="index+'_key'"
      :class="['body-third-section-item', { ja: !isZH}]"
    >
      <div class="title">
        <div class="title-black">{{ item.text1 }}</div>
        <span class="title-text">{{ item.text2 }}</span>
      </div>
      <p v-viewportChanged="showView" :class="['hidden-element',isSP ? 'description-sp' : 'description-pc']">
        {{ item.description }}
      </p>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import useRenderData from '@/composables/useRenderData';
import useIsSP from '@/composables/useIsSP';
import { LOCALES } from '@/constants/constants';

export default {
  props: {
    work: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { getImageUrl } = useRenderData('network-security');
    const { isSP } = useIsSP();
    const locale = inject('locale');
    return {
      getImageUrl,
      isSP,
      locale,
    };
  },
  computed: {
    isZH() {
      return this.locale === LOCALES.ZH;
    },
  },
};
</script>

<style lang="scss" scoped>
  .body-third-section{
    .title{
      display: flex;
      justify-content: flex-start;
    }
    .body-third-section-item{
      margin-top: 28px;
    }
  }
  .title-black{
    min-width: 58px;
    height: 24px;
    background-color: #000;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.37px;
    line-height: 24px;
    text-align: center;
    margin-right: 10px;
  }
  .title-text{
    display: inline-block;
    font-size: 20px;
    color: #222222;
    letter-spacing: 0.53px;
    font-weight: 600;
    line-height: 28px;
  }
  .text-pc{
    font-size: 23px;
    color: #222222;
    letter-spacing: 0.61px;
    font-weight: 600;
  }
  .description-pc{
    font-size: 16px;
    color: #222222;
    letter-spacing: 0.43px;
    line-height: 28px;
    font-weight: 400;
    margin-top: 12px;
  }
  .text-sp{
    font-size: 19px;
    color: #222222;
    letter-spacing: 0.51px;
    line-height: 26px;
    font-weight: 600;
  }
  .description-sp{
    font-size: 14px;
    color: #222222;
    letter-spacing: 0.37px;
    line-height: 28px;
    margin-top: 12px;
  }
  .ja .description-sp{
    font-weight: 300;
  }
  @media only screen and(max-width: 600px) {
    .title-text{
      font-size: 16px;
      line-height: 20px;
    }
  }
</style>
