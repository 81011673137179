import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import Layout from '@/layout/Layout.vue';
import Home from '@/views/Home.vue';
import WorkDetail from '@/views/WorkDetail.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import ContactUs from '@/views/ContactUs.vue';
import ThanksContact from '@/views/ThanksContact.vue';
import PageResources from '@/model/index';
import NewsDetail from '@/views/NewsDetail.vue';
import NewsList from '@/views/NewsList.vue';
import WorksPage from '@/views/WorksPage.vue';
import { browserLocale } from '@/composables/useLocale';

const getMeta = (route) => {
  const { lang, id } = route.params;
  const key = lang || browserLocale();
  const pr = PageResources[key];
  let meta = {};
  switch (route.name) {
    case 'privacy-policy':
      meta = pr.meta[route.name];
      break;
    case 'work-detail':
      meta = pr.meta[route.name][id];
      break;
    default:
      meta = pr.meta.top;
      break;
  }
  return meta;
};

const routes = [
  {
    path: '',
    name: 'home',
    component: Home,
  },
  {
    path: 'work-detail/:id',
    name: 'work-detail',
    component: WorkDetail,
  },
  {
    path: 'privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: 'contact-us',
    name: 'contact-us',
    component: ContactUs,
  },
  {
    path: 'thanks-contact',
    name: 'thanks-contact',
    component: ThanksContact,
  },
  {
    path: 'news-detail/:id',
    name: 'news-detail',
    component: NewsDetail,
  },
  {
    path: 'news',
    name: 'news',
    component: NewsList,
  },
  {
    path: 'works',
    name: 'works',
    component: WorksPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
  routes: [
    {
      path: '/:lang(zh|ja)?',
      component: Layout,
      children: routes,
      meta: {
        get: getMeta,
      },
    },
    {
      path: '/:pathMatch(.*)',
      redirect: { name: 'home' },
    },
  ],
});

router.beforeEach((to) => {
  const lang = to.params.lang || browserLocale();
  document.documentElement.lang = lang === 'ja' ? 'ja' : 'zh-CN';
});

router.afterEach((to) => {
  nextTick(() => {
    const meta = to.meta.get(to);
    document.title = meta.title;
    $('meta[name=description]').remove();
    $(`<meta name="description" content="${meta.description}">`).insertAfter('meta[name=viewport]');
  });
});

export default router;
