<template>
  <section class="recruit-top">
    <div class="recruit-top-content">
      <h3 v-viewportChanged="showView" class="hidden-element recruit-top-title">{{ pr.title }}</h3>
      <h6 v-viewportChanged="showView" class="hidden-element recruit-top-subtitle" v-html="title">
      </h6>
      <BreakText
        v-for="(item, index) in pr.description"
        :key="index"
        :content="item"
        v-viewportChanged="showView"
        class="hidden-element recruit-top-description pc" />
      <p v-viewportChanged="showView" class="hidden-element recruit-top-description sp" v-html="descriptionMobile"></p>
    </div>
  </section>
</template>

<script>
import usePageResource from '@/composables/usePageResource';
import BreakText from '@/components/common/BreakText.vue';

export default {
  name: 'recruit-top',
  setup() {
    const { pr } = usePageResource('recruit');
    return {
      pr,
    };
  },
  components: {
    BreakText,
  },
  computed: {
    title() {
      return this.pr.subtitle.replace(/\n/gm, '<br/>');
    },
    descriptionMobile() {
      return this.pr.descriptionMobile.replace(/\n/gm, '<br/>');
    },
  },
};
</script>

<style scoped lang="scss">
.sp {
  @media screen and (min-width: 768px + 1) {
    display: none;
  }
}

.pc {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.recruit-top {
  position: relative;
  color: #fff;

  &-content {
    padding: 0 17px 68px 24px;
    text-align: center;
    overflow: auto;
  }

  &-title {
    margin-top: 72px;
    font-weight: bold;
    font-size: 30px;
    line-height: 42px;
  }

  &-subtitle {
    margin-top: 31px;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
  }

  &-description {
    font-size: 14px;
    line-height: 24px;
  }

  @media only screen and (max-width: 768px) {
    &-content {
      padding-bottom: 40px;
    }

    &-title {
      margin-top: 56px;
      font-size: 22px;
      line-height: 30px;
    }

    &-subtitle {
      margin: 29px -5px 15px;
      font-size: 18px;
      line-height: 30px;
    }

    &-description {
      font-size: 15px;
      line-height: 30px;
      text-align: left;
      letter-spacing: 0.4px;
    }
  }
}
</style>
