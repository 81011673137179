<template>
  <Mask v-show="isShowPopup">
    <div :class="['leaveMenu', { messageMenu: !isMessageFail }, { ja: !isZH }]">
      <p class="title pc" v-html="popupTitlePC"></p>
      <p class="title sp" v-html="popupTitleSP"></p>
      <div>
        <button v-if="isMessageFail" class="cancel popupBtn" @click.prevent="popupBtnClick(false)">
          {{ cancelText }}
        </button>
        <button class="confirm popupBtn" @click.prevent="popupBtnClick(true)">
          {{ confirmText }}
        </button>
      </div>
    </div>
  </Mask>
</template>

<script>
import { inject } from 'vue';
import { LOCALES } from '@/constants/constants';
import Mask from '@/components/mask/Mask.vue';

export default {
  components: {
    Mask,
  },
  props: {
    title: String,
    cancelText: String,
    confirmText: String,
  },
  setup() {
    const locale = inject('locale');
    return {
      locale,
    };
  },
  data() {
    return {
      isShowPopup: false,
    };
  },
  computed: {
    isZH() {
      return this.locale === LOCALES.ZH;
    },
    isMessageFail() {
      return this.cancelText !== undefined;
    },
    popupTitlePC() {
      return this.title.replace(/\n/, '<br/>').replace(/\n/, '');
    },
    popupTitleSP() {
      return this.title.replace(/\n/gm, '<br/>');
    },
  },
  methods: {
    open(cb) {
      this.isShowPopup = true;
      this.onClose = cb;
    },
    close(res) {
      this.isShowPopup = false;
      if (typeof this.onClose === 'function') {
        this.onClose(res);
      }
    },
    popupBtnClick(clickFlag) {
      this.close(clickFlag);
    },
  },
};
</script>

<style scoped lang="scss">
.sp {
  @media screen and(min-width: 768px + 1) {
    display: none;
  }
}

.pc {
  @media screen and(max-width: 768px) {
    display: none;
  }
}

.leaveMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 400px;
  height: 160px;
  padding: 24px 32px;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;

  &.messageMenu {
    height: 137px;
  }

  .title {
    width: 100%;
    line-height: 24px;
    color: rgba($color: #000000, $alpha: 0.65);
    font-size: 16px;
  }

  .popupBtn {
    width: 86px;
    height: 32px;
    border-radius: 4px;
    font-size: 14px;
    letter-spacing: 1.6px;
    outline: none;
    border: none;
    cursor: pointer;
    letter-spacing: 0;
  }

  .cancel {
    background-color: #ffffff;
    color: #212121;
    border: 1px solid #a6aeb1;
  }

  .confirm {
    background-color: #212121;
    color: #ffffff;
    margin-left: 12px;
  }

  @media screen and (max-width: 768px) {
    width: 280px;
    height: 148px;
    padding: 24px;
    align-items: center;

    &.messageMenu {
      height: 126px;

      .popupBtn {
        width: 120px;
        margin-left: 0;
      }

      &.ja {
        .title {
          line-height: 24px;
        }

        .popupBtn {
          width: 120px;
          margin-left: 0;
        }
      }
    }

    .title {
      text-align: center;
      font-size: 14px;
      line-height: 22px;
    }

    .popupBtn {
      width: 106px;
    }

    .confirm {
      margin-left: 20px;
    }
  }

  &.ja {
    height: 160px;
    .popupBtn {
      width: 106px;
    }

    @media screen and (max-width: 768px) {
      height: 176px;
    }
  }
}
</style>
