import PrivacyPolicyZH from './privacy-policy/privacy-policy_zh';
import PrivacyPolicyJA from './privacy-policy/privacy-policy_ja';
import SplashZH from './splash/splash_zh';
import SplashJA from './splash/splash_ja';
import WorksZH from './works/works_zh.json';
import WorksJA from './works/works_ja.json';
import AboutZH from './about/about_zh';
import AboutJA from './about/about_ja';
import RecruitZH from './recruit/recruit_zh';
import RecruitJA from './recruit/recruit_ja';
import ServicesZH from './services/services_zh';
import ServicesJA from './services/services_ja';
import NavZH from './nav/nav_zh';
import NavJA from './nav/nav_ja';
import ContactUsZH from './contact-us/contact-us_zh';
import ContactUsJA from './contact-us/contact-us_ja';
import ThanksContactZH from './thanks-contact/thanks-contact_zh';
import ThanksContactJA from './thanks-contact/thanks-contact_ja';
import MetaZH from './meta/meta_zh.json';
import MetaJA from './meta/meta_ja.json';
import NewsZH from './news/news_zh';
import NewsJA from './news/news_ja';

export default {
  zh: {
    'privacy-policy': PrivacyPolicyZH,
    splash: SplashZH,
    services: ServicesZH,
    works: WorksZH,
    recruit: RecruitZH,
    about: AboutZH,
    nav: NavZH,
    'contact-us': ContactUsZH,
    'thanks-contact': ThanksContactZH,
    meta: MetaZH,
    news: NewsZH,
  },
  ja: {
    'privacy-policy': PrivacyPolicyJA,
    splash: SplashJA,
    services: ServicesJA,
    works: WorksJA,
    recruit: RecruitJA,
    about: AboutJA,
    nav: NavJA,
    'contact-us': ContactUsJA,
    'thanks-contact': ThanksContactJA,
    meta: MetaJA,
    news: NewsJA,
  },
};
