<template>
  <div class="container home">
    <Splash id="splash" />
    <Services id="services" />
    <Works id="work-cards" />
    <HomeAbout id="home-about" />
    <Recruit id="recruit" />
    <News id="news" />
  </div>
</template>

<script>
import Splash from '@/components/Splash.vue';
import Services from '@/components/services/Services.vue';
import Works from '@/components/works/Works.vue';
import Recruit from '@/components/recruit/Index.vue';
import HomeAbout from '@/components/about/Index.vue';
import News from '@/components/news/News.vue';

export default {
  name: 'home',
  components: {
    Splash,
    Services,
    Works,
    Recruit,
    HomeAbout,
    News,
  },
  data() {
    return {};
  },
  methods: {
    disableScroll() {
      this.body.addClass('disable-scroll');
    },
    enableScroll() {
      this.body.removeClass('disable-scroll');
    },
  },
};
</script>

<style>
</style>
