<template>
  <p v-if="Array.isArray(content)">
    <span
      :class="['text-line', isSpanBlock && 'span-block', isWrap ? 'wrap' : 'noWrap']"
      v-for="(text, index) in content"
      :key="index"
    >
      {{text}}
    </span>
  </p>
  <p v-else :title="title">{{content}}</p>
</template>

<script>
export default {
  props: {
    content: {
      type: [String, Array],
      default: '',
    },
    isWrap: {
      type: Boolean,
      default: true,
    },
    isSpanBlock: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [String, Array],
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.text-line {
  display: inline-block;
}
.span-block {
  display: block;
}
.wrap {
  white-space: pre-wrap;
}
.noWrap {
  white-space: nowrap;
}
</style>
