<template>
  <div class="works-wrap">
    <div class="works-content">
      <div :class="['works-header', { ja: !isZH}]">
        <span class="animation-text title">{{ pr.title }}</span>
        <break-text
          class="animation-text description"
          :content="pr.description1"
        />
        <break-text
          class="animation-text description"
          :content="pr.description2"
        />
      </div>
      <div v-if="isSP" class="works-slides-sp">
        <template v-for="(work, index) in showWorksInPage" :key="work.id">
          <work-card
            :work="work"
            :detailLabel="pr.detailLabel"
            :isHasAnimation="index < 1 ? true : false"
          />
        </template>
      </div>
      <div v-else class="works-slides">
        <template v-for="(work, index) in showWorksInPage" :key="work.id">
          <div class="work-card-wrap">
            <work-card
              :work="work"
              :detailLabel="pr.detailLabel"
              :isHasAnimation="index <= 1 ? true : false"
            />
          </div>
        </template>
      </div>
      <Pagination
        v-if="isShowPagination"
        :total="total"
        v-model:pageNum="pageNum"
        :page-size="pageSize"
        :is-clicked-top="false"
        :styleCss="'margin: 0 24px 100px;width: 100%;'"
        @click="()=>{scrollTo()}"
        />
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import usePageResource from '@/composables/usePageResource';
import WorkCard from '@/components/works/WorkCard.vue';
import Pagination from '@/components/common/Pagination.vue';
import BreakText from '@/components/common/BreakText.vue';
import { LOCALES } from '@/constants/constants';
import useIsSP from '@/composables/useIsSP';

export default {
  components: {
    WorkCard,
    Pagination,
    BreakText,
  },
  data() {
    return {
      pageNum: Number(this.$route.query.pageNum) || 1,
      pageSize: 10,
    };
  },
  setup() {
    // const isMobile = /mobi|tablet|ipad|iphone|android/.test(navigator.userAgent.toLowerCase());
    const { pr } = usePageResource('works');
    const locale = inject('locale');
    const moveTo = inject('moveTo');
    const { isSP } = useIsSP();
    return {
      // isMobile,
      moveTo,
      pr,
      locale,
      isSP,
    };
  },
  methods: {
    scrollTo() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    isZH() {
      return this.locale === LOCALES.ZH;
    },
    showWorksInPage() {
      let arr = this.pr.works;
      if (this.isSP) arr = [...arr.filter((work) => work?.isShowInListPage?.sp ?? true)];
      if (!this.isSP) arr = [...arr.filter((work) => work?.isShowInListPage?.pc ?? true)];
      return arr
        .filter((_, index) => {
          const leftNub = this.pageSize * this.pageNum - this.pageSize;
          const rigthNub = this.pageSize * this.pageNum - 1;
          return index >= leftNub && index <= rigthNub;
        });
    },
    total() {
      return this.pr.works.filter((work) => work?.visible?.pc ?? true).length || 0;
    },
    isShowPagination() {
      let arr = this.pr.works;
      if (this.isSP) arr = [...arr.filter((work) => work?.isShowInListPage?.sp ?? true)];
      if (!this.isSP) arr = [...arr.filter((work) => work?.isShowInListPage?.pc ?? true)];
      return (arr.length > 10);
    },
  },
  beforeRouteEnter(to, from, next) {
    const { name } = from;
    if (name !== 'work-detail') {
      next();
      return;
    }
    next((vm) => {
      const storageNum = window.sessionStorage.getItem('worksPageNum');
      // eslint-disable-next-line no-param-reassign
      if (storageNum) vm.pageNum = Number(storageNum);
    });
  },
  beforeRouteLeave() {
    window.sessionStorage.setItem('worksPageNum', this.pageNum);
  },
};
</script>

<style lang="scss" scoped>
.works-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 146px 24px 0px 24px;
  background: #FFFFFF;
  color: #222222;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

  .works-content {
    width: 100%;
    max-width: 1260px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .works-header {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        margin-bottom: 32px;
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
      }

      .description {
        padding: 0 15px;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.43px;

        @media only screen and (max-width: 374px) {
          padding: 0;
          margin: 0 -10px;
        }
      }
    }

    .works-slides {
      width: 100%;
      margin-bottom: 78px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .work-card-wrap {
        width: 50%;
        box-sizing: border-box;
        padding: 22px 30px;
      }
    }

    .works-slides-sp{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 68px;
      &>div{
        margin-bottom: 32px;
        padding: 0;
      }
    }

    .learn-more {
      margin-bottom: 42px;
      font-size: 16px;
      text-align: center;
      line-height: 22px;
    }
  }
}

@media only screen and (max-width: 1138px) {
  .work-card-wrap {
    flex: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .works-wrap {
    padding-top: 112px;
    .works-content {
      .works-header {
        margin-bottom: 32px;

        .title {
          margin-bottom: 34px;
          font-size: 22px;
        }

        .description {
          margin: 0 -10px;
          font-size: 15px;
          line-height: 30px;
          letter-spacing: -0.32px;
        }

        &.ja {
          margin-bottom: 40px;

          .title {
            line-height: 22px;
            margin-bottom: 30px;
          }
        }
      }

      .works-slides {
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        .work-card-wrap {
          flex: 100%;
          padding: 0;

          &:not(last-child) {
            margin-bottom: 24px;
          }
        }
      }

      .learn-more {
        margin-bottom: 32px;
        max-width: 236px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.text-line {
  display: inline-block;
  white-space: nowrap;
}
</style>
