<template>
  <div class="container">
    <router-view/>
  </div>
</template>
<script>
import DeviceUtil from './utils/DeviceUtil';

export default {
  name: 'app',
  data() {
    return {
      checkVer: false,
    };
  },
  provide() {
    return { moveTo: this.moveTo, isiOS: DeviceUtil.isiOS() };
  },
  beforeMount() {
    // const oldVer = localStorage.version;
    // const newVer = process.env.GIT_VERSION;
    // if (oldVer === 'undefined' || oldVer === newVer) {
    //   this.checkVer = true;
    //   return;
    // }
    // localStorage.version = newVer;
    // window.location.reload();
  },
  methods: {
    moveTo(name) {
      if (this.$route.name === name) {
        this.$router.go();
      } else {
        this.$router.push({ name, params: { lang: this.$route.params.lang } });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/reset.scss";
@import "@/styles/common.scss";

html,
body,
.container {
  min-height: 100vh;
}

body {
  -webkit-text-size-adjust: 100%;
}
</style>
