import { createApp } from 'vue';
import jQuery from 'jquery';
import App from './App.vue';
import router from './router';
import mixin from './utils/mixins';
import bus from './utils/EventBus';

window.$ = jQuery;

const app = createApp(App);
app.config.globalProperties.$bus = bus;
app
  .use(router)
  .mixin(mixin)
  .directive('viewportChanged', {
    beforeMount(el, binding) {
      if (binding.arg === false) return;
      const f = (evt) => {
        if (binding.value(evt, el)) {
          window.removeEventListener('scroll', f);
          window.removeEventListener('resize', f);
          window.removeEventListener('load', f);
        }
      };
      window.addEventListener('scroll', f);
      window.addEventListener('resize', f);
      window.addEventListener('load', f);
    },
  })
  .mount('#app');
