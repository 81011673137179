<template>
  <component
    :is="tag"
    :class="['btn with-hover']"
    v-bind="$attrs"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'link-btn',
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: 'a',
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  box-sizing: border-box;
  display: inline-block;
  padding: 9px;
  line-height: 20px;
  text-align: center;
  color: #222;
  border: 1px solid #A6AEB1;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  letter-spacing: 1.6px;
  font-size: 14px;
  text-decoration: none;
}
</style>
