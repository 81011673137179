<template>
  <div v-if="!isSP" class="body-first-section-pc">
    <p v-viewportChanged="showView" class="hidden-element text-pc">{{ work.desTitle1 }}</p>
    <p v-viewportChanged="showView" class="hidden-element description-pc">
     {{ work.desContent1[0] }}
    </p>
    <p class="description-pc">{{ work.desContent1[1] }}</p>
    <div class="image-box-pc">
      <img alt='' :src="getImageUrl(work.screenShot2[0])" />
      <div>
        <img style="width:100% ;" alt='' :src="getImageUrl(work.screenShot2[1])" />
        <img style="width:100% ;" alt='' :src="getImageUrl(work.screenShot2[2])" />
      </div>
    </div>
  </div>
  <div v-else class="body-first-section-sp">
    <p v-viewportChanged="showView" class="hidden-element text-sp">{{ work.desTitle1 }}</p>
    <p v-viewportChanged="showView" class="hidden-element description-sp">
     {{ work.desContent1[0] }}
    </p>
    <p v-viewportChanged="showView" class="hidden-element description-sp">{{ work.desContent1[1] }}</p>
    <div class="image-box-sp">
      <img alt='' :src="getImageUrl(work.screenShot2[0])" />
      <div>
        <img alt='' :src="getImageUrl(work.screenShot2[1])" />
        <img alt='' :src="getImageUrl(work.screenShot2[2])" />
      </div>
    </div>
  </div>
</template>

<script>
import useRenderData from '@/composables/useRenderData';
import useIsSP from '@/composables/useIsSP';

export default {
  props: {
    work: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { getImageUrl } = useRenderData('network-security');
    const { isSP } = useIsSP();
    return {
      getImageUrl,
      isSP,
    };
  },
};
</script>

<style lang="scss" scoped>
  .body-first-section-pc{
    margin-top: 96px;
    .image-box-pc{
      width: 100%;
      box-sizing: border-box;
      padding: 50px 50px 90px 100px;
      display: flex;
      justify-content: space-between;
      &>img{
        width: 40%;
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.10);
      }
      div{
        box-sizing: border-box;
        padding-left: 68px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  .body-first-section-sp{
    margin-top: 52px;
    padding-bottom: 60px;
    .image-box-sp{
      margin-top: 40px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &>img{
        max-width: 175px;
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.10);
      }
      div{
        display: flex;
        flex-direction: column;
        align-items: center;
        &>img{
          max-width: 297px;
          margin-top: 40px;
        }
      }
    }
  }
  .text-pc{
    font-size: 23px;
    color: #222222;
    letter-spacing: 0.61px;
    font-weight: 600;
  }
  .description-pc{
    font-size: 16px;
    color: #222222;
    letter-spacing: 0.43px;
    line-height: 28px;
    font-weight: 400;
    margin-top: 26px;
  }
  .text-sp{
    font-size: 19px;
    color: #222222;
    letter-spacing: 0.51px;
    line-height: 26px;
    font-weight: 600;
  }
  .description-sp{
    font-size: 14px;
    color: #222222;
    letter-spacing: 0.37px;
    line-height: 28px;
    margin-top: 24px;
  }
</style>
