<template>
  <div v-if="isReady" :class="['layout', this.lang]">
    <div :class="['header-bg', { active: showMaskLayer }]"></div>
    <Navigation @toggle="toggleNav" ref="header"/>
    <router-view />
    <TheFooter/>
  </div>
</template>
<script>
import { readonly, watch } from 'vue';
import { DefaultLang } from '@/constants/constants';
import Navigation from '@/components/Navigation.vue';
import TheFooter from '@/components/TheFooter.vue';
import useLocale, { browserLocale } from '@/composables/useLocale';
import usePageResources from '@/composables/usePageResources';

export default {
  name: 'layout',
  components: {
    Navigation,
    TheFooter,
  },
  // inject: ['locale'],
  computed: {
    lang() {
      return this.$route.params.lang || browserLocale();
    },
  },
  data() {
    return {
      showMaskLayer: false,
      isReady: false,
    };
  },
  provide() {
    const { locale, updateLocale } = useLocale(this.lang);
    const { pageResources } = usePageResources(locale, this.lang);

    watch(locale, (val) => {
      this.$router.push({ params: { lang: val, targetId: undefined } });
    });

    return {
      locale: readonly(locale), updateLocale, pageResources: readonly(pageResources),
    };
  },
  beforeRouteEnter(to, from, next) {
    const lang = browserLocale();
    if (!to.params.lang && lang !== DefaultLang) {
      next({ ...to, params: { ...to.params, lang } });
    } else {
      next();
    }
  },
  mounted() {
    const { lang } = this.$route.params;
    if (lang || browserLocale() === DefaultLang) {
      this.isReady = true;
    }
  },
  methods: {
    toggleNav(val) {
      this.showMaskLayer = val;
    },
  },
};
</script>
<style lang="scss">
.layout {
  position: relative;

  &.ja {
    font-family:
      "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Hira Kaku Pro", "Yu Gothic Medium", "Yu Gothic", sans-serif;
  }

  &.zh {
    font-family: -apple-system, BlinkMacSystemFont, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", sans-serif;
  }
}

.header-bg {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  pointer-events: none;
  background-color: #464849;
  mix-blend-mode: multiply;
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0.02, 0.01, 0.47, 1);

  &.active {
    opacity: 0.95;
    pointer-events: auto;
    touch-action: none;
  }
}

.container {
  /* this leaves space for the sticky footer */
  min-height: calc(100vh - 362px);
}
</style>
