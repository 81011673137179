/* eslint-disable max-len */
export default {
  title: '隐私政策',
  read: '本隐私政策记载了成都市飞狼科技有限公司（以下简称“本公司”）如何收集和使用个人信息。本隐私政策适用于本公司企业网站、本公司运营的相关网页、服务或直接或间接联系的所有用户。但是，在相关网页和服务中另行规定了隐私政策的情况下，不在此限。\n'
      + '通过其他公司的服务与本公司取得联系的情况下，收到的信息将按照本隐私政策进行管理，但关于其他公司的服务，则适用于用户另外与其他公司之间的商定。',
  list: [
    {
      id: 'list_1',
      title: '个人信息等的收集、使用目的、使用依据',
      desc: '本公司请用户提供以下信息（以下称“个人信息等”）。用户所提供的个人信息等，仅在用户提供时被告知的使用目的以及以下所示使用目的范围内使用，未经用户事前同意，不会用于其他目的。此外，当不需要使用个人信息等时，个人信息会被及时删除。',
    },
    {
      id: 'list_2',
      title: '用户的权利',
      desc: '用户具有以下权利。 有关行使这些权利、意见、问题、投诉和处理用户信息的查询，请使用下面的“<a href="/zh/contact-us"  style="color: #303233">联系我们</a>”。',
    },
    {
      id: 'list_3',
      title: '个人信息等的恰当管理和保护',
      desc: '本公司根据法律对用户的个人信息等进行适当的管理和保护，导入了防止不恰当的消失、被盗、不正当使用、泄露、损毁、篡改的对策和信息安全管理体制。',
    },
    {
      id: 'list_4',
      title: '向第三方提供、公开',
      desc: '本公司除以下情况外，不会向第三方提供或公开用户的个人信息。',
    },
    {
      id: 'list_5',
      title: '第三方相关的免责事项',
      desc: '本公司在以下情况下不承担第三方取得个人信息的责任。',
    },
    {
      title: '委托方的管理',
      desc: '本公司在实施使用目的所需的范围内，有将个人信息等的处理委托给外部的情况。此时，选择适当处理个人信息等的委托方。另外，对委托方，限定在实施使用目的所需要的范围内，提供或公开用户的个人信息等，同时在与委托方签订保密合同的基础上，要求妥善处理个人信息等，并定期对其妥善处理进行确认和修改。',
    },
    {
      id: 'list_7',
      title: '本公司的联系方式',
      desc: '关于个人信息的使用方法如果有疑问或者不清楚的地方，请随时咨询。联系方式如下：',
    },
    {
      title: '准据法',
      desc: '本隐私政策依据中华人民共和国的法律解释。',
    },
    {
      title: '个人信息等处理的修改及改善',
      desc: '本公司对个人信息等的处理随时进行评价，致力于持续的改善。为此，有可能变更本隐私政策。有大幅度的变更的情况下，会用用户易懂的方法告知该内容。',
    },
    {
      title: '信息保留期限',
      desc: '本公司为了不收集无关的个人信息，采取合理可行的一切措施。本公司仅在达到本隐私政策中记载的目的所需的期间内，保存用户的个人信息等（为提供商品或服务需要延长保留期间或法律许可的情况除外）。如果超过法定或同意的保存期限，本公司将根据法律对用户的个人信息等进行删除或匿名化。',
    },
    {
      title: '个人信息等的跨越国境传送',
      desc: '当本公司将用户提供的个人信息等提供给日本的 Fenrir Inc. 及其他海外的本公司集团企业时，本公司为了提高本公司对用户的服务质量，在该个人信息等跨国界的发送过程中，遵守相关法规的规定，在跨越国境传送之前，根据法律执行数据的出境安全评估，尽可能地进行匿名化以保护个人信息等安全。',
    },
  ],
  subList: {
    list_1: [
      {
        title: '收集的信息',
        desc: '收集的具体信息内容，参照以下例子。',
        content: [
          {
            title: '访问信息',
            content: [
              { title: 'IP地址' },
              { title: '设备信息（设备和应用的识别号、因特网服务提供商、移动运营商）' },
              { title: '浏览器信息' },
              { title: 'WEB阅览状况（访问地的URL、检索信息）、邮件的点击状况' },
              { title: '操作系统、系统设置信息' },
              { title: '时间戳信息' },
              { title: 'Cookie信息' },
            ],
          },
          {
            title: '案件的委托、各种咨询',
            content: [
              { title: '咨询内容' },
              { title: '公司名称、部门名称' },
              { title: '地址' },
              { title: '姓名' },
              { title: '联系方式（邮件地址、电话号码）' },
            ],
          },
          {
            title: '聘用相关信息',
            content: [
              { title: '个人属性信息（住址、姓名、出生年月日等）' },
              { title: '学历、工作经历等' },
            ],
          },
        ],
      },
      {
        title: '收集信息的使用目的',
        content: [
          {
            title: '宣传及营销活动',
            content: [
              { title: '本公司合作服务的使用手续' },
              { title: '本公司产品、服务、研讨会的介绍' },
              { title: '商品、赠品的发送、提供' },
              { title: '问卷等的调查配合委托' },
              { title: '提供的问卷等内容的调查、分析' },
              { title: '服务使用情况的调查、分析' },
            ],
          },
          {
            title: '咨询应对',
            content: [{ title: '应对各种咨询' }],
          },
          {
            title: '聘用活动',
            content: [
              { title: '向应聘者提供发送聘用信息等' },
              { title: '本公司的聘用业务管理' },
            ],
          },
          {
            title: '履行法规上的手续',
            content: [
              { title: '本公司或用户关于本公司服务使用的请求、纠纷的调查、解决。' },
              { title: '法规上认可的情况以及向国家机关或地方公共团体提供。' },
            ],
          },
        ],
      },
      {
        title: '收集信息的使用依据',
        desc: '我们将基于以下一种或多种理由使用个人信息等。',
        content: [
          { title: '同意', content: [{ title: '本公司根据用户的同意，收集用户的个人信息等。用户有权随时撤回上述同意。' }] },
          { title: '对用户要求的对应', content: [{ title: '本公司为了履行合同需要处理时，为了应对签约前的用户的要求而进行手续实践需要处理时，会收集用户的个人信息等。应对用户的要求，包括对用户咨询的应对和聘用活动。' }] },
          { title: '遵守法令上的义务', content: [{ title: '本公司为了遵守本公司应遵守的法律义务，收集用户的个人信息等。' }] },
          { title: '保护用户或第三方的重要利益', content: [{ title: '本公司为了保护用户或第三方的重要利益，收集用户的个人信息等。用户的重要利益包括：省略用户重复输入的麻烦，显示对用户来说是最佳的内容，显示用户感兴趣的广告。' }] },
          { title: '保护公共利益', content: [{ title: '本公司在保护公共利益所需的范围内收集用户的个人信息等。' }] },
          { title: '追求本公司或第三方的正当利益', content: [{ title: '本公司为了追求本公司或第三方的正当利益，收集用户的个人信息等。本公司的正当利益包括防止和检测出针对本公司服务的不正当行为并进行对应；提供客户支持；为提高本公司的服务或提高安全性而进行调查、分析；以及直接进行营销和进行聘用活动。' }] },
        ],
      },
      {
        title: '关于Cookie等的利用',
        desc: '本公司在本公司运营的主页及相关网页上，有时会使用Cookie。Cookie是通过互联网浏览器保存在计算机系统中的文本文件。利用Cookie的话，利用网页的时候在浏览器和服务器之间发送和接收的使用历史和输入内容等，可以保存在用户的电脑里。通过使用Cookie，可以优化本公司网站上的信息和广告。\n'
            + '用户可以根据使用的互联网浏览器的设定，通过本公司的网站将Cookie的设定设为无效。',
      },
      {
        title: '关于解析工具的使用',
        desc: '本公司的网站为了营销活动导入了第三方企业的服务。详情请确认以下第三方企业的隐私政策。',
        content:
          [{ content: [{ title: '百度统计\n<a href="https://tongji.baidu.com/" target="_black" style="color: #161616">https://tongji.baidu.com/</a>' }], padding: true, dot: true }],
      },
      {
        title: '关于reCAPTCHA的使用',
        desc: '为提升本网站的安全性，我们使用了“reCAPTCHA”。\n'
          + 'reCAPTCHA适用Google的隐私政策和条款。\n'
          + 'reCAPTCHA API收集的硬件和软件信息（例如设备或应用程序数据等），将发送到Google进行分析，以判断是否存在恶意机器人行为，以上收集的信息将用于提升Google服务及其安全性，并不会用于Google广告。',
        content:
          [
            { content: [{ title: 'Google隐私权政策\n<div><a href="https://policies.google.com/privacy?hl=zh-CN" target="_black" style="color: #161616">https://policies.google.com/privacy?hl=zh-CN</a></div>\n' }], padding: true, dot: true },
            { content: [{ title: 'Google服务条款\n<a href="https://policies.google.com/terms?hl=zh-CN" target="_black" style="color: #161616">https://policies.google.com/terms?hl=zh-CN</a>' }], padding: true, dot: true },
          ],
      },
    ],
    list_2: [
      { title: '信息权、访问权', desc: '用户具有请求对有关本公司所持有的个人信息以及该信息的使用方法进行说明的权利。另外，如有用户请求访问正在处理的个人信息等，本公司将提供复印件。' },
      { title: '修改权', desc: '用户有权修改用户不正确的个人信息等，在考虑该信息等处理目的的基础上使该信息等成为完整的信息。' },
      { title: '删除权', desc: '用户在一定的情况下，有权要求删除与用户相关的个人信息等。' },
      { title: '处理限制权', desc: '用户在一定的情况下，有权限制与用户相关的个人信息等处理。' },
      { title: '可移植性权', desc: '对于经同意或为了对应用户的请求而取得的个人信息等，用户具有构造化、一般使用、以机器可读的形式获得的权利，或者具有从获得了用户个人信息等的提供的管理者不被妨碍地将该个人信息等从本公司转移到其他管理者的权利。具体而言，是具有在用户转移到与本公司提供的服务不同的其他公司提供的服务时，对于用户向本公司提供的个人数据群，以CSV等文本文件数据、JSON等数据记述语言的水平，获取可再利用的个人数据的提供的权利。' },
      { title: '异议权', desc: '用户有权对保护公共利益或为追求本公司或第三方的正当利益而取得的个人信息等的处理（包括配置）提出异议。' },
      { title: '不接受包括配置在内的自动化决策的权利', desc: '用户有权不受完全自动化决策的约束，包括: (1) 对用户产生法律影响; (2) 对用户产生类似的重大影响。另外，目前本公司鉴于广告的内容·投放方法、对用户造成的影响等，未进行侵入度较高的广告策划，未做出包括 (1) 产生法律效力或 (2) 产生同样重大影响的概要分析在内的完全自动化的决定。' },
      { title: '撤回同意的权利', desc: '用户有权对以同意为依据取得的个人信息等，随时撤回自己的同意。但是，对该同意撤回前依据该同意进行的处理的合法性没有影响。' },
    ],
    list_4: [
      { title: '征得用户本人同意公开个人信息等时' },
      { title: '为达成使用目的所需的范围内向业务委托方等提供或公开时' },
      { title: '为达到使用目的所需的范围内，在本集团企业之间共同使用时' },
      {
        title: '公司可以在公司指定的使用目的范围内在集团公司之间共享用户的个人信息，以确保集团公司之间的业务顺利进行。 在这种情况下，我们将负责个人信息的安全管理。',
        content: [
          {
            title: '(a)可能共同使用的个人信息项目',
            content: [
              { title: '①用户的住址、姓名、出生年月日等\n②电话号码、邮件地址\n③IP地址、设备信息、浏览器信息、WEB浏览信息、Cookie信息' },
            ],
          },
          {
            title: '(b)共同使用者的范围',
            content: [{ title: '日本的 Fenrir Inc. 及其国内外的本集团企业（包括飞狼数码（上海）有限公司、大连飞狼科技有限公司、其他由 Fenrir Inc. 直接或间接持有50%以上股份的公司）' }],
          },
          {
            title: '(c)共同使用者的使用目的',
            content: [{ title: '与第1条规定的个人信息的使用目的相同。' }],
          },
          {
            title: '(d)负责管理该个人数据的公司名称',
            content: [{ title: '成都市飞狼科技有限公司' }],
          },
        ],
      },
      { title: '法规允许公开或提供时' },
      { title: '因收购、合并、公司清算、公司分割或营业转让等业务继承而公开或提供个人信息时' },
    ],
    list_5: [
      { title: '用户根据自己的判断向第三方披露了个人信息时' },
      { title: '在用户咨询时，根据告知的内容，出乎意料地确定了本人的情况时' },
      { title: '在与本公司服务链接的外部网站上，用户自己提供个人信息，并使用该信息时' },
      { title: '由于用户利用的网络的脆弱性，因用户自身的原因泄露了识别其个人信息时' },
    ],
    list_7: [
      { title: '地址：四川省成都市高新区天府软件园D6栋15层' },
      { title: '电话号码：028-85177409' },
      { title: '联系我们：<a href="/zh/contact-us" style="color: #303233">https://fenrir-inc.com.cn/contact-us</a>' },
    ],
  },
  date: '2024年8月20日修订',
};
