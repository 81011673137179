import aiImages1 from '@/assets/new-pages/new_20231205_01.png';
import aiImages2 from '@/assets/new-pages/new_20231205_02.png';

export default {
  sectionList: [
    {
      subtitle: '',
      content: [
        {
          partType: 'img',
          value: {
            src: aiImages1,
            alt: 'aiImages1',
          },
        },
        {
          partType: 'string',
          value:
            '飞狼的母公司Fenrir Inc.作为创始成员加入了旨在推进开放、安全、负责任的人工智能的国际社区——人工智能联盟(The AI Alliance，下称“AI联盟”)。',
        },
      ],
    },
    {
      subtitle: '关于AI联盟',
      content: [
        {
          partType: 'string',
          value:
            // eslint-disable-next-line max-len
            'AI联盟成立于2023年12月5日，旨在加速开放、安全、负责任的人工智能的发展。联盟汇聚了包括IBM和Meta在内的全球50多个领先组织，Fenrir Inc.也作为创始成员参与其中。Fenrir Inc.自2005年创立以来，通过设计与技术的结合，不断地为用户提供了更好的用户体验。为了实现具有实用性的人工智能，将通过设计和技术为人工智能的开放创新和开放科学做出贡献。',
        },
        {
          partType: 'string',
          value:
            "关于AI联盟的详细内容请看以下链接。\n<a target='_blank' href='https://thealliance.ai/'>https://thealliance.ai/</a>",
        },
      ],
    },
    {
      subtitle: '关于Aitomatic',
      content: [
        {
          partType: 'string',
          value:
            // eslint-disable-next-line max-len
            '位于硅谷的Aitomatic公司是在生成式人工智能领域引领产业的存在，并引领着人工智能的应用。Aitomatic公司由在Google和Panasonic拥有丰富经验的Christopher Nguyen博士成立。该公司基于其推进的OpenSSA，推出了包括aiVA（虚拟顾问）等人工智能产品。Aitomatic是全球企业的重要合作伙伴，并与IBM、Meta共同促成了此次AI联盟的成立。\nFenrir Inc.与Aitomatic推进的OpenSSA合作，致力于提高在UX/UI领域的软件开发中的业务效率。',
        },
      ],
    },
    {
      subtitle: 'Fenrir Inc.首席执行官牧野兼史的评论',
      content: [
        {
          partType: 'string',
          value:
            // eslint-disable-next-line max-len
            '能够成为全球性联盟的一员，我感到非常荣幸。能够在快速发展的人工智能领域与世界各地的专业人士合作，Fenrir Inc.的技术实力有望得到进一步提升。在这个联盟中，我相信通过Fenrir Inc.积累的设计和技术能力，与开放透明的的人工智能创新相结合，将为社会做出贡献。',
        },
      ],
    },
    {
      subtitle: 'Fenrir Inc.开发中心负责人高木健次的评论',
      content: [
        {
          partType: 'string',
          value:
            '与Aitomatic公司合作并灵活运用他们的OpenSSA技术，对于构建以用户为中心的工业级人工智能解决方案至关重要。这种合作伙伴关系可以为客户提供个性化的解决方案，是推动行业效率化和创新的关键。',
        },
      ],
    },
    {
      subtitle: 'Aitomatic首席执行官克Christopher Nguyen博士的评论',
      content: [
        {
          partType: 'string',
          // eslint-disable-next-line max-len
          value: '人工智能的独立性对于对于企业来说非常重要，它可以集中特定领域的专业知识，以保持竞争力。而开源的基础设施在顺利推广运用最新技术模型方面则是不可或缺的。Aitomatic和Fenrir Inc.通过在全球范围内的人工智能合作，致力于推动面向产业的创新和负责任的人工智能发展，并希望从中为客户带来益处。',
        },
      ],
    },
    {
      subtitle: '',
      content: [
        {
          partType: 'img',
          value: {
            src: aiImages2,
            alt: 'aiImages2',
          },
        },
      ],
    },
    {
      subtitle: '联盟合作伙伴(截至2023年12月5日/乱序)',
      content: [
        {
          partType: 'string',
          // eslint-disable-next-line no-useless-concat
          value:
            '新加坡科技研究局(A*STAR)'
            + '\nAitomatic, Inc.'
            + '\nAdvanced Micro Devices, Inc.（AMD）'
            + '\nAnyscale, Inc'
            + '\nCerebras'
            + '\n欧洲核子研究中心'
            + '\nCleveland Clinic'
            + '\n康奈尔大学'
            + '\n达特茅斯学院'
            + '\nDell Technologies, Inc.'
            + '\n洛桑联邦理工学院'
            + '\n苏黎世联邦理工学院(ETH)'
            + '\nFast.ai'
            + '\nFenrir Inc.'
            + '\nFPT Software'
            + '\n希伯来大学'
            + '\nHugging Face, Inc.'
            + '\nIBM（International Business Machines Corporation）'
            + '\n帝国理工学院(ICL)'
            + '\n印度理工学院孟买分校'
            + '\n国际理论物理中心（ICTP）'
            + '\nINSAIT（Institute for Computer Science, Artificial Intelligence and Technology）'
            + '\nIntel Corporation'
            + '\n庆应义塾大学'
            + '\nLangChain'
            + '\nLlamaIndex'
            + '\nThe Linux Foundation'
            + '\nMOC Alliance'
            + '\nMeta Platforms, Inc.（Meta）'
            + '\n穆罕默德·本·扎耶德人工智能大学(MBZUAI)'
            + '\nMLCommons'
            + '\n美国国家航空航天局（NASA）'
            + '\n美国国家科学基金会(NSF)'
            + '\n纽约大学（NYU）'
            + '\nNumFOCUS'
            + '\nOpenTeams'
            + '\nOracle Corporation'
            + '\nPartnership on AI（PAI）'
            + '\nQuansight, Inc.'
            + '\nRed Hat, Inc.'
            + '\n伦斯勒理工学院(RPI)'
            + '\nRoadzen, Inc.'
            + '\nSakana AI株式会社'
            + '\nSB Intuitions株式会社'
            + '\nServiceNow, Inc'
            + '\nSilo AI'
            + '\n西蒙斯基金会'
            + '\n索尼集团'
            + '\nStability AI LTD,'
            + '\nTogether AI'
            + '\n慕尼黑工业大学(TUM)'
            + '\n加州大学伯克利分校（UC Berkeley）'
            + '\n伊利诺伊大学厄巴纳-香槟分校'
            + '\n圣母大学'
            + '\n得克萨斯大学奥斯汀分校'
            + '\n东京大学'
            + '\n耶鲁大学'
          ,
        },
        {
          partType: 'string',
          // eslint-disable-next-line max-len
          value: "<a target='_blank' href='https://www.fenrir-inc.com/jp/news/2023/12/05/ai-alliance/'>Fenrir公司新闻发布</a>",
        },
      ],
    },
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          // eslint-disable-next-line max-len
          value: "如果您对生成式AI的应用有任何疑问或需要，我们可以提供支持，请随时联系我们。\n\n<strong>联系我们：</strong>\n飞狼数码（上海）有限公司　吉泽<br/>电子邮件：<a href='mailto:info@fenrir-inc.com.cn'>info@fenrir-inc.com.cn</a>",
        },
      ],
    },
  ],
};
