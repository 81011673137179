<template>
  <div class="home-about">
    <div class="top-content">
      <h3 v-viewportChanged="showView" class="hidden-element top-title">{{ pr.title }}</h3>
      <div v-viewportChanged="showView" class="hidden-element top-subtitle">
        <break-text :content="pr.subtitle1"></break-text>
        <break-text :content="pr.subtitle2"></break-text>
      </div>
      <p
        v-viewportChanged="showView"
        v-html="description"
        class="hidden-element top-description pc"
      ></p>
      <p
        v-viewportChanged="showView"
        v-html="descriptionMiddle"
        class="hidden-element top-description pc-middle"
      ></p>
      <p
        v-viewportChanged="showView"
        v-html="description"
        class="hidden-element top-description sp"
      ></p>
      <div class="map pc">
        <div class="map-img">
          <div class="map-img-wrapper">
            <div
              v-for="item in markerList"
              :key="item.id"
              :class="['map-address', { active: active > -1 && item.id === pr.list[active].id }]"
            >
              <div
                class="map-item marker"
                :style="{
                  left: item.position.icon[0] + 'px',
                  top: item.position.icon[1] + 'px',
                }"
                @click="selectMarker(item.id)"
              ></div>
              <span
                class="map-item name"
                :style="{
                  left: item.position.name[0] + 'px',
                  top: item.position.name[1] + 'px',
                }"
                @click="selectMarker(item.id)"
              >
                {{ item.name }}
              </span>
            </div>
          </div>
        </div>
        <img src="@/assets/shadow_left.png" alt="" class="shadow left" />
        <img src="@/assets/shadow_right.png" alt="" class="shadow right" />
      </div>
      <div class="map sp">
        <img class="map-img" src="@/assets/img_map_sp@3x.png" alt="map" />
      </div>
    </div>
    <div id="about-info-pc" class="about-info pc">
      <tabs v-model="active">
        <tab v-for="item in pr.list" :key="item.id" :title="item.title" class="about-info-content">
          <Info :model="item" :language="locale" />
        </tab>
      </tabs>
    </div>
    <div id="about-info-sp" class="about-info sp">
      <Info v-for="item in pr.list" :key="item.id" :model="item" :language="locale" />
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import usePageResource from '@/composables/usePageResource';
import { FenrirLink } from '@/constants/constants';
import Tabs from '@/components/tabs/Index.vue';
import Tab from '@/components/tabs/Tab.vue';
import BreakText from '@/components/common/BreakText.vue';
import Info from './Info.vue';

export default {
  name: 'home-about',
  components: {
    Tabs,
    Tab,
    Info,
    BreakText,
  },
  data() {
    return {
      markerList: [
        {
          id: 'sh',
          name: 'Shanghai',
          position: {
            icon: [456, 117],
            name: [529, 180],
          },
        },
        {
          id: 'cd',
          name: 'Chengdu',
          position: {
            icon: [392, 117],
            name: [280, 185],
          },
        },
        {
          id: 'dl',
          name: 'Dalian',
          position: {
            icon: [463, 69],
            name: [549, 135],
          },
        },
      ],
    };
  },
  computed: {
    description() {
      return this.pr.description
        .replace(/\t/gm, '')
        .replace(/\n/gm, '<br/>')
        .replace('Fenrir Inc.', `<a href="${FenrirLink}" target="_blank">Fenrir Inc.</a>`);
    },
    descriptionMiddle() {
      return this.pr.description
        .replace(/\t/gm, '<br/>')
        .replace(/\n/gm, '<br/>')
        .replace('Fenrir Inc.', `<a href="${FenrirLink}" target="_blank">Fenrir Inc.</a>`);
    },
    cd() {
      return this.pr.cd;
    },
    dl() {
      return this.pr.dl;
    },
  },
  setup() {
    const { pr } = usePageResource('about');
    const locale = inject('locale');
    const active = ref(0);
    return {
      pr,
      locale,
      active,
    };
  },
  mounted() {
    this.$bus.$on('show-about-info', this.selectMarker);
  },
  unmounted() {
    this.$bus.$off('show-about-info', this.selectMarker);
  },
  methods: {
    selectMarker(id) {
      this.active = this.pr.list.findIndex((o) => o.id === id);
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

.pc {
  &.top-description {
    @media screen and (max-width: 910px) {
      display: none;
    }
  }

  &.map {
    @media screen and (max-width: 414px) {
      display: none;
    }
  }

  &.about-info {
    @media screen and (max-width: 768px) {
      display: none;
    }

    margin: 0 auto;
    max-width: 1200px;
  }
}

.pc-middle {
  &.top-description {
    @media screen and (min-width: 911px), (max-width: 768px) {
      display: none;
    }
  }
}

.sp {
  &.top-description {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
  &.map {
    @media screen and (min-width: 415px) {
      display: none;
    }
  }

  &.about-info {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}

.home-about {
  padding: 0 20px 100px;
  color: #222;

  .top {
    &-content {
      overflow: auto;
      text-align: center;

      .map {
        width: 100%;

        &.pc {
          $mapWidth: 1111px;
          $mapHeight: 471px;

          position: relative;
          margin-bottom: 48px;
          height: $mapHeight;
          overflow: hidden;

          .map-img {
            position: absolute;
            left: calc(50% - #{math.div($mapWidth, 2)});
            width: $mapWidth;
            height: 100%;
            background-image: url('~@/assets/img_map_pc@3x.png');
            background-repeat: no-repeat;
            background-size: $mapWidth $mapHeight;

            @media screen and (max-width: 768px) {
              left: calc(50% - #{math.div($mapWidth, 2)} + 95px);
            }

            &-wrapper {
              position: relative;
              width: 100%;
              height: 100%;

              .map-address {
                @mixin marker-active {
                  z-index: 10;
                  transform: scale(1);
                }
                &.active {
                  .map-item {
                    &.marker {
                      @include marker-active;
                    }
                  }
                }

                &:not(.active) {
                  .map-item {
                    &.name:hover {
                      opacity: 0.7;
                    }

                    &.marker:hover {
                      @include marker-active;
                    }
                  }
                }
              }

              .map-item {
                position: absolute;
                cursor: pointer;
                transition-duration: 100ms;

                &.name {
                  font-size: 18px;
                  font-weight: 500;
                  color: #3784e6;
                  line-height: 25px;
                  user-select: none;
                }

                &.marker {
                  width: 38px;
                  height: 60px;
                  background-image: url('~@/assets/coordinate_big@3x.png');
                  background-repeat: no-repeat;
                  background-size: 100%;
                  transform: scale(0.63);
                  transform-origin: center bottom;
                }
              }
            }
          }

          .shadow {
            position: absolute;
            top: 0;

            &.left {
              left: 0;
            }

            &.right {
              right: 0;
            }
          }
        }

        &.sp {
          max-width: 326px;
          margin: 0 auto 40px;

          .map-img {
            width: 100%;
          }
        }
      }
    }

    &-title {
      margin-top: 72px;
      font-size: 30px;
      line-height: 42px;
    }

    &-subtitle {
      margin: 31px 0 15px;
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
    }

    &-description {
      margin-bottom: 56px;
      font-size: 14px;
      line-height: 24px;

      @media only screen and (max-width: 800px) {
        margin-bottom: 40px;
      }
    }

    @media only screen and (max-width: 800px) {
      &-description {
        text-align: left;
      }

      &-title {
        margin-top: 56px;
        font-size: 22px;
        line-height: 30px;
      }

      &-subtitle {
        margin: 29px 0 15px;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -1px;
      }

      &-description {
        margin-bottom: 40px;
        font-size: 15px;
        line-height: 30px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-about {
    padding: 0 24px 70px;
  }
}
</style>
