/* eslint-disable max-len */
export default {
  title: '关于我们',
  subtitle1: '赋能匠心设计，纵横创新技术，',
  subtitle2: '让用户拥有极致体验',
  description:
    '飞狼投资方 Fenrir Inc. 公司以设计和技术的完美结合而闻名于日本。\n总公司设立于大阪，旗下四家分公司，分别位于东京、京都、名古屋和岛根。在中国境内有上海、成都和大连三家子公司。\n我们旨在紧密各方力量，联手打造绝对优势的IT服务团队。',
  info: {
    addressTitle: '办公地址',
    addressBtnText: '查看地图',
    postcodeTitle: '邮编',
    phoneNumberTitle: '电话',
    creationDateTitle: '成立日期',
  },
  list: [
    {
      id: 'sh',
      title: '上海',
      name: '飞狼数码（上海）有限公司',
      nameEN: 'Fenrir Shanghai Inc.',
      manager: {
        title: '总经理',
        name: '吉泽 克巳',
      },
      description: [
        '在数字化飞速发展的中国市场，各个企业为了扩大事业规模，需要密切关注国内的新兴服务与技术，并尽快建立或完善能够与之对应的运营体制。',
        '为了帮助客户企业应对在中国市场开展业务时所面临的挑战和需求，让客户满意，我们将运用国内最前沿的移动技术，持续不断地支持客户企业的数字化发展。',
      ],
      address: {
        postcode: '200001',
        text: '上海市黄浦区延安东路550号海洋大厦27层',
        poiid: 'B0015104B7',
        // location: [121.464391, 31.21858],
      },
      phoneNumber: null,
      creationDate: '2021年4月30日',
    },
    {
      id: 'cd',
      title: '成都',
      name: '成都市飞狼科技有限公司',
      nameEN: 'Fenrir Chengdu Inc.',
      manager: {
        title: '总经理',
        name: '赵 岗',
      },
      description: [
        '从全面的用户视角，为客户提供最适合的解决方案，与客户一起致力于创造新的价值，让客户以及客户的客户得到更大的满足。',
        '坚持以人为本，关注员工的可能性，尊重员工的个人价值，不断为员工提供提升自我、超越自我的机会，追求为员工以及员工的家庭创造幸福与快乐。',
      ],
      address: {
        postcode: '610000',
        text: '四川省成都市高新区天府软件园D6栋15层',
        poiid: 'B001C8TY3I',
        // location: [104.067615, 30.551924],
      },
      phoneNumber: '028-85177409',
      creationDate: '2013年10月21日',
    },
    {
      id: 'dl',
      title: '大连',
      name: '大连飞狼科技有限公司',
      nameEN: 'Fenrir Dalian Inc.',
      manager: {
        title: '总经理',
        name: '梁 日棚',
      },
      description: [
        '大连飞狼科技秉承为客户带来满足，创造价值的理念，站在客户立场，用心体会客户需求，为客户提供高品质软件开发服务和IT解决方案。',
        '在经营管理上，力戒华而不实，最大程度发挥个人创造力和集体优势，实现员工和公司共同成长的目标。在客户合作上，以公正交易为基础，和客户构筑同存互利的合作关系，成为深受社会信赖的企业公民。',
      ],
      address: {
        postcode: '116085',
        text: '辽宁省大连市高新园区火炬路3号，纳米大厦11楼',
        poiid: 'B019B0C1WK',
        // location: [121.526098, 38.859573],
      },
      phoneNumber: '0411-83731889',
      creationDate: '2014年12月12日',
    },
  ],
};
