<template>
  <NetworkSecurity v-if="work.id === 'network-security'" :work="work" />
  <div v-else :class="['work-wrap', work.id]">
    <div class="work-brief">
      <div class="work-brief-content">
        <div class="work-brief-text">
          <span class="animation-text company">{{ work.company }}</span>
          <break-text
            class="animation-text name"
            :content="(!isSP ? work?.namePC : work.name) || work.name"
          />
          <break-text
            class="animation-text des"
            :content="(!isSP ? work?.descriptionPC : work?.descDetail) || work.description"
            :isSpanBlock="true"
          />
        </div>
        <div :class="['screen-shoots', !imageLoaded && 'placeholder']">
          <img :src="getImageUrl(work.screenShot1)" alt="" @load="onImageLoad" />
          <img :src="getImageUrl(work.screenShot2)" alt="" />
        </div>
      </div>
    </div>
    <div class="work-details">
      <div class="work-details-content">
        <template v-if="work?.main?.length">
          <template v-for="(item, index) in work.main" :key="`main-${index}`">
            <template v-if="item?.isCustom">
              <CustomContent :main="item"/>
            </template>
            <template v-else>
              <p v-viewportChanged="showView" v-show="item.title" class="hidden-element des-title">
                {{ item.title }}
              </p>
              <break-text
                v-viewportChanged="showView"
                v-show="item.content"
                class="hidden-element des-content"
                :content="item?.content"
              />
              <div :class="['img-container', item.imagesClass]" v-if="item.images">
                <template v-for="(img, index) in item.images" :key="`img-${index}`">
                  <img
                    v-if="typeof img === 'string'"
                    :src="getImageUrl(img)"
                    :alt="img"
                    class="screen-shot image-box-mb"
                  />
                  <div v-else :class="['image-box', img?.class || 'image-box-mb']">
                    <div
                      :class="['screen-shot', 'img', !!img.legend && 'mb-text']"
                      :style="img.style"
                    >
                      <img
                        :src="getImageUrl(img.src)"
                        :alt="img.src"
                        :srcset="transformSrcset(img.srcset)"
                      />
                    </div>
                    <span v-if="!!img.legend" class="legend">{{ img.legend }}</span>
                  </div>
                </template>
              </div>
            </template>
          </template>
        </template>
        <template v-else>
          <p v-viewportChanged="showView" v-show="work.desTitle1" class="hidden-element des-title">
            {{ work.desTitle1 }}
          </p>
          <p
            v-viewportChanged="showView"
            v-show="work.desContent1"
            class="hidden-element des-content"
          >
            {{ work.desContent1 }}
          </p>
          <img
            v-show="work.screenShot3"
            class="screen-shot image-box-mb"
            :src="getImageUrl(work.screenShot3)"
          />
          <p v-viewportChanged="showView" v-show="work.desTitle2" class="hidden-element des-title">
            {{ work.desTitle2 }}
          </p>
          <p
            v-viewportChanged="showView"
            v-show="work.desContent2"
            class="hidden-element des-content"
          >
            {{ work.desContent2 }}
          </p>
          <img
            v-show="work.screenShot4"
            :class="['screen-shot', !!work.desTitle3 && 'image-box-mb']"
            id="shop"
            :src="getImageUrl(work.screenShot4)"
          />
          <p v-viewportChanged="showView" v-show="work.desTitle3" class="hidden-element des-title">
            {{ work.desTitle3 }}
          </p>
          <p
            v-viewportChanged="showView"
            v-show="work.desContent3"
            class="hidden-element des-content"
          >
            {{ work.desContent3 }}
          </p>
        </template>
      </div>
      <works-button containerClass="works-button" btnText="worksBtnTextInDetail" />
    </div>
  </div>
</template>

<script>
import usePageResource from '@/composables/usePageResource';
import BreakText from '@/components/common/BreakText.vue';
import NetworkSecurity from '@/components/NetworkSecurity/index.vue';
import useIsSP from '@/composables/useIsSP';
import WorksButton from '@/components/works-button/WorksButton.vue';
import CustomContent from '../components/kuraray-dx/CustomContent.vue';

export default {
  components: {
    BreakText,
    NetworkSecurity,
    WorksButton,
    CustomContent,
  },
  name: 'work-detail',
  setup() {
    const { pr } = usePageResource('works');
    const { isSP } = useIsSP(500);
    return {
      pr,
      isSP,
    };
  },
  watch: {
    pr() {
      this.initData();
    },
    $route() {
      this.initData();
    },
  },
  data() {
    return {
      work: {},
      imageLoaded: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.initData();
    if (this.work.name) return;
    this.$router.push({ name: 'home', lang: this.$route.params.lang });
  },
  computed: {
    descriptionMobile() {
      switch (this.work.id) {
        case 'lingwei-anfou':
        case 'xikemu-dx':
        case 'feilang-go':
        case 'kuraray-miniapp':
        case 'ise-woa':
          return Array.isArray(this.work.description)
            ? this.work.description.join('')
            : this.work.description;
        default:
          return this.work.description;
      }
    },
  },
  methods: {
    initData() {
      const workId = this.$route.params.id;
      const work = this.pr.works.find((item) => item.id === workId);
      if (work) this.work = work;
    },
    getImageUrl(imgName) {
      if (imgName) {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        return require(`./../assets/${imgName}`);
      }
      return '';
    },
    transformSrcset(srcset) {
      if (typeof srcset !== 'object' || srcset === null) return '';

      const res = [];
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in srcset) {
        res.push(`${this.getImageUrl(i)} ${srcset[i]}`);
      }

      return res;
    },
    onImageLoad() {
      this.imageLoaded = true;
    },
  },
};
</script>

<style scoped lang="scss">
.img {
  & > img {
    width: 100%;
    height: 100%;
  }
}
.mb-text {
  margin-bottom: 10px;
}
.image-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .legend {
    $line-height: 18px;
    line-height: $line-height;
    width: 100%;
    text-align: center;
    font-size: 12px;
    white-space: pre-line;
  }
}
.works-button {
  margin-top: 90px;
  @media only screen and(max-width: 768px) {
    margin-top: 60px;
  }
}
.work-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #222222;

  .work-brief {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 158px 24px 102px;
    background: #f1f3f4;

    &-content {
      max-width: 900px;
      width: 100%;
      display: flex;
      align-items: center;

      .screen-shoots {
        display: flex;
        > img {
          width: 232px;
        }
      }
      @media only screen and(min-width: 1000px) {
        .placeholder {
          width: 464px;
          height: 349px;
        }
      }
      @media only screen and(max-width: 999px) {
        .placeholder {
          width: 310px;
          height: 232px;
        }
      }
    }

    &-text {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .company {
        font-size: 16px;
        white-space: pre-wrap;
      }

      .name {
        margin: 17px 0 40px 0;
        font-size: 32px;
        font-weight: bold;
        line-height: 45px;
        overflow-wrap: break-word;
      }

      .des {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .work-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 24px 160px 24px;
    background: #ffffff;

    .work-details-content {
      max-width: 900px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .des-title {
        width: 100%;
        margin-bottom: 27px;
        font-size: 23px;
        font-weight: bold;
        line-height: 32px;
      }

      .des-content {
        width: 100%;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 28px;

        &:not(:last-child) {
          margin-bottom: 50px;
        }
      }

      .screen-shot {
        width: 265px;
        box-shadow: 0 10px 20px rgba($color: #000000, $alpha: 0.1);
      }
    }
  }
}
.feilang-go #shop {
  width: 512px;
  height: 384px;
}
.img-container {
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media only screen and(max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
}

.jc-center {
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.\>500\:m-r-100 {
  @media screen and (min-width: 500px) {
    margin-right: 100px;
  }
}

@media only screen and (max-width: 999px) {
  .work-wrap {
    .work-brief {
      padding: 98px 24px 70px 24px;

      &-content {
        flex-direction: column;

        .screen-shoots {
          > img {
            width: auto;
            height: 232px;
          }
        }
      }

      &-text {
        width: 100%;
        margin-bottom: 40px;
        align-items: center;
        text-align: center;
      }
    }
  }
}
.image-box-mb {
  margin-bottom: 90px;
}
@media only screen and (max-width: 768px) {
  .work-wrap {
    .work-brief {
      &-text {
        .company {
          font-size: 14px;
          line-height: 20px;
        }

        .name {
          margin: 6px 0 16px;
          font-size: 26px;
          line-height: 40px;
        }

        .des {
          font-size: 13.5px;
        }
      }
    }

    .work-details {
      padding: 60px 24px 100px 24px;

      .work-details-content {
        .des-title {
          margin-bottom: 24px;
          font-size: 19px;
          line-height: 26px;
        }

        .des-content {
          font-size: 14px;

          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }

        .screen-shot {
          width: 175px;
        }
      }
    }
  }

  .image-box-mb {
    margin-bottom: 60px;
  }
  .mb-sp-60 {
    margin-bottom: 60px !important;
  }
  .mb-sp-32 {
    margin-bottom: 32px;
  }
  .mb-sp-0 {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 769px) {
  .mb-pc-90 {
    margin-bottom: 90px;
  }
  .mb-pc-32 {
    margin-bottom: 32px;
  }
  .mb-pc-20 {
    margin-bottom: 20px;
  }
  .mb-pc-0 {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 375px) {
  .lingwei-anfou {
    .work-brief-text {
      .name {
        font-size: 26px;
      }
    }
  }
  .kuraray-dx {
    .work-brief-text {
      .name {
        font-size: 24px;
      }
    }
  }
}
</style>
