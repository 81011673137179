const DeviceUtil = {
  isiOS: () => (
    navigator.platform === 'iPad'
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    || (navigator.userAgent.toLowerCase().indexOf('iphone') > -1)
    || (navigator.userAgent.toLowerCase().indexOf('ipod') > -1)
  ),
};

export default DeviceUtil;
