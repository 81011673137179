<template>
  <section>
    <div class="share-bar">
      <a
        :class="['share-btn', 'btn', item.type]"
        v-for="(item, i) in shareList"
        :key="i"
        href="#"
        @click.prevent="handleWxClick(item, $event)"
        target="_blank"
        tabindex="0"
      >
        <img :src="item.icn" :alt="item.type" />
        <div v-if="item.type === 'wx'" id="qrcode">
          <qrcode-vue
            :value="url"
            :size="qrcodeData.size"
            level="H"
            :margin="qrcodeData.margin"
            class="qr"
          />
          <p :class="['scan', { ja: !isZH }]">
            {{ pr.wxScan }}
          </p>
        </div>
      </a>
    </div>
    <div class="xs-btn" v-if="xsBtnVisible">
      <a href="#" target="_blank" class="xs-btn-link with-hover btn" @click.prevent="spShare">
        <span class="text">{{ pr.share }}</span>
        <span class="img" />
      </a>
    </div>
  </section>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import { inject } from 'vue';
import usePageResource from '@/composables/usePageResource';
import { LOCALES } from '@/constants/constants';

export default {
  name: 'share-bar',
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: undefined,
      shareList: [
        {
          type: 'wx',
          color: '#33AE13',
          href: '',
          // eslint-disable-next-line global-require
          icn: require('@/assets/wechat@3x.png'),
        },
        {
          type: 'wb',
          color: '#E60F29',
          href: 'https://service.weibo.com/share/share.php?url={{url}}&title={{text}}',
          // eslint-disable-next-line global-require
          icn: require('@/assets/weibo@3x.png'),
        },
        {
          type: 'fb',
          color: '#39A3F1',
          href: 'https://www.facebook.com/sharer.php?u={{url}}&t={{text}}',
          // eslint-disable-next-line global-require
          icn: require('@/assets/facebook@3x.png'),
        },
        {
          type: 'tr',
          color: '#3A5A9B',
          href: 'https://twitter.com/share?url={{url}}&text={{text}}',
          // eslint-disable-next-line global-require
          icn: require('@/assets/twitter.svg'),
        },
      ],
      qrcodeData: {
        size: 130,
        margin: 0,
      },
    };
  },
  setup() {
    const { pr } = usePageResource('news');
    const locale = inject('locale');
    return {
      pr,
      locale,
    };
  },
  methods: {
    spShare() {
      navigator.share(this.shareData);
    },
    handleWxClick(item) {
      const { href } = item;
      if (href) {
        const url = this.spliceUrl(href);
        window.open(encodeURI(url));
      }
    },
    spliceUrl(href) {
      const urlRex = /{{url}}/;
      const textRex = /{{text}}/;
      let res = '';
      res = href.replace(urlRex, this.url);
      res = res.replace(textRex, `${this.detail.epitome}\n${this.detail.title}\n`);
      return res;
    },
  },
  computed: {
    xsBtnVisible() {
      if (navigator.canShare) {
        return navigator.canShare(this.shareData);
      }
      return !!navigator.share;
    },
    shareData() {
      return {
        url: this.url,
        title: this.detail.epitome,
        text: this.detail.title,
      };
    },
    isZH() {
      return this.locale === LOCALES.ZH;
    },
  },
  components: {
    QrcodeVue,
  },
  created() {
    this.url = window.location.href;
  },
};
</script>

<style scoped lang="scss">
$xs: 767px;
$sp-bottom: 95px;
$border-radius: 4px;
$types: (
  'wx': #33ae13,
  'wb': #e60f29,
  'tr': #000,
  'fb': #3a5a9b,
);
@each $key, $color in $types {
  .#{$key} {
    background-color: $color;
  }
}

.share-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 160px;

  @media screen and (max-width: $xs) {
    display: none;
  }

  .share-btn {
    box-sizing: border-box;
    text-align: center;
    padding: 21px 0;
    border-radius: $border-radius;
    border: none;
    max-width: 215px;
    flex: 1;
    height: 60px;
    cursor: pointer;
    position: relative;

    &:not(&:last-child) {
      margin-right: 10px;
    }

    img {
      height: 18px;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Google Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }

    &:hover {
      @each $key, $color in $types {
        &.#{$key} {
          transition: background-color 0.1s;
          background-color: rgba($color, 0.4);
        }
      }
    }

    &:focus {
      outline: none;

      #qrcode {
        display: block;
      }
    }

    #qrcode {
      display: none;
      border-radius: $border-radius;
      box-shadow: 0 2px 10px #aaa;
      background-color: #fff;
      position: absolute;
      bottom: calc(100% + 5px);
      padding: 20px 20px 0;
      left: 50%;
      transform: translateX(-50%);

      .scan {
        color: #666;
        padding: 10px 0;
        font-size: 14px;

        &.ja {
          line-height: 1.2;
        }
      }
    }
  }
}

.xs-btn {
  display: flex;
  width: 100%;
  $font-size: 14px;

  .xs-btn-link {
    text-align: center;
    border: 1px solid #ccc;
    border-radius: $border-radius;
    flex: 1;
    padding: 11px 0;
    text-decoration: none;
    font-size: $font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    margin-bottom: 40px;

    &:hover {
      color: #000;

      .img {
        background: {
          image: url('~@/assets/Button_Link_hover.png');
          repeat: no-repeat;
        }
      }
    }

    .text {
      margin-right: 15px;
    }

    .img {
      display: inline-block;
      height: $font-size;
      width: $font-size;
      background: {
        image: url('~@/assets/Button_Link.png');
        repeat: no-repeat;
        size: 100%;
      }
    }
  }

  @media screen and (min-width: $xs) {
    display: none;
  }
}
</style>
