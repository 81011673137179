<template>
  <div>
    <ul class="tabs">
      <li
        v-for="(tab, i) of tabs"
        :key="i"
        :class="['tab text-hover', { active: active === i }]"
        @click="selectTab(i)"
      >
        {{ tab.props.title }}
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
import { provide, computed, ref } from 'vue';

export default {
  name: 'Tabs',
  props: {
    modelValue: {
      type: [String, Number],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const active = computed(() => props.modelValue);
    const tabs = ref([]);

    function selectTab(tab) {
      emit('update:modelValue', tab);
    }

    provide('tabsState', {
      active,
      tabs,
    });

    return {
      tabs,
      active,
      selectTab,
    };
  },
};
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  justify-content: center;
  list-style: none;
  border-bottom: 1px solid #A6AEB1;
}

.tab {
  box-sizing: border-box;
  width: 196px;
  height: 73px;
  text-align: center;
  line-height: 73px;
  color: #A6AEB1;
  font-size: 18px;
  letter-spacing: 2.8px;
  font-weight: bold;
  user-select: none;
  cursor: pointer;

  &.active {
    $color: #212121;
    color: $color;
    border-bottom: 4px solid $color;
  }
}
</style>
