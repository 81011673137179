// eslint-disable-next-line import/prefer-default-export
export const LOCALES = {
  ZH: 'zh',
  JA: 'ja',
};

export const FenrirLink = 'https://www.fenrir-inc.com/';

export const reCAPTCHASiteKey = process.env.VUE_APP_reCAPTCHA_site_key;

export const DefaultLang = LOCALES.ZH;
