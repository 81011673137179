<template>
  <div class="container privacy-policy-wrapper">
    <div class="content">
      <p class="title">{{ title }}</p>
      <div class="read">
        <p class="read-item" v-for="(item, index) in getArr(read)" :key="index">{{ item }}</p>
      </div>
      <div class="police" v-for="(police, firstIndex) in list" :key="firstIndex">
        <PoliceItem
          :title="`${firstIndex + 1})${police.title}`"
          :titleType="titleType.first"
          :desc="police.desc"
          :descType="firstIndex >= 2 ? descType.large : ''"
          :box="police.content !== undefined || firstIndex < 2"
        />
        <div class="police-content" v-if="subList[police.id]">
          <div v-if="firstIndex < 2">
            <PoliceItem
              v-for="(policeSecend, secendIndex) in subList[police.id]"
              :key="secendIndex"
              :title="`${secendIndex + 1}.${policeSecend.title}`"
              :titleType="titleType.secend"
              :desc="policeSecend.desc"
            >
              <div v-for="(li, liIndex) in policeSecend.content" :key="liIndex">
                <div class="disc-title" v-if="li.title">{{ li.title }}</div>
                <Disc
                  :list="li.content"
                  :isPadding="!li.padding"
                  :isDot="!!li.dot || li.content.length > 1"
                />
              </div>
            </PoliceItem>
          </div>

          <div v-else-if="firstIndex >= 2">
            <Disc :list="subList[police.id]" />
          </div>
        </div>
      </div>
      <div class="date">{{ date }}</div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import usePageResource from '@/composables/usePageResource';
import PoliceItem from '@/components/privacy-policy/Item.vue';
import Disc from '@/components/privacy-policy/Disc.vue';
import { titleType, descType } from '@/constants/privacy-policy';

export default defineComponent({
  components: { PoliceItem, Disc },
  setup() {
    const { pr } = usePageResource('privacy-policy');
    const title = computed(() => pr.value.title);
    const read = computed(() => pr.value.read);
    const list = computed(() => pr.value.list);
    const subList = computed(() => pr.value.subList);
    const date = computed(() => pr.value.date);

    const getArr = (value) => value.split('\n');

    return {
      title,
      read,
      list,
      subList,
      date,
      getArr,
      titleType,
      descType,
    };
  },
});
</script>

<style scoped lang="scss">
$fontColor: #303233;

.privacy-policy-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 150px 0 160px;
  opacity: 0;
  animation: 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms 1 forwards slide-up-fading;

  .content {
    width: 100%;
    max-width: 900px;
    box-sizing: content-box;
    padding: 0 48px;

    .title {
      width: 100%;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      color: #222222;
    }

    .read {
      margin-top: 96px;
      line-height: 1.8;
      font-size: 16px;
      color: $fontColor;
      letter-spacing: 0.1em;

      &-item:nth-child(1) {
        margin-bottom: 36px;
      }
    }

    .police {
      width: 100%;
      margin-top: 80px;

      .police-content {
        padding-left: 16px;
      }

      .disc-title {
        line-height: 1.5;
        padding: 12px 42px;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: bold;
        color: #161616;
        background: #a7aeb1;
      }

      .dot {
        padding-left: 16px;

        &::before {
          content: '・';
          position: absolute;
          left: 0;
          transform: translateX(-50%);
          width: 8px;
        }
      }
    }

    .date {
      width: 100%;
      margin-top: 120px;
      text-align: right;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 112px 0 100px;

    .content {
      padding: 0 24px;

      .title {
        font-size: 22px;
        line-height: 1;
      }

      .read {
        font-size: 14px;
        line-height: 1.6;
        margin-top: 48px;
      }

      .police {
        margin-top: 40px;

        .disc-title {
          padding: 12px 24px;
        }
      }

      .date {
        margin-top: 60px;
      }
    }
  }
}

@keyframes slide-up-fading {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
<style>
.police a {
  text-decoration: none;
  border-bottom: 1px solid #222222;
  -webkit-font-smoothing: antialiased;
}
</style>
