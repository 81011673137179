<template>
  <ul :class="['disc', {'padd': isPadding}]">
    <li
      :class="['disc-item', {dot: isDot}]"
      v-for="(li, index) in list" :key="index"
    >
      <div :class="{'disc-item-child': isDiscChild(li.title) && li.content}">
        <div v-html="getText(li.title || '')"></div>
        <Disc v-if="li.content" :list="li.content" :isDot="false"/>
      </div>
    </li>
  </ul>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Disc',
  props: {
    list: { type: Array, default() { return []; } },
    isPadding: Boolean,
    isDot: { type: Boolean, default: true },
  },
  setup() {
    const getText = (value) => value.replace(/\n/gm, '<br/>');
    const isDiscChild = (value) => /[(a-z)]/.test(value || '');
    return {
      getText,
      isDiscChild,
    };
  },
});
</script>

<style lang="scss" scoped>
.disc {
  &-item {
    position: relative;
    line-height: 2;
    font-size: 16px;
    font-weight: 400;
    list-style: none;

    &-child {
      margin-bottom: 36px;

      &:nth-child(1) {
        margin-top: 36px;
      }

      & > .disc {
        padding-left: 16px;
      }
    }
  }

  &.padd {
    padding: 20px 42px;
    background-color: #E4E9EB;
  }

  .dot {
    padding-left: 16px;

    &::before {
      content: '・';
      position: absolute;
      left: 0;
      transform: translateX(-50%);
      width: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    .disc-item {
      line-height: 1.5;
      font-size: 14px;

      &-child {
        margin-bottom: 24px;

        &:nth-child(1) {
          margin-top: 24px;
        }

        & > .disc {
          padding-left: 14px;
        }
      }
    }

    &.padd {
      padding: 20px 24px;
      background-color: #E4E9EB;
    }

    .dot {
      padding-left: 14px;
    }
  }
}
</style>
