
export default {
  methods: {
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return rect.top <= $(window).height() - 100;
    },
    showView(e, el) {
      const dom = el;
      if (el.classList.contains('hidden-element') && this.isElementInViewport(el)) {
        dom.className = dom.className.replace(
          'hidden-element',
          'fade-in-element',
        );
      }
    },
  },
};
