<template>
  <div :class="['card-container', work.id]">
    <div :class="['card', { ja: !isZH }]" @click.prevent="handleWorkClick">
      <span
        v-viewportChanged="showView"
        :class="[isHasAnimation ? 'animation-text' : 'hidden-element', 'company', 'pc']"
      >
        {{ work.company }}
      </span>
      <span
        v-viewportChanged="showView"
        :class="[isHasAnimation ? 'animation-text' : 'hidden-element', 'company', 'sp']"
      >
        {{ companyMobile }}
      </span>
      <break-text
        v-viewportChanged="showView"
        :class="[isHasAnimation ? 'animation-text' : 'hidden-element', 'name']"
        :content="work.name"
        :isWrap="false"
      />
      <break-text
        v-viewportChanged="showView"
        :class="[isHasAnimation ? 'animation-text' : 'hidden-element', 'description', 'clamp']"
        :content="(!isSP ? work.descriptionCard : descriptionMobile) || descriptionMobile"
        :title="descriptionMobile"
        :isSpanBlock = "true"
      />
      <div
        v-if="work.id === 'network-security'"
        :class="['screen-shots',!imageLoaded && 'placeholder']"
      >
        <img :src="getImageUrl(work.screenShot1)" @load="onImageLoad" alt=""/>
      </div>
      <div v-else :class="['screen-shots',!imageLoaded && 'placeholder']">
        <img :src="getImageUrl(work.screenShot1)" @load="onImageLoad" alt=""/>
        <img :src="getImageUrl(work.screenShot2)" alt=""/>
      </div>
      <div class="detail-label with-hover">{{ detailLabel }}</div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import BreakText from '@/components/common/BreakText.vue';
import { LOCALES } from '@/constants/constants';
import useIsSP from '@/composables/useIsSP';

export default {
  components: { BreakText },
  setup() {
    const locale = inject('locale');
    const { isSP } = useIsSP(500);
    return { locale, isSP };
  },
  props: {
    work: {
      type: Object,
      default: () => {},
    },
    detailLabel: {
      type: String,
      default: '查看详情',
    },
    isHasAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageLoaded: false,
    };
  },
  computed: {
    isZH() {
      return this.locale === LOCALES.ZH;
    },
    descriptionMobile() {
      return Array.isArray(this.work.description)
        ? this.work.description.join('')
        : this.work.description;
    },
    companyMobile() {
      return this.work.companyMobile ?? this.work.company;
    },
  },
  methods: {
    getImageUrl(imgName) {
      if (imgName) {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        return require(`./../../assets/${imgName}`);
      }
      return '';
    },
    handleWorkClick() {
      const { lang } = this.$route.params;
      this.$router.push({ name: 'work-detail', params: { lang, id: this.work.id } });
    },
    onImageLoad() {
      this.imageLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
$sp: 500px;
$sp-small: 400px;

.clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: $sp) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: ($sp + 1)) {
  .sp {
    display: none;
  }
}

.card {
  width: 100%;
  height: 100%;
  padding: 33px 36px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f1f3f4;
  color: #222222;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  &-container {
    width: 100%;
    height: 100%;
  }

  .company {
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 6px;
    white-space: nowrap;
  }

  .name {
    margin-bottom: 36px;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
  }

  .description {
    min-height: 40px;
    margin-bottom: 23px;
    font-size: 14px;
    line-height: 20px;
  }

  .screen-shots {
    display: flex;
    justify-content: center;
    margin-bottom: 33px;
    pointer-events: none;

    > img {
      height: 232px;
    }
  }
  .placeholder{
    width: 310px;
    height: 232px;
  }

  .detail-label {
    width: 176px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #a6aeb1;
    border-radius: 4px;
    font-size: 14px;
    letter-spacing: 1.6px;
  }

  &.ja {
    padding: 36px 36px;

    .company {
      line-height: 14px;
      margin-bottom: 12px;
    }

    .name {
      line-height: 20px;
    }

    .description {
      min-height: 48px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .card {
    padding: 33px 24px 36px;

    &-container {
      padding: 0 24px;
    }

    .company {
      margin-bottom: 6px;
      line-height: 20px;
    }

    .name {
      margin-bottom: 22px;
    }

    .description {
      margin-bottom: 24px;
      line-height: 22px;
    }

    &.ja {
      padding: 32px 24px 36px;

      .name {
        margin-bottom: 22px;
      }

      .description {
        min-height: 44px;
        line-height: 22px;
        margin-bottom: 30px;
      }
    }

  }
}

@media only screen and (max-width: $sp) {
  .card {
    padding: 29px 20px 36px;
  }
  .ise-woa .ja {
    .name {
      font-size: 20px;
      line-height: 23px;
    }
  }
}

@media only screen and (max-width: $sp-small) {
  .ja,.zh{
    .name {
      font-size: 15px;
      white-space: nowrap;
    }
    .company {
      font-size: 13px;
    }
  }
  .ise-woa .ja {
    .name {
      font-size: 13px;
      white-space: nowrap;
    }
    .company {
      font-size: 13px;
    }
  }
}
</style>
