<template>
  <ul v-if="list.length" class="news-content">
    <template v-for="(item, i) in displayList">
      <ContentItem v-if="i < maxLength" :key="i" :item="item" v-bind="$attrs" />
    </template>
  </ul>
</template>

<script>
import ContentItem from '@/components/news/ContentItem.vue';
import usePageResource from '@/composables/usePageResource';

export default {
  name: 'news-content',
  setup() {
    const { pr } = usePageResource('news');
    return {
      pr,
    };
  },
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    maxLength: {
      type: Number,
      default: 5,
    },
    pageNum: {
      type: Number,
      default: 1,
    },
  },
  emits: ['onLength'],
  created() {
    this.$emit('onLength', this.list.length);
  },
  computed: {
    currentCount() {
      return (this.pageNum - 1) * this.maxLength;
    },
    displayList() {
      return this.list.slice(this.currentCount, this.currentCount + this.maxLength);
    },
  },
  components: {
    ContentItem,
  },
};
</script>

<style scoped lang="scss">
.news-content {
  list-style: none;
  margin: 0 auto;
  max-width: 1200px;

  & :last-child {
    border-bottom: none;
  }
}
</style>
