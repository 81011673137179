<template>
  <li
    :class="['content-item', 'hover', { 'hidden-element': animation }]"
    v-viewportChanged:[animation]="showView"
  >
    <router-link
      v-if="!item.to"
      class="content-item-link hover"
      :to="{ name: 'news-detail', params: { lang: $route.params.lang, id: item.id } }"
    >
      <div :class="['text', { ja: !isZH, 'has-img': isShowImg }]">
        <p class="date-type">
          <span class="date-type-date">{{ item.date }}</span>
          <Divider :space="11.5"/>
          <span class="date-type-type">{{ item.type }}</span>
        </p>
        <h3 class="title">
          {{ item.epitome }}
        </h3>
        <h3 class="content">
          {{ item.title }}
        </h3>
        <p :class="['description', { clamp: isOverflow }]" ref="clamp" v-if="isShowDescription">
          {{ item.description }}
        </p>
      </div>
      <div v-if="isShowImg" class="img">
        <img :src="item.mainPicture" :alt="item.title"/>
      </div>
    </router-link>
    <a v-else :href="item.to" target="_blank" class="content-item-link hover">
      <div :class="['text', { ja: !isZH, 'has-img': isShowImg }]">
        <p class="date-type">
          <span class="date-type-date">{{ item.date }}</span>
          <Divider :space="11.5"/>
          <span class="date-type-type">{{ item.type }}</span>
        </p>
        <h3 class="title">
          {{ item.epitome }}
        </h3>
        <h3 class="content">
          {{ item.title }}
        </h3>
        <p :class="['description', { clamp: isOverflow }]" ref="clamp" v-if="isShowDescription">
          {{ item.description }}
        </p>
      </div>
      <div v-if="isShowImg" class="img">
        <img :src="item.mainPicture" :alt="item.title"/>
      </div>
    </a>
  </li>
</template>

<script>
import { inject } from 'vue';
import Divider from '@/components/news/Divider.vue';
import { LOCALES } from '@/constants/constants';

export default {
  name: 'content-item',
  data() {
    return {
      isOverflow: false,
    };
  },
  setup() {
    const locale = inject('locale');
    return {
      locale,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    animation: {
      type: Boolean,
      default: true,
    },
    description: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isZH() {
      return this.locale === LOCALES.ZH;
    },
    isShowImg() {
      return this.image && !!this.item.mainPicture;
    },
    isShowDescription() {
      return this.description && !!this.item.description;
    },
  },
  methods: {
    setIsOverflow() {
      if (!this.$refs.clamp) return;
      const { offsetHeight, scrollHeight } = this.$refs.clamp;
      if (scrollHeight > offsetHeight) this.isOverflow = true;
    },
  },
  mounted() {
    this.setIsOverflow();
  },
  components: {
    Divider,
  },
};
</script>

<style scoped lang="scss">
$text-color: #212121;
$border-color: #a6aeb1;
$color-description: #464849;
$md: 767px;

.content-item {
  border-bottom: 1px solid $border-color;
  user-select: none;

  &-link {
    text-decoration: none;
    padding: 36px 0;
    display: flex;
    flex-wrap: wrap;

    &:hover {
      opacity: 1;
    }

    @media only screen and (max-width: $md) {
      padding: 28px 0;

      .text.has-img {
        margin-right: 0;
      }

      .img {
        width: 100%;
        flex-basis: 100%;
        text-align: center;
        margin-top: 1em;

        img {
          width: 100%;
        }
      }
    }

    &.hover {
      @media only screen and (min-width: $md) {
        &:hover {
          transition: opacity 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
          opacity: 0.4;
        }
      }
    }
  }
}

.text {
  flex: 1;
  max-width: 1000px;
  margin: 0 auto;
  color: $text-color;

  &.ja {
    letter-spacing: 0.1em;
  }

  &.has-img {
    margin-right: 40px;
  }
}

.description {
  margin-top: 18px;
  font-size: 15px;
  line-height: 2;
  color: $color-description;
  max-height: 4em;
  overflow: hidden;

  &.clamp {
    position: relative;

    &::after {
      content: '';
      background-image: url('../../assets/omission.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 3em;
      height: 2em;
      line-height: 2em;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.img {
  $width-base: 240px;
  min-width: $width-base;
  flex: 0 0 $width-base;

  img {
    width: $width-base;
  }
}

.date-type {
  font-size: 14px;
  padding-bottom: 20px;

  &-date {
    color: #a7aeb1;
    letter-spacing: 0.1em;
  }
}

.title,
.content {
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
}
</style>
