import square from '@/assets/square.jpg';

export default {
  sectionList: [
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          value: 'この度、飛狼成都は2021年9月13日に下記住所に移転し業務を行うこととなりました。',
        },
        {
          partType: 'string',
          value:
            '今後も皆様のご期待にお応えするため、スタッフ一同、一層の努力を重ねる所存でございます。これからも変わらぬご支援、お引き立てのほど、よろしくお願いいたします。',
        },
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
            "<ul style='list-style-position: inside; padding-left: 1em;'><li>会社名：成都市飛狼科技有限公司</li><li>移転先：〒610000 四川省成都市高新区東方希望天祥広場A座6階</li></ul>",
        },
        {
          partType: 'string',
          value: '※電話番号に変更はございません。',
        },
        {
          partType: 'img',
          value: {
            src: square,
            alt: 'square',
          },
        },
      ],
    },
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
            "お近くにお越しの際は、ぜひお立ち寄りください。\n\n<strong>お問い合わせ：</strong><br>成都市飛狼科技有限公司 趙 崗<br>メール：<a href='mailto:info@fenrir-inc.com.cn'>info@fenrir-inc.com.cn</a>",
        },
      ],
    },
  ],
};
