<template>
  <button class="button with-hover" :class="containerClass" @click="toWorksPage">
    <span>{{pr[btnText]}}</span>
  </button>
</template>

<script>
import { inject } from 'vue';
import usePageResource from '@/composables/usePageResource';

export default {
  setup() {
    const { pr } = usePageResource('works');
    const moveTo = inject('moveTo');
    return {
      pr,
      moveTo,
    };
  },
  name: 'WorksButton',
  props: {
    containerClass: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: 'worksBtnText',
    },
  },
  methods: {
    toWorksPage() {
      this.moveTo('works');
    },
  },
};
</script>

<style lang="scss" scoped>
  .button {
    width: 360px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #A6AEB1;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: inherit;
    cursor: pointer;
    outline: none;
    text-decoration: none;

    &:hover {
      opacity: 1;
    }

    > span {
      font-size: 16px;
      color: #222222;
      letter-spacing: 2.8px;
      font-weight: bold;
    }

    @media only screen and (max-width: 768px) {
      width: 250px;
    }
  }
</style>
