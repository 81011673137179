<template>
  <span class="divider" :style="{ margin: `0 ${space}px` }"></span>
</template>

<script>
export default {
  name: 'divider',
  props: {
    space: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.divider {
  display: inline-block;
  width: 0;
  height: 0.9em;
  border: {
    right: 1px solid #d0d4d7;
  }
  vertical-align: middle;
  top: -0.06em;
  position: relative;
  box-sizing: border-box;
}
</style>
