export default {
  navList: [
    {
      id: 'services',
      title: '产品与服务',
      link: '/services',
      subNav: [
        { title: '移动端咨询服务', link: '/services_app-advisory' },
        { title: 'UX/UI设计', link: '/services_ui' },
        { title: '活用新技术企划开发和运维支持', link: '/services_web' },
        { title: '移动端App/网页端App开发', link: '/services_app' },
        { title: '小程序开发和运维支持', link: '/services_mini-program' },
        { title: '办公自动化工具导入与开发', link: '/services_office-automation' },
      ],
    },
    {
      id: 'works',
      title: '客户案例',
      link: '/works',
      subNav: [],
    },
    { title: 'logo', link: '' },
    {
      id: 'home-about',
      title: '关于我们',
      link: '/home-about',
      subNav: [
        { title: '飞狼数码（上海）有限公司', link: '/about_sh' },
        { title: '成都市飞狼科技有限公司', link: '/about_cd' },
        { title: '大连飞狼科技有限公司', link: '/about_dl' },
      ],
    },
    {
      id: 'recruit',
      title: '招贤纳才',
      link: '/recruit',
      subNav: [
        { title: '事业部门', link: '/recruit_pmo' },
        { title: '开发部门', link: '/recruit_dev' },
        { title: '品质管理部门', link: '/recruit_qa' },
      ],
    },
    {
      id: 'news',
      title: '企业动态',
      link: '/news',
      subNav: [],
    },
  ],
  privacy_link: '隐私政策',
  beianIcp: '蜀ICP备19005068号',
  beian: '川公网安备 51019002002138 号',
  langList: [
    { title: '中文', code: 'zh' },
    { title: '日本語', code: 'ja' },
  ],
  contact: {
    title: '联系方式',
    emailTitle: '邮箱',
    url: 'info@fenrir-inc.com.cn',
    telTitle: '电话',
    tels: ['028-85177409（成都）', '0411-83731889（大连）'],
    contactTitle: '联系我们',
  },
};
