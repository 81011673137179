import { inject, computed } from 'vue';

export default function usePageResource(pageResourceKey) {
  const pageResources = inject('pageResources');
  const pr = computed(() => pageResources.value[pageResourceKey]);

  return {
    pr,
  };
}
