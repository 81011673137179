import officeImg from '@/assets/office/cd_1021.jpg';

export default {
  sectionList: [
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          value: '成都飞狼于2024年10月21日搬迁至以下地址进行办公。',
        },
        {
          partType: 'string',
          value:
            '为了回应大家的期待，公司全体员工将更加努力。今后也请一如既往地支持和关照我们。',
        },
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
            "<ul style='list-style-position: inside; padding-left: 1em;'><li>公司名称：成都市飞狼科技有限公司</li><li>新地址：四川省成都市高新区天府软件园D6栋15层</li></ul>",
        },
        {
          partType: 'string',
          value: '※电话号码和邮编无变更。',
        },
        {
          partType: 'img',
          value: {
            src: officeImg,
            alt: 'office',
          },
        },
      ],
    },
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
            "若您途经附近，欢迎移步本公司。\n\n<strong>联系我们：</strong><br>成都市飞狼科技有限公司　赵 岗<br>邮箱：<a href='mailto:info@fenrir-inc.com.cn'>info@fenrir-inc.com.cn</a>",
        },
      ],
    },
  ],
};
