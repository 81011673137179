import mitt from 'mitt';

const EventBus = {};
const emitter = mitt();

EventBus.$on = emitter.on;
EventBus.$off = emitter.off;
EventBus.$emit = emitter.emit;

export default EventBus;
